export function MoneyMask(v, n) {
  return new Intl.NumberFormat('pt-BR', { style: 'currency', currency: 'BRL' }).format(v)
}

export function NumberMask(v, n) {
  return new Intl.NumberFormat('pt-BR').format(ToFixed(v,n))
}

export function ToFixed(num, fixed) {
  if(num === undefined || num === null || num === NaN){
    return 0.0
  }
  var re = new RegExp("^-?\\d+(?:.\\d{0," + (fixed || -1) + "})?");
  if(num.toString().match(re) === null){
    return 0.0
  }
  return num.toString().match(re)[0];
}
