import {
  CustomerPicture,
  CustomerSelect,
} from "@/app/core/base/controllers/CustomerController";
import Storage from "@/app/core/util/Storage";
import React, { useEffect, useState, useRef } from "react";
import CarouselCard from "./components/Carousel";
import ManagerCard from "./components/ManagerCard";
import TaskCard from "./components/TaskCard";
import OrganListController from "@/app/core/base/controllers/OrganListController";
import CompaniesCard from "./components/CompaniesCard";
import BudgetCard from "./components/BudgetCard";
import { toast } from "react-toastify";
import ResumeTaskCard from "./components/ResumeTaskCard";
import ElaboratorCard from "./components/ElaboratorCard";
import Calendar from "react-calendar";
import ReminderCard from "./components/ReminderCard";

export default function HomeComponent({ props }) {
  const userData = Storage.getUserData();
  const [picture, setPicture] = useState(null);
  const [data, setData] = useState({});
  const [exigencies, setExigencies] = useState([]);
  const inputFile = useRef(null);
  const [value, setValue] = useState(new Date());

  function onUploadClick() {
    inputFile.current.click();
  }

  useEffect(() => {
    getCustomerData();
    loadCustomerExigencies();
  }, []);

  function loadCustomerExigencies() {
    OrganListController().SelectByCustomer(
      props.match.params.key,
      (res) => setExigencies(res),
      (err) => {
        console.log("Erro api", err);
      },
    );
  }

  function getCustomerData() {
    CustomerSelect(props.match.params.key, (res) => {
      console.log("API response", res);
      setData(res);

      if (res.avatarImages.length > 0) {
        setPicture(res.avatarImages[0].url);
      }
    });
  }

  function uploadFile(files) {
    var formData = new FormData();
    formData.append("file", files[0]);

    CustomerPicture(data.id, formData, (res) => {
      toast.success("Salvo com sucesso");
      getCustomerData();
    });
  }

  function onChange(){
    window.location = "#/reminders"
  }
  
  function openDate(){
    window.location = "#/reminders"
  }

  return (
    <div className="mr-1 ml-3 pl-0 pr-0">
      <div className="row mr-0 pr-0">
        <div className="col-md-12 row mb-3">
          <img
            className="img-avatar img-avatar96 img-avatar-thumb text-center ml-3"
            src={
              data && picture != null
                ? picture
                : "assets/media/avatars/avatar10.jpg"
            }
            style={{ textAlign: "center" }}
            onClick={(evt) => onUploadClick()}
            // alt="Add logo"
            title="Personalize com seu logo aqui"
          />
          <button
            className="btn btn-white btn-rounded btn-sm"
            style={{ marginLeft: -25, marginTop: 75 }}
            onClick={() => onUploadClick()}
          >
            <i className="fa fa-camera" />
          </button>
          <h3 className="mb-0 ml-3 mt-4">
            <strong className="font-w700">
              Seja bem vindo! {userData && userData.firstName}
            </strong>
            <br />
            <small className="text-muted">
              Código de cliente {data && data.customerCode}
            </small>
          </h3>
        </div>
        <div className="col-md-6 ml-0 mr-0">
          {/* Slider */}
          <div style={{ width: "100%", height: 430 }}>
            <CarouselCard />
          </div>
        </div>
        <div className="col-md-6 row ml-0 mr-0 pl-0">
          <div className="col-md-6 ml-0 mr-0 pr-0 pl-0">
            {/* <BudgetCard
              budgets={budgets}
              reload={() => loadBudgets() | getCustomerData()}
              customerId={data && data.id}
            /> */}
            <ResumeTaskCard customerId={	props.match.params.key}/>
          </div>
          <div
            className="col-md-6 ml-0 mr-0 pr-1 pl-1 cursor">
            <ElaboratorCard customerId={	props.match.params.key}/>
          </div>
          <div className="col-md-6 ml-0 mr-0 pr-1 pl-1">
            <BudgetCard
              customerId={props.match.params.key}
            />
          </div>
          <div className="col-md-6 ml-0 mr-0 pr-1 pl-1 cursor">
            <CompaniesCard customerId={	props.match.params.key}/>
          </div>
        </div>
        <div className="col-md-9 pr-2">
          {/* Tabelas */}
          {data && (
            <TaskCard
              customerId={props.match.params.key}
              data={data && data}
              exigencies={exigencies}
            />
          )}
        </div>
        <div className="col-md-3 ml-0 pl-0">
            <ReminderCard customerId={props.match.params.key} />
          <div className="mt-4">
          <ManagerCard customerId={props.match.params.key} />
          </div>
            <div onClick={() => openDate()}>
              <Calendar
                value={value}
                onChange={onChange}
                onClickDay={(evt) => openDate(evt)}/>
            </div>
        </div>
      </div>
      <input
        type="file"
        ref={inputFile}
        accept="image/*"
        onChange={(event) => {
          uploadFile(event.currentTarget.files);
        }}
        style={{ visibility: "hidden", width: 10 }}
      />
    </div>
  );
}
