import React, { useState, useEffect } from "react";
import Modal from "@/app/core/layouts/main/components/CustomModal";
import { FieldTypeObj } from "@/app/core/util/helpers/FieldTypeHelper";
import FinancialHelper from "@/app/core/util/helpers/FinancialHelper";
import { OnDataChange } from "@/app/core/util/services/DataService";
import CashFlowController from "@/app/core/base/controllers/CashFlowController";
import { toast } from "react-toastify";
import { Message } from "@/app/core/util/messages/GeralMessages";
import { CustomerList } from "@/app/core/base/controllers/CustomerController";
import FinancialTimeline from "./FinancialTimeline";

export default function NegotiationsModal({ show, onHide, selected, customerId }) {
    const [data, setData] = useState({});
    const [query, setQuery] = useState("");
    const [customers, setCustomers] = useState([]);
    const [loading, setLoading] = useState(false);
    const financialHelper = FinancialHelper();
    const cashFlow = CashFlowController();

    useEffect(() => {
        let base = {};
        if (!customerId) {
            loadCustomers()
        } else {
            base = { customerId };
        }
        if (selected !== undefined && selected.id !== undefined) {
            let baseDate = selected.dueDate.split("T");
            setData({ ...selected, dueDate: baseDate[0] });
        } else {
            setData(base);
        }
    }, [selected, customerId]);

    async function loadCustomers() {
        CustomerList(res => setCustomers(res));
    }

    function handleChange(evt, type) {
        setData(OnDataChange(data, evt, type));
    }

    async function save() {
        if (!data.customerId || !data.value) {
            toast.error(Message.PendingData);
            return;
        }
        setLoading(true);
        if (data.id === undefined) {
            await cashFlow.InsertEntry(data, (res) => {
                toast.success(Message.Saved);
                onHide();
            });
        } else {
            await cashFlow.UpdateEntry(data, (res) => {
                toast.success(Message.Saved);
                onHide();
            });
        }
        setLoading(false);
    }

    return (
        <Modal
            title={`Timeline`}
            show={show}
            isLoading={false}
            onHide={onHide}
            onSave={() => save()}
            onSubmit={() => save()}>
            <div>

                <FinancialTimeline customerId={customerId} />
            </div>
        </Modal>
    );
}
