import UserAccountController from "@/app/core/base/controllers/UserAccountController";
import { DateTranslate } from "@/app/core/util/DateTranslater";
import FinancialHelper from "@/app/core/util/helpers/FinancialHelper";
import { MoneyMask } from "@/app/core/util/helpers/MoneyHelper";
import { Message } from "@/app/core/util/messages/GeralMessages";
import React, { useEffect, useState } from "react";
// import ReactToExcel from "react-html-table-to-excel";
import { toast } from "react-toastify";
import UserCashModal from "./UserCashModal";
import Storage from "@/app/core/util/Storage";

export default function UserCashFlow({ user }) {
  const date = new Date();
  const [startDate, setStartDate] = useState(
    getFirstDayOfMonth(date.getFullYear(), date.getMonth())
      .toISOString()
      .substring(0, 10),
  );
  const [endDate, setEndDate] = useState(
    getFirstDayOfMonth(date.getFullYear(), date.getMonth() + 1)
      .toISOString()
      .substring(0, 10),
  );
  const [data, setData] = useState([]);
  const [showModal, setShowModal] = useState(false);
  const [selected, setSelected] = useState({});
  const [result, setResult] = useState(0);
  const [debit, setDebit] = useState(0);
  const [credit, setCredit] = useState(0);
  const [balance, setBalance] = useState(0);
  const userCash = UserAccountController();
  const financialHelper = FinancialHelper();
  const userData = Storage.getUserData();

  useEffect(() => {
    loadData();
  }, [startDate, endDate]);

  function getFirstDayOfMonth(year, month) {
    return new Date(year, month, 1);
  }

  async function loadData() {
    await userCash.List(user.id, startDate, `${endDate}`, (res) => {
      let creditRaw = 0;
      let debitRaw = 0;

      for (let s in res) {
        res[s].time = new Date(res[s].created).getTime();
        if (res[s].type === financialHelper.OperationType.Income) {
          creditRaw += res[s].value;
        } else {
          debitRaw += res[s].value;
        }
      }
      setResult(creditRaw - debitRaw);
      setDebit(debitRaw);
      setCredit(creditRaw);
      res.sort(function (a, b) {
        return b.time - a.time;
      });

      setData(res);
    });
    await userCash.Report(user.id, (res) => setBalance(res.value));
  }

  async function removeValue(item) {
    if (window.confirm(Message.DeleteConfirm)) {
      await userCash.Delete(item.id, (res) => {
        toast.success(Message.DeleteSuccess);
        loadData();
      });
    }
  }

  function open(item) {
    setSelected(item);
    setShowModal(true);
  }

  return (
    <div className="row">
      <div className="form-group col-md-6">
        <span>Data de início</span>
        <input
          type="date"
          className="form-control form-control-alt form-control-sm"
          value={startDate}
          onChange={(evt) => setStartDate(evt.target.value)}
        />
      </div>
      <div className="form-group col-md-6">
        <span>Data fim</span>
        <input
          type="date"
          className="form-control form-control-alt form-control-sm"
          value={endDate}
          onChange={(evt) => setEndDate(evt.target.value)}
        />
      </div>
      <div className="col-md-6">
        <div className="title">
          <h2 className="content-heading">
            <i className="fa fa-angle-right text-muted mr-1"></i>{" "}
            {data && data.length} Conta corrente
          </h2>
        </div>
      </div>
      <div className="col-md-6 text-right">
        <button
          className="btn btn-square btn-dual mt-3"
          onClick={() => setShowModal(true)}
        >
          <i className="si si-plus" /> Adicionar
        </button>
        {/* <ReactToExcel
                    className="btn btn-square btn-info mt-3"
                    table="table-element"
                    filename={`Relatorio de conta corrente - ${user.firstName} período: ${startDate} - ${endDate}`}
                    sheet="sheet 1"
                    buttonText="Exportar"
                /> */}
      </div>
      <div className="col-12 row text-center">
        {/* <div className="col-lg-3 py-3">
                    <div className="font-size-h1 font-w300 text-black mb-0">
                        R${MoneyMask(credit, 2)}
                    </div>
                    <div className="font-size-sm font-w700 text-muted text-uppercase">
                        Créditos
                    </div>
                </div>
                <div className="col-lg-3 py-3">
                    <div className="font-size-h1 font-w300 text-black mb-0">
                        R${MoneyMask(debit, 2)}
                    </div>
                    <div className="font-size-sm font-w700 text-muted text-uppercase">
                        Débitos
                    </div>
                </div>
                <div className="col-lg-3 py-3">
                    {result >= 0 ?
                        <div className="font-size-h1 font-w300 text-success mb-0">
                            R${MoneyMask(result, 2)}
                        </div>
                        :
                        <div className="font-size-h1 font-w300 text-danger mb-0">
                            R${MoneyMask(result, 2)}
                        </div>
                    }
                    <div className="font-size-sm font-w700 text-muted text-uppercase">
                        Saldo no período
                    </div>
                </div>
                <div className="col-lg-3 py-3">
                    {balance >= 0 ?
                        <div className="font-size-h1 font-w300 text-success mb-0">
                            R${MoneyMask(balance, 2)}
                        </div>
                        :
                        <div className="font-size-h1 font-w300 text-danger mb-0">
                            R${MoneyMask(balance, 2)}
                        </div>
                    }
                    <div className="font-size-sm font-w700 text-muted text-uppercase">
                        Saldo em conta
                    </div>
                </div> */}
      </div>
      <div className="col-12">
        <table
          className="table table-hover"
          id="table-element"
          key={"table-element"}
        >
          <thead>
            <tr>
              <th>Data</th>
              <th>Descrição</th>
              <th>Valor</th>
              <th></th>
            </tr>
          </thead>
          <tbody>
            {data &&
              data.map((item) => {
                return (
                  <tr>
                    <td>{DateTranslate(item.createdDate)}</td>
                    <td>{item.description}</td>
                    <td className="text-right">
                      {item.type === financialHelper.OperatorOperationType.Income ? (
                        <p className="text-success">
                          +{MoneyMask(item.value, 2)}
                        </p>
                      ) : (
                        <p className="text-danger">
                          -{MoneyMask(item.value, 2)}
                        </p>
                      )}
                    </td>
                    {/* {userData && userData.level == 10 ?
                                        : <td></td>} */}
                    <td>
                      <button
                        className="btn btn-sm btn-dual"
                        onClick={() => open(item)}
                      >
                        <i className="fa fa-eye" />
                      </button>
                      <button
                        className="btn btn-sm btn-dual"
                        onClick={() => removeValue(item)}
                      >
                        <i className="si si-close text-danger" />
                      </button>
                    </td>
                  </tr>
                );
              })}
            {/* <tr>
                            <td colspan="2" className="font-w700 text-uppercase text-right bg-body-light">Total</td>
                            <td className="font-w700 text-right bg-body-light">
                                {result >= 0 ?
                                    <p className="text-success">+R${MoneyMask(result, 2)}</p> :
                                    <p className="text-danger">-R${MoneyMask(result, 2)}</p>
                                }
                            </td>
                        </tr> */}
          </tbody>
        </table>
      </div>
      <UserCashModal
        userId={user && user.id}
        show={showModal}
        selected={selected}
        onHide={() => setSelected({}) | setShowModal(false) | loadData()}
      />
    </div>
  );
}
