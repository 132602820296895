import React, { useState, useEffect } from "react";
import Storage from "@/app/core/util/Storage";
import {
  OldBudgetsList,
  OldExigenciesList,
  ScheduleSend,
  SendInsert,
} from "@/app/core/base/controllers/SendController";
import { BudgetStatusTranslate } from "@/app/core/util/StatusTranslater";
import {
  DateToPast,
  DateTranslate,
  DateTranslateSimple,
} from "@/app/core/util/DateTranslater";
import BudgetModal from "@/app/core/layouts/main-components/BudgetModal";
import {
  TimelineChannelEmail,
  TimelineChannelSistema,
} from "@/app/core/util/helpers/TimelineHelper";
import { NotifyUpdate } from "@/app/core/base/controllers/TimelineController";
import {
  ResendExigencies,
  ResendEmailBudget,
  GetResendEmailBudget,
  SendList,
  ResendExigenciesBody,
} from "@/app/core/base/controllers/ContactController";
import {
  SelectedServices,
  ServiceList,
} from "@/app/core/base/controllers/ServicesController";
import { toast } from "react-toastify";
import OrganListHelper, {
  GetCurrentStatus,
  OrganStatus,
} from "@/app/core/util/helpers/OrganListHelper";
import ProcessController from "@/app/core/base/controllers/ProcessController";
import { DepartmentUsers } from "@/app/core/base/controllers/UsersController";
import { Toast } from "react-bootstrap";
import { CustomerList } from "@/app/core/base/controllers/CustomerController";
import OrganListController from "@/app/core/base/controllers/OrganListController";
import DropdownElement from "@/app/core/layouts/main-components/elements/DropdownElement";
import { Message } from "@/app/core/util/messages/GeralMessages";
import { CnpjMask, CpfMask } from "@/app/core/util/helpers/ContactHelper";
import { ExigencyDeliveryList } from "@/app/core/base/controllers/ExigenciesController";

export default function ProfileMainComponent(props) {
  const date = new Date();
  const userData = Storage.getUserData();
  const [refresh, setRefresh] = useState(true);
  const [loading, setLoading] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [selectAllCustomer, setSelectAllCustomer] = useState(true);
  const [selectAllServices, setSelectAllServices] = useState(true);
  const [selectAllOrgans, setSelectAllOrgans] = useState(true);
  const [type, setType] = useState(0);
  const [from, setFrom] = useState(userData.level > 1 ? 2 : userData.level);
  const [users, setUsers] = useState([]);
  const [itens, setItens] = useState([]);
  const [customers, setCustomers] = useState([]);
  const [services, setServices] = useState([]);
  const [organs, setOrgans] = useState([]);
  const [selectedData, setSelectedData] = useState({});
  const [change, setChange] = useState(date.getTime());
  const [process, setProcess] = useState([]);
  const [organList, setOrganList] = useState([]);
  const [processRaw, setProcessRaw] = useState([]);
  const [delivery, setDelivery] = useState([]);
  const [queryBase, setQuery] = useState("");
  const [organListRaw, setOrganListRaw] = useState([]);
  const processCtrl = ProcessController();
  const organCtrl = OrganListController();
  const organHelper = OrganListHelper();

  useEffect(() => {
    loadSecond();
  }, [refresh, type, from]);

  useEffect(() => {
    loadUsers();
    loadExigencies();
  }, []);

  useEffect(() => {
    processFilter();
  }, [
    selectAllCustomer,
    selectAllServices,
    selectAllOrgans,
    customers,
    services,
    organs,
  ]);

  function loadExigencies() {
    ExigencyDeliveryList((res) => setDelivery(res));
  }

  function loadUsers() {
    DepartmentUsers(5, (res) => {
      setUsers(res);
      loadCustomers();
    });
  }

  function loadCustomers() {
    CustomerList((res) => {
      setCustomers(checkAll(res));
      loadServices();
    });
  }

  function loadServices() {
    ServiceList((res) => {
      setServices(checkAll(res));
      loadOrgans();
    });
  }

  async function loadOrgans() {
    await organCtrl.OrgansSelect((res) => {
      setOrgans(checkAll(res));
      loadSecond();
    });
  }

  function checkAll(itens) {
    for (let s in itens) {
      itens[s].checked = true;
    }

    return itens;
  }

  async function loadSecond() {
    setLoading(true);
    if (from === 1) {
      await OldBudgetsList(userData.id, type, (res) => {
        let myProcess = res.process;
        let sends = res.sends;
        for (let p of myProcess) {
          p.open = false;
          p.sends = sends.filter((x) => x.processId === p.id);

          if (p.sends !== undefined && p.sends.length > 0) {
            for (let send of p.sends) {
              send.time = new Date(send.createdDate).getTime();
            }

            p.sends.sort(function (a, b) {
              return b.time - a.time;
            });

            p.updatedDate = p.sends[0].updatedDate;
          }
        }

        setProcess(myProcess);
        setProcessRaw(myProcess);
        setOrganList([]);
      });
    } else {
      await OldExigenciesList(userData.id, type, (res) => {
        let listOrgans = res.organList;
        let sends = res.sends;
        let exigencies = res.exigencies;
        for (let organ of listOrgans) {
          organ.open = false;
          organ.sends = sends.filter((x) => x.organListId === organ.id);
          organ.exigencies = exigencies.filter(
            (x) => x.organListId === organ.id,
          );

          if (organ.sends !== undefined && organ.sends.length > 0) {
            for (let send of organ.sends) {
              send.time = new Date(send.createdDate).getTime();
            }

            organ.sends.sort(function (a, b) {
              return b.time - a.time;
            });

            organ.updatedDate = organ.sends[0].updatedDate;
          }
        }
        setOrganList(listOrgans.filter(x => x.status !== OrganStatus.ToBeReturned));
        setOrganListRaw(listOrgans.filter(x => x.status !== OrganStatus.ToBeReturned));
        setProcess([]);
      });
    }

    setLoading(false);
  }

  async function sendMail(item) {
    if (window.confirm("Realmente deseja reenviar?")) {
      if (userData.level === 1) {
        SelectedServices(item.id, async (res) => {
          notifySend(item.id, 1, 0);

          ResendEmailBudget(item, res);
          await NotifyUpdate(
            item.id,
            "Orçamento reenviado por email",
            true,
            TimelineChannelEmail,
            null,
          );
        });
      } else if (userData.level === 2 || userData.level === 3) {
        await processCtrl.Select(item.processId, async (res) => {
          ResendExigencies(res, item.id);
          notifySend(item.processId, userData.level, item.id);

          await NotifyUpdate(
            item.processId,
            "Exigências enviadas ao cliente",
            false,
            TimelineChannelSistema,
            item.id,
          );
        });
      }

      setTimeout(() => {
        loadSecond();
      }, 2500);
    }
  }

  async function notifySend(id, stage, organListId) {
    await SendInsert(
      {
        userId: userData.id,
        processId: id,
        stage: stage,
        organListId,
      },
      (res) => {
        toast.success("Enviado com sucesso");
      },
    );
  }

  function changeOpen(item, index) {
    if (userData.level === 1) {
      let pr = process;
      pr[index].open = !item.open;
      setOrganList(pr);
    } else {
      let organs = organList;
      organs[index].open = !item.open;
      setOrganList(organs);
    }
    notyChange();
  }

  function selectAll() {
    if (userData.level === 1 || from == 1) {
      let elements = process;
      let newItens = [];
      if (itens.length === process.length) {
        for (let s in elements) {
          elements[s].checked = false;
        }
      } else {
        for (let s in elements) {
          elements[s].checked = true;
          newItens.push(elements[s].id);
        }
      }
      setProcess(elements);
      setItens(newItens);
      notyChange();
    } else if (userData.level > 1 || from == 2) {
      let elements = organList;
      let newItens = [];
      if (itens.length === organList.length) {
        for (let s in elements) {
          elements[s].checked = false;
        }
      } else {
        for (let s in elements) {
          elements[s].checked = true;
          newItens.push(elements[s].id);
        }
      }

      setOrganList(elements);
      setItens(newItens);
      notyChange();
    }
  }

  async function recoverBudget(item) {
    if (window.confirm("Deseja realmente recuperar esse elemento?")) {
      await processCtrl.Update(item, (res) => {
        toast.success(Message.Saved);
        loadSecond();
      });
    }
  }

  async function recoverOrgan(item) {
    if (window.confirm("Deseja realmente recuperar esse elemento?")) {
      await organCtrl.Update(item, (res) => {
        toast.success(Message.Saved);
        loadSecond();
      });
    }
  }

  function openElement(item) {
    if (from === 1) {
      setSelectedData(item);
      setShowModal(true);
    } else if (userData.level === 2) {
      window.open(`#/analysis/${item.id}`, "_blank");
    } else if (userData.level === 3) {
      window.open(`#/preparation/${item.id}`, "_blank");
    } else {
    }
  }

  async function sendSelecteds() {
    if (window.confirm("Tem certeza de que deseja reenviar para todos?")) {
      const chunkSize = 10;

      if (userData.level === 1) {
        setLoading(true);
        let sendBody = [];
        let baseBody = {
          subject: "Alerta! Orçamento Parado!",
          targetName: "",
          targetEmail: "",
          from: "sistema@hasa.com.br",
          plainTextContent: "",
          htmlContent: "",
          organListId: null,
          processId: null,
        };

        for (let s in process) {
          const item = process[s];
          if (
            item.checked &&
            item.budgetResponsible != null &&
            item.budgetResponsible
          ) {
            let email = {};
            email.htmlContent = GetResendEmailBudget(
              item,
              item.selectedServices,
            );
            email.plainTextContent = GetResendEmailBudget(
              item,
              item.selectedServices,
            );
            email.targetEmail = item.budgetResponsible.email;
            email.targetName = item.budgetResponsible.firstName;
            email.processId = item.id;
            email.subject = "Alerta! Orçamento Parado!";
            email.from = "sistema@hasa.com.br";

            sendBody.push(email);
          }
        }

        if (sendBody.length > 0) {
          SendList(
            sendBody,
            (res) => {
              toast.success("Emails enviados");
              setLoading(false);
              loadSecond();
            },
            (error) => {
              toast.success("Não foi possível enviar a remessa");
              setLoading(false);
              loadSecond();
            },
          );
        }
      } else {
        for (let i = 0; i < organList.length; i += chunkSize) {
          const chunk = organList.slice(i, i + chunkSize);
          // do whatever
          for (let s in chunk) {
            let contains = itens.filter((x) => x === chunk[s].id);
            if (contains.length > 0) {
              let pr = chunk[s].process;
              let user = users.filter((x) => x.id === pr.responsibleId);
              if (user.length > 0) {
                pr.responsible = user[0];
                // notifySend(pr.id, userData.level, chunk[s].id);
                const body = ResendExigenciesBody(pr, chunk[s].id);
                await ScheduleSend(body, chunk[s].id, chunk[s].processId, "Alerta! Processo aguardando cumprimento de exigências!", pr.responsible.email, pr.responsible.firstName, "sistema@hasa.com.br", 0, res => {})
              } else {
                toast.error(
                  `Processo ${pr.processName} não possui um responsável definido. Talvez tenha sido excluído`,
                );
              }
            }
          }

          if (organList.length >= chunkSize) {
            toast.warn("Agendado a primeira remessa");
          }
        }
      }

      setTimeout(() => {
        loadSecond();
      }, 2500);
    }
  }

  function handleSelectedItens(evt, index) {
    let newItens = itens;
    if (userData.level === 1) {
      let pr = process;
      pr[index].checked = evt.target.checked;
      newItens = pr.filter((x) => x.checked == true);
      setProcess(pr);
      notyChange();
    } else if (userData.level > 1) {
      let organs = organList;
      if (evt.target.checked === true) {
        let contains = newItens.filter((x) => x === organs[index].id);
        if (contains.length < 1) {
          if (organs[index] !== undefined) {
            newItens.push(organs[index].id);
          }
        }
      } else {
        for (let s in newItens) {
          if (s === organs[index].id) {
            newItens.splice(s, 1);
          } else {
            newItens.splice(s, 1);
          }
        }
      }
      organs[index].checked = evt.target.checked;

      setOrganList(organs);
    }

    setItens(newItens);
    notyChange();
  }

  function handleQueryChange(info) {
    let query = info.trim().toLowerCase();
    if (from === 1) {
      let oldData = processRaw;
      if (info.length === 0 || info === "") {
        setProcess(processRaw);
        notyChange();
      } else {
        let data = [];

        for (let s in oldData) {
          const item = oldData[s];
          const customer = item.customer;
          let enter = false;
          for (let y in item.selectedServices) {
            let sv = item.selectedServices[y];
            if (sv.serviceName.trim().toLowerCase().includes(query)) {
              enter = true;
            }
          }

          if (
            item.processName.trim().toLowerCase().includes(query) ||
            customer.name.trim().toLowerCase().includes(query) ||
            (customer.address !== undefined &&
              customer.address.trim().toLowerCase().includes(query)) ||
            (customer.state !== undefined &&
              customer.state.trim().toLowerCase().includes(query)) ||
            (customer.crc !== undefined &&
              customer.crc.trim().toLowerCase().includes(query)) ||
            (customer.city !== undefined &&
              customer.city.trim().toLowerCase().includes(query)) ||
            customer.customerCode == query ||
            (customer.cnpj !== undefined &&
              CnpjMask(customer.cnpj).includes(query)) ||
            (customer.cpf !== undefined &&
              CpfMask(customer.cpf).includes(query))
          ) {
            enter = true;
          }

          if (
            DateTranslate(item.updatedDate).trim().toLowerCase().includes(query)
          ) {
            enter = true;
          }

          if (enter === true) {
            data.push(item);
          }
        }

        setProcess(data);
        notyChange();
      }
    } else {
      if (info.length === 0 || info === "") {
        setProcess(processRaw);
        notyChange();
      } else {
        let oldData = organListRaw;
        let data = [];

        for (let s in oldData) {
          const item = oldData[s];
          let enter = false;

          if (item.selectedService.serviceName.trim().toLowerCase() === query) {
            enter = true;
          }

          if (
            item.process.processName.trim().toLowerCase().includes(query) ||
            item.process.customer.name.trim().toLowerCase().includes(query) ||
            (item.process.customer.address !== undefined &&
              item.process.customer.address
                .trim()
                .toLowerCase()
                .includes(query)) ||
            (item.process.customer.state !== undefined &&
              item.process.customer.state
                .trim()
                .toLowerCase()
                .includes(query)) ||
            (item.process.customer.crc !== undefined &&
              item.process.customer.crc.trim().toLowerCase().includes(query)) ||
            (item.process.customer.city !== undefined &&
              item.process.customer.city
                .trim()
                .toLowerCase()
                .includes(query)) ||
            item.process.customer.customerCode.includes(query) ||
            (item.process.customer.cnpj !== undefined &&
              CnpjMask(item.process.customer.cnpj).includes(query)) ||
            (item.process.customer.cpf !== undefined &&
              CpfMask(item.process.customer.cpf).includes(query))
          ) {
            enter = true;
          }

          let checkOrgan = organs.filter((x) => x.id === item.organId);

          if (checkOrgan.length > 0) {
            if (checkOrgan[0].name.trim().toLowerCase().includes(query)) {
              enter = true;
            }
          }

          if (
            DateTranslate(item.updatedDate).trim().toLowerCase().includes(query)
          ) {
            enter = true;
          }

          if (enter === true) {
            data.push(item);
          }
        }

        setOrganList(data);
        notyChange();
      }
    }
  }

  function processFilter() {
    if (from === 1) {
      let oldData = processRaw;
      let data = [];

      for (let s in oldData) {
        const item = oldData[s];
        let service = false,
          customer = false;

        for (let y in item.selectedServices) {
          let sv = item.selectedServices[y];
          let checkService = services.filter(
            (x) => x.id === sv.serviceId && x.checked === true,
          );
          if (checkService !== null && checkService.length > 0) {
            service = true;
          }
        }

        let checkCustomer = customers.filter(
          (x) => x.id === item.customerId && x.checked === true,
        );
        if (checkCustomer !== null && checkCustomer.length > 0) {
          customer = true;
        }

        if (customer === true && service === true) {
          data.push(item);
        }
      }

      setProcess(data);
      notyChange();
    } else {
      let oldData = organListRaw;
      let data = [];

      for (let s in oldData) {
        const item = oldData[s];
        let service = false,
          customer = false,
          organ = false;

        let checkService = services.filter(
          (x) =>
            item.selectedService &&
            x.id === item.selectedService.serviceId &&
            x.checked === true,
        );

        if (checkService !== null && checkService.length > 0) {
          service = true;
        }

        let checkCustomer = customers.filter(
          (x) => x.id === item.process.customerId && x.checked === true,
        );

        if (checkCustomer !== null && checkCustomer.length > 0) {
          customer = true;
        }

        let checkOrgan = organs.filter(
          (x) => x.id === item.organId && x.checked === true,
        );

        if (checkOrgan !== null && checkOrgan.length > 0) {
          organ = true;
        }

        if (customer === true && service === true && organ === true) {
          data.push(item);
        }
      }

      setOrganList(data);
      notyChange();
    }
  }

  function notyChange() {
    setChange(date.getTime());
  }

  return (
    <div className="content">
      <div className="block block-rounded">
        <div className="block-header">
          <div className="block-title">Reenviar</div>
          <div className="block-tools">
            <div className="row">
              {userData && userData.role > 0 ? (
                <div className="col-5">
                  <div className="form-group">
                    <select
                      className="form-control"
                      value={from}
                      onChange={(evt) => setFrom(parseInt(evt.target.value))}
                    >
                      <option value={1}>Orçamentos</option>
                      <option value={2}>Exigências</option>
                    </select>
                  </div>
                </div>
              ) : (
                ""
              )}
              {userData && userData.role > 0 ? (
                <div className="col-7">
                  <div className="form-group">
                    <select
                      className="form-control"
                      onChange={(evt) => setType(parseInt(evt.target.value))}
                    >
                      <option value={0}>Mostrar minhas tarefas</option>
                      <option value={1}>Mostrar minha equipe</option>
                      {userData && userData.role > 1 ? (
                        <option value={2}>Mostrar todos</option>
                      ) : (
                        ""
                      )}
                    </select>
                  </div>
                </div>
              ) : (
                ""
              )}
              <div className="col-5">
                <div className="form-group">
                  <div className="input-group">
                    <input
                      type="text"
                      placeholder="Filtrar"
                      className="form-control"
                      id="search-filter"
                      value={queryBase}
                      onChange={(evt) =>
                        handleQueryChange(evt.target.value) |
                        setQuery(evt.target.value)
                      }
                    />
                    <div className="input-group-append">
                      <span
                        className="input-group-text"
                        onClick={() => handleQueryChange("") | setQuery("")}
                        title="Limpar campo"
                      >
                        <i className="si si-close"></i>
                      </span>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-7">
                <button
                  className="btn btn-info btn-sm"
                  onClick={() => selectAll()}
                >
                  Selecionar todos
                </button>
                <button
                  className="btn btn-dual btn-sm"
                  onClick={() => sendSelecteds()}
                >
                  {change && itens && itens.length} Reenviar
                </button>
              </div>
            </div>
          </div>
        </div>
        <div className="block-content">
          {loading === true ? (
            <div className="block-content text-center">
              <i className={"fa fa-circle-notch fa-spin text-info fa-2x"}></i>
            </div>
          ) : (
            ""
          )}
          <table className="table table-striped table-hover table-borderless table-vcenter font-size-sm">
            <thead>
              {from === 1 ? (
                <tr>
                  <td style={{ width: "5%" }}></td>
                  <td style={{ width: "5%" }}>Id</td>
                  <td style={{ width: "17.5%" }}>Processo</td>
                  <td style={{ width: "17.5%" }}>Status</td>
                  <td style={{ width: "17.5%" }}>Últ. Envio</td>
                  <td style={{ width: "17.5%" }}>Envios</td>
                  <td style={{ width: "17.5%" }}></td>
                </tr>
              ) : (
                <tr>
                  <td style={{ width: "5%" }}></td>
                  <td style={{ width: "5%" }}>Id</td>
                  <td style={{ width: "12.5%" }}>Processo</td>
                  <td style={{ width: "12.5%" }}>Órgão</td>
                  <td style={{ width: "12.5%" }}>Status</td>
                  <td style={{ width: "12.5%" }}>Últ. Envio</td>
                  <td style={{ width: "9.75%" }}>Envios</td>
                  <td style={{ width: "9.75%" }}>Exigências</td>
                  <td style={{ width: "19.5%" }}></td>
                </tr>
              )}
            </thead>
            <tbody></tbody>
          </table>
          {change &&
            process.map((item, index) => (
              <div
                className={`block block-rounded ${
                  item.budgetResponsible == undefined ||
                  item.budgetResponsible == null
                    ? "block-bordered border-danger border-2x"
                    : ""
                }`}
              >
                <tr>
                  <td style={{ width: "5%" }}>
                    <div className="custom-control custom-checkbox custom-control-lg custom-control-inline mb-1">
                      <input
                        className="custom-control-input mr-1"
                        type="checkbox"
                        name={item.id}
                        id={item.id}
                        onChange={(evt) => handleSelectedItens(evt, index)}
                        checked={item.checked}
                      />
                      <label class="custom-control-label" for={item.id}></label>
                      {item.budgetResponsible == undefined ||
                      item.budgetResponsible == null ? (
                        <i
                          className="si si-info text-danger fa-2x mt-3 pt-3 ml-3"
                          title="Processo sem responsável pelo orçamento"
                        />
                      ) : (
                        ""
                      )}
                    </div>
                  </td>
                  <td style={{ width: "5%" }}>{item.id}</td>
                  <td style={{ width: "17.5%" }}>{item.processName}</td>
                  <td style={{ width: "17.5%" }}>
                    {BudgetStatusTranslate(item.budgetStatus)}
                  </td>
                  <td style={{ width: "17.5%" }}>
                    {DateTranslateSimple(item.updatedDate)}
                  </td>
                  <td style={{ width: "17.5%" }}>
                    {item.sends && item.sends.length}
                  </td>
                  <td style={{ width: "17.5%" }}>
                    <button
                      className="btn btn-sm btn-dual"
                      onClick={() => openElement(item)}
                    >
                      <i className="far fa-eye" />
                    </button>
                    <button
                      className="btn btn-sm btn-dual"
                      onClick={() => sendMail(item)}
                    >
                      <i className="si si-envelope-letter" /> Reenviar email
                    </button>
                    <button
                      className="btn btn-sm btn-dual"
                      onClick={() => recoverBudget(item)}
                    >
                      <i className="far fa-arrow-left" /> Voltar a esteira
                    </button>
                    <button
                      className="btn btn-sm btn-dual js-click-ripple-enabled"
                      onClick={() => changeOpen(item, index)}
                    >
                      <i
                        className={`si si-arrow-${
                          item.open === true ? "up" : "down"
                        }`}
                      ></i>
                    </button>
                  </td>
                </tr>
                {item.open === true ? (
                  <div
                    className="block-content"
                    style={{ marginTop: -25, paddingTop: -25 }}
                  >
                    <div className="block-header">
                      <div className="block-title">Envios</div>
                    </div>
                    <table className="table table-striped table-hover table-borderless table-vcenter font-size-sm">
                      <thead>
                        <tr>
                          <td>Id</td>
                          <td>Em dias</td>
                          <td>Data</td>
                          <td>Usuário</td>
                        </tr>
                      </thead>
                      <tbody>
                        {item.sends &&
                          item.sends.map((send) => (
                            <tr>
                              <td>{send.id}</td>
                              <td>{DateToPast(send.updatedDate)}</td>
                              <td>{DateTranslate(send.updatedDate)}</td>
                              <td>{send.user && send.user.firstName}</td>
                            </tr>
                          ))}
                      </tbody>
                    </table>
                  </div>
                ) : (
                  ""
                )}
              </div>
            ))}
          {from > 1 ? (
            <div>
              {change &&
                organList.map((item, index) => (
                  <div className="block block-rounded">
                    <table className="table table-striped table-hover table-borderless table-vcenter font-size-sm">
                      <tr>
                        <td style={{ width: "5%" }}>
                          <input
                            className="form-check-input mr-1"
                            type="checkbox"
                            name={item.id}
                            id={`checkbox-${item.id}`}
                            onChange={(evt) => handleSelectedItens(evt, index)}
                            checked={item.checked}
                          />
                        </td>
                        <td style={{ width: "5%" }}>{item.id}</td>
                        <td style={{ width: "12.5%" }}>
                          {item.process && item.process.processName}
                        </td>
                        <td style={{ width: "12.5%" }}>
                          {item.organ && item.organ.name}
                        </td>
                        <td style={{ width: "12.5%" }}>
                          {organHelper.GetCurrentStatus(item)}
                        </td>
                        <td style={{ width: "12.5%" }}>
                          {DateTranslateSimple(item.updatedDate)}
                        </td>
                        <td style={{ width: "9.75%" }}>
                          {item.sends && item.sends.length}
                        </td>
                        <td style={{ width: "9.75%" }}>
                          {item.exigencies && item.exigencies.length}
                        </td>
                        <td style={{ width: "19.5%" }}>
                          <button
                            className="btn btn-sm btn-dual"
                            onClick={() => openElement(item)}
                          >
                            <i className="far fa-eye" />
                          </button>
                          <button
                            className="btn btn-sm btn-dual"
                            onClick={() => sendMail(item)}
                          >
                            <i className="si si-envelope-letter" /> Reenviar
                            email
                          </button>
                          <button
                            className="btn btn-sm btn-dual"
                            onClick={() => recoverOrgan(item)}
                          >
                            <i className="far fa-arrow-left" /> Voltar a esteira
                          </button>
                          <button
                            className="btn btn-sm btn-dual js-click-ripple-enabled"
                            onClick={() => changeOpen(item, index)}
                          >
                            <i
                              className={`si si-arrow-${
                                item.open === true ? "up" : "down"
                              }`}
                            ></i>
                          </button>
                        </td>
                      </tr>
                    </table>
                    {item.open === true ? (
                      <div
                        className="block-content"
                        style={{ marginTop: -25, paddingTop: -25 }}
                      >
                        <div className="block-header">
                          <div className="block-title">Envios</div>
                        </div>
                        <table className="table table-striped table-hover table-borderless table-vcenter font-size-sm">
                          <thead>
                            <tr>
                              <td>Id</td>
                              <td>Em dias</td>
                              <td>Data</td>
                              <td>Usuário</td>
                            </tr>
                          </thead>
                          <tbody>
                            {item.sends &&
                              item.sends.map((send) => (
                                <tr>
                                  <td>{send.id}</td>
                                  <td>{DateToPast(send.updatedDate)}</td>
                                  <td>{DateTranslate(send.updatedDate)}</td>
                                  <td>{send.user && send.user.firstName}</td>
                                </tr>
                              ))}
                          </tbody>
                        </table>
                      </div>
                    ) : (
                      ""
                    )}
                  </div>
                ))}
            </div>
          ) : (
            ""
          )}
        </div>
      </div>

      <BudgetModal
        data={selectedData}
        info={selectedData}
        show={showModal}
        onHide={() =>
          setShowModal(false) | setRefresh(!refresh) | setSelectedData({})
        }
      />
    </div>
  );
}
