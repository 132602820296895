import CustomerPayments from "@/app/core/layouts/main-components/financial/CustomerPayments";
import FinancialEntries from "@/app/core/layouts/main-components/financial/FinancialEntriesCard";
import Storage from "@/app/core/util/Storage";
import React from "react";
import { Tab, Tabs } from "react-bootstrap";

export default function Billing(props) {
    const userData = Storage.getUserData();

    return (
        <div>
            <h2 className="mb-0 ml-3 mt-2 content-heading">
                <strong className="font-w700">Financeiro</strong>
            </h2>
            <div className="block-content">
                <Tabs defaultActiveKey="conta">
                    {/* <Tab eventKey="billing" title="Faturas">
                        <CustomerPayments customerId={userData && userData.customerId}/>
                    </Tab> */}
                    <Tab eventKey="conta" title="Conta corrente">
                        <FinancialEntries customerId={userData && userData.customerId}/>
                    </Tab>
                </Tabs>
            </div>
        </div>
    )
}