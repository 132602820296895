import React, { useEffect, useState } from "react";
import Modal from "../main/components/Modal";
import Storage from "@/app/core/util/Storage";
import InputMask from "react-input-mask";
import { MoneyMask } from "../../util/helpers/MoneyHelper";
import { OnDataChange } from "../../util/services/DataService";
import { FieldTypeObj } from "../../util/helpers/FieldTypeHelper";
import { ServiceList } from "../../base/controllers/ServicesController";
import ProcessController from "../../base/controllers/ProcessController";
import { toast } from "react-toastify";
import DocsController from "../../base/controllers/DocumentsController";
import { SendEmailBudget } from "../../base/controllers/ContactController";
import { CustomerSearch } from "../../base/controllers/CustomerController";
import { PhoneMask } from "../../util/helpers/ContactHelper";
import GetBaseUrl from "../../base/BaseAddress";

export default function NewBudgetComponent(props) {
  const date = new Date();
  const userData = Storage.getUserData();
  const [data, setData] = useState({ customer: {} });
  const [change, setChange] = useState(date.getTime());
  const [selectedServices, setSelectedServices] = useState([]);
  const [servicesList, setServicesList] = useState(Storage.getServices());
  const [customers, setCustomers] = useState([]);
  const [files, setFiles] = useState([]);
  const [totalValue, setTotalValue] = useState("0");
  const [isOpen, setIsOpen] = useState(true);
  const processCtrl = ProcessController();
  const docsCtrl = DocsController();

  const { setFieldValue, show, onHide } = props;

  useEffect(() => {
    if (show == true) {
      loadServices();
    }
  }, [show]);

  function notyChange() {
    setChange(date.getTime());
    processTotalValue();
  }

  function processTotalValue() {
    let base = 0;
    let old = selectedServices;
    for (let s in old) {
      base += old[s].value;
    }

    setTotalValue(base);
  }

  async function searchCustomers() {
    if (
      data.customer.email &&
      data.customer.email.length > 0 &&
      data.customer.whatsapp &&
      data.customer.whatsapp.length > 0 &&
      data.customer.name &&
      data.customer.name.length > 0
    ) {
      await CustomerSearch(
        data.customer.email,
        data.customer.whatsapp,
        data.customer.name,
        (res) => {
          setCustomers(res);
        },
      );
    } else {
    }
  }

  async function loadServices() {
    if (!servicesList || servicesList.length == 0) {
      ServiceList((res) => {
        setServicesList(res);
      });
    }
  }

  function showFields(item) {
    if (item === true) {
      return (
        <>
          <div className="form-group" style={{ visibility: isOpen }}>
            <label for="example-masked-time">Nire</label>
            <input
              onChange={(evt) => handleChange(evt, FieldTypeObj.Text)}
              className="form-control"
              type="text"
              name="nire"
            />
          </div>

          <div className="form-group" style={{ visibility: isOpen }}>
            <label for="example-masked-time">CNPJ</label>
            <input
              onChange={(evt) => handleChange(evt, FieldTypeObj.Text)}
              className="form-control"
              type="text"
              name="cnpj"
            />
          </div>

          <div className="form-group" style={{ visibility: isOpen }}>
            <label for="example-masked-time">CCM (Opcional)</label>
            <input
              onChange={(evt) => handleChange(evt, FieldTypeObj.Text)}
              className="form-control"
              type="text"
              name="ccm"
            />
          </div>

          <div className="form-group" style={{ visibility: isOpen }}>
            <label for="example-masked-time">Cadesp (Opcional)</label>
            <input
              onChange={(evt) => handleChange(evt, FieldTypeObj.Text)}
              className="form-control"
              type="text"
              name="cadesp"
            />
            {/* {errors.cadesp && touched.cadesp && (
              <div style={{ color: "red" }}>{errors.cadesp}</div>
            )} */}
          </div>
        </>
      );
    }
  }

  function handleChange(evt, type) {
    setData(OnDataChange(data, evt, type));
    if (evt.target.name === "observation" && customers.length === 0) {
      searchCustomers();
    }
  }

  function handleCustomerChange(evt, type) {
    setData({ ...data, customer: OnDataChange(data.customer, evt, type) });
  }

  function removeSelectedService(item, idx) {
    let old = selectedServices;
    old.splice(idx, 1); // 2nd parameter means remove one item only

    setSelectedServices(old);
    notyChange();
  }

  function removeOrganService(idx, index) {
    let old = selectedServices;
    old[idx].serviceOrgans.splice(index, 1); // 2nd parameter means remove one item only

    setSelectedServices(old);
    notyChange();
  }

  async function handleSubmit() {
    const body = {
      ...data,
      services: selectedServices,
      operatorId: userData.id,
    };

    await processCtrl.ProspectInsert(body, async (res) => {
      if (files.length > 0) {
        var formData = new FormData();
        for (let s in files) {
          formData.append("file", files[s]);
        }

        await uploadFiles(res.id, formData);
      }
      await sendEmail(res, res.customer.name);
    });
  }

  async function sendEmail(res) {
    await SendEmailBudget(
      res,
      selectedServices,
      data.customer.name,
      data.customer.email,
    );
    toast.success("Salvo com sucesso");
    onHide();
  }

  async function uploadFiles(processId, formData) {
    await docsCtrl.UploadDocs(
      processId,
      formData,
      userData.id,
      null,
      (res) => {},
      (error) =>
        toast.error(
          "Ocorreu um erro ao subir os arquivos, tente fazer manualmente na lista de processos recentes",
        ),
    );
  }

  function openCustomer(item) {
    window.open(`${GetBaseUrl()}#/customer-profile/${item.id}`, "_blank");
  }

  function selectCustomer(customer) {
    setData({ ...data, customerId: customer.id });
    setCustomers([customer]);
  }

  function addService(w) {
    let old = servicesList.find((item) => item.id == parseInt(w));

    old.price = old.value;
    old.serviceName = old.name;

    setSelectedServices([...selectedServices, old]);
    notyChange();
  }

  return (
    <Modal
      title="Novo Orçamento sem cadastro"
      show={show}
      onHide={onHide}
      onSubmit={() => handleSubmit()}
    >
      <div className="row">
        <div className="col-sm-12">
          <div className="form-group">
            <label for="example-masked-time">Nome do Processo</label>
            <input
              onChange={(evt) => handleChange(evt, FieldTypeObj.Text)}
              className="form-control"
              type="text"
              name="processName"
            />
          </div>
          <div className="form-group">
            <label for="example-masked-time">Nome do cliente</label>
            <input
              onChange={(evt) => handleCustomerChange(evt, FieldTypeObj.Text)}
              className="form-control"
              type="text"
              name="name"
            />
          </div>
          <div className="form-group">
            <label for="example-masked-time">E-mail</label>
            <input
              onChange={(evt) => handleCustomerChange(evt, FieldTypeObj.Text)}
              className="form-control"
              type="email"
              name="email"
            />
          </div>
          <div className="form-group">
            <label for="example-masked-time">Telefone</label>
            <InputMask
              mask="(99) 99999-9999"
              maskChar=""
              type="text"
              className="form-control"
              id="advanced-filter-document"
              name="phone"
              placeholder="(99) 99999-9999"
              value={data && data.whatsapp}
              onChange={(event) => {
                handleCustomerChange({
                  target: {
                    name: "whatsapp",
                    value: event.target.value.replace(/\D/g, ""),
                  },
                });
              }}
            />
          </div>

          <div className="form-group">
            <input
              onChange={(event) => {
                setFiles(event.currentTarget.files);
              }}
              type="file"
              id="attachment"
            />
          </div>

          {data && customers && customers.length > 0 ? (
            <div className={`block block-bordered`}>
              <div className="block-header">
                <div className="block-title">
                  <h6 className="text-muted">
                    <i className="fa fa-angle-right text-muted mr-1"></i>{" "}
                    Clientes com dados semelhantes
                  </h6>
                </div>
                <div className="block-tools">
                  <button
                    className="btn btn-sm btn-dual"
                    onClick={() => searchCustomers()}
                  >
                    <i className="si si-refresh" /> Recarregar clientes
                  </button>
                </div>
              </div>
              <div className="block-content">
                {data &&
                  customers.map((item) => (
                    <div
                      className={
                        data &&
                        `block block-fx-pop border-5x border border-left ${
                          data.customerId === item.id
                            ? "border-success"
                            : "border-secondary"
                        }`
                      }
                    >
                      <div className="block-header">
                        <div className="block-title">
                          <div>
                            <i className="fa fa-briefcase mr-1" />
                            {item.customerCode} - {item.name}
                          </div>
                          {item.phone !== undefined ? (
                            <div>
                              <br />
                              <i className="fa fa-phone ml-1 mr-1" />
                              {PhoneMask(item.phone)}
                            </div>
                          ) : (
                            ""
                          )}
                          {item.email !== undefined ? (
                            <div>
                              <br />
                              <i className="si si-envelope-letter ml-1 mr-1" />
                              {item.email}
                            </div>
                          ) : (
                            ""
                          )}
                        </div>
                        <div className="block-tools">
                          <button
                            className="btn btn-dual btn-sm"
                            onClick={() => openCustomer(item)}
                          >
                            <i className="fa fa-eye text-danger" />
                          </button>
                          {item.id === data.customerId ? (
                            <button
                              className="btn btn-success btn-sm"
                              onClick={() => selectCustomer(item)}
                            >
                              <i className="si si-check text-white" />{" "}
                              Selecionado
                            </button>
                          ) : (
                            <button
                              className="btn btn-dual btn-sm"
                              onClick={() => selectCustomer(item)}
                            >
                              <i className="si si-check text-info" /> Selecionar
                            </button>
                          )}
                        </div>
                      </div>
                    </div>
                  ))}
              </div>
            </div>
          ) : (
            ""
          )}

          <div className="form-group">
            <label for="example-masked-time">Serviço</label>
            <select
              onChange={(evt) => {
                if (
                  evt.target !== undefined &&
                  evt.target.value !== undefined &&
                  evt.target.value !== null &&
                  evt.target.value !== "Selecionar"
                ) {
                  handleChange(evt);
                  if (customers.length === 0) {
                    searchCustomers();
                  }
                  addService(evt.target.value);
                }
              }}
              className="form-control"
              name="serviceId"
            >
              <option value={null}>Selecionar</option>
              {servicesList &&
                servicesList.map((service) => (
                  <option
                    key={`${service.name}-${service.id}`}
                    value={service.id}
                  >
                    {service.name} - {MoneyMask(service.value, 2)}
                  </option>
                ))}
            </select>
          </div>

          {change &&
            selectedServices &&
            selectedServices.length > 0 &&
            selectedServices.map((item, idx) => (
              <div className="block block-bordered">
                <div className="block-header">
                  <div className="block-title">
                    <h6 className="text-muted">
                      <i className="fa fa-angle-right text-muted mr-1"></i>{" "}
                      Serviço {idx + 1}: {item.name} - 
                      {MoneyMask(item.value, 2)}
                    </h6>
                  </div>
                  <div className="block-tools">
                    <button
                      className="btn btn-white"
                      onClick={() => removeSelectedService(item, idx)}
                    >
                      <i className="si si-close text-danger" />
                    </button>
                  </div>
                </div>
                <div className="block-content">
                  {item.serviceOrgans &&
                    item.serviceOrgans.map((organ, index) => (
                      <div className="block block-fx-pop">
                        <div className="block-header">
                          <div className="block-title">{organ.organ.name}</div>
                          <div className="block-tools">
                            <button
                              className="btn btn-white"
                              onClick={() => removeOrganService(idx, index)}
                            >
                              <i className="si si-close text-danger" />
                            </button>
                          </div>
                        </div>
                      </div>
                    ))}
                </div>
              </div>
            ))}

          <div className="form-group">
            <label for="example-textarea-input">Observações</label>
            <textarea
              onChange={(evt) => handleChange(evt, FieldTypeObj.Text)}
              className="form-control"
              name="observation"
              rows="4"
              placeholder="Escreva suas observações"
            ></textarea>
          </div>
          <p className="p-3 bg-gray-light text-gray-darker" id="value">
            Total: {totalValue ? MoneyMask(totalValue, 2) : 0}
          </p>
        </div>
        <div className="col-sm-12">
          <div className="form-group">
            <label>Se trata de uma abertura?</label>
            <div className="custom-control custom-radio custom-control-primary mb-1">
              <input
                type="radio"
                className="custom-control-input"
                name="typeOpen"
                id="yes"
                value="1"
                onChange={(evt) => setIsOpen(false)}
              />
              <label className="custom-control-label" for="yes">
                Sim
              </label>
            </div>
            <div className="custom-control custom-radio custom-control-primary mb-1">
              <input
                type="radio"
                className="custom-control-input"
                id="no"
                name="typeOpen"
                value="2"
                defaultChecked={true}
                onChange={(evt) => setIsOpen(true)}
              />
              <label className="custom-control-label" for="no">
                Não
              </label>
            </div>
          </div>
          {showFields(isOpen)}

          <div className="form-group">
            <label>Preferência envio orçamento</label>
            <div className="custom-control custom-radio custom-control-primary mb-1">
              <input
                type="radio"
                className="custom-control-input"
                name="contactPreference"
                id="emailBudget"
                value="1"
                onChange={(evt) => handleChange(evt)}
              />
              <label className="custom-control-label" for="emailBudget">
                E-mail
              </label>
            </div>
            <div className="custom-control custom-radio custom-control-primary mb-1">
              <input
                type="radio"
                className="custom-control-input"
                id="whatsBudget"
                name="contactPreference"
                value="2"
                onChange={(evt) => handleChange(evt)}
              />
              <label className="custom-control-label" for="whatsBudget">
                Whatsapp
              </label>
            </div>
          </div>
        </div>
      </div>
    </Modal>
  );
}
