import React, { useState, useEffect } from "react";
import Modal from "@/app/core/layouts/main/components/Modal";
import { FieldTypeObj } from "@/app/core/util/helpers/FieldTypeHelper";
import FinancialHelper from "@/app/core/util/helpers/FinancialHelper";
import { OnDataChange } from "@/app/core/util/services/DataService";
import { toast } from "react-toastify";
import { Message } from "@/app/core/util/messages/GeralMessages";
import UserAccountController from "@/app/core/base/controllers/UserAccountController";

export default function UserCashModal({ show, onHide, selected, userId }) {
  const [data, setData] = useState({});
  const [loading, setLoading] = useState(false);
  const financialHelper = FinancialHelper();
  const cashFlow = UserAccountController();

  useEffect(() => {
    if (selected !== undefined && selected.id !== undefined) {
      setData(selected);
    } else {
      setData({});
    }
  }, [selected]);

  function handleChange(evt, type) {
    setData(OnDataChange(data, evt, type));
    if (
      evt.target.name == "type" &&
      evt.target.value == 9 &&
      (data.description == undefined || data.description == "")
    ) {
      setData({ ...OnDataChange(data, evt, type), description: "Saque" });
    }
  }

  async function save() {
    setLoading(true);
    if (data.id === undefined) {
      await cashFlow.Insert({ ...data, userId }, (res) => {
        toast.success(Message.Saved);
        onHide();
      });
    } else {
      await cashFlow.Update(data, (res) => {
        toast.success(Message.Saved);
        onHide();
      });
    }
  }

  return (
    <Modal
      title="Lançamento"
      show={show}
      isLoading={false}
      onHide={onHide}
      onSave={() => save()}
      onSubmit={() => save()}
    >
      <div className="row">
        <div className="col-md-12">
          <div className="form-group">
            <span>Valor</span>
            <input
              className="form-control"
              type={"number"}
              disabled={selected && selected.id != undefined ? true : false}
              name="value"
              value={data.value}
              onChange={(evt) => handleChange(evt, FieldTypeObj.Currency)}
            />
          </div>
        </div>
        <div className="col-md-12">
          <div className="form-group">
            <span>Descrição</span>
            <input
              className="form-control"
              name="description"
              value={data.description}
              onChange={(evt) => handleChange(evt)}
            />
          </div>
        </div>
        <div className="col-md-12">
          <div className="form-group">
            <span>Tipo de operação</span>
            <select
              className="form-control"
              name="type"
              disabled={selected && selected.id != undefined ? true : false}
              value={data.type}
              onChange={(evt) => handleChange(evt, FieldTypeObj.Number)}
            >
              <option>Selecione</option>
              {financialHelper.OperationTypeList.map((item) => (
                <option value={item.value}>{item.name}</option>
              ))}
            </select>
          </div>
        </div>
      </div>
    </Modal>
  );
}
