import React, { useEffect, useState } from "react";
import Storage from "@/app/core/util/Storage";
import { Autocomplete } from "@/app/core/util/helpers/AutocompleteHelper";
import { toast } from "react-toastify";
import { NegotiationTimelineController } from "@/app/core/base/controllers/NegotiationTimelineController";
import DropdownElement from "../elements/DropdownElement";
import FinancialTimelineItem from "./FinancialTimelineItem";

export default function FinancialTimeline({ customerId }) {
    const userData = Storage.getUserData();
    const [data, setData] = useState([]);
    const [channel, setChannel] = useState(1);
    const [talk, setTalk] = useState(null);
    const [filter, setFilter] = useState([]);
    const [question, setQuestion] = useState("");
    const [rawData, setRawData] = useState([]);

    useEffect(() => {
        loadTimeline();
    }, [customerId]);

    async function loadTimeline() {
        if (customerId === null) {
            return;
        }

        await NegotiationTimelineController.ByCustomer(customerId, (res) => {
            console.log("Timeline", res);
            for (let s in res) {
                res[s].time = new Date(res[s].createdDate).getTime();
            }

            res.sort(function (a, b) {
                return b.time - a.time;
            });

            setData(res);
            setRawData(res);
        });
    }

    function clearFields() {
        setTalk("");
    }

    async function saveUpdate() {
        let timeline = {
            observation: talk,
            customerId: customerId,
            userId: userData.id,
            channel: parseInt(channel),
        };

        await NegotiationTimelineController.Insert(timeline, (res) => {
            toast.info("Salvo com sucesso");
            clearFields();
            loadTimeline();
        });
    }

    function handleFilterChange(value) {
        setQuestion(value);
        let timeline_to_show = [];

        if (value.length > 0) {
            setFilter([]);
            timeline_to_show = Autocomplete(value, data, "observation");
            setFilter(timeline_to_show);
            console.log("as", timeline_to_show);
        } else {
            timeline_to_show = [];
            setFilter([]);
        }
    }

    return (
        <div className="row">
            <div className="col-md-12">
                <h2 className="content-heading">
                    <i className="fa fa-angle-right text-muted mr-1"></i> Timeline
                </h2>
                <div className="text-center">
                    <DropdownElement
                        submit={() => saveUpdate()}
                        icon=""
                        style={{ width: "100%", height: 250 }}
                        title={"Adicionar informacao"}
                    >
                        <div className="form-group">
                            <span>Qual o canal de contato?</span>
                            <select
                                type="text"
                                className="form-control"
                                onChange={(evt) => setChannel(evt.target.value)}
                            >
                                <option value={1}>Email</option>
                                <option value={2}>Whatsapp</option>
                                <option value={3}>Chat</option>
                                <option value={4}>Telefone</option>
                                <option value={5}>Recepção</option>
                            </select>
                        </div>
                        <div className="form-group">
                            <span>O que foi tratado?</span>
                            <textarea
                                type="text"
                                className="form-control"
                                value={talk}
                                onChange={(evt) => setTalk(evt.target.value)}
                            />
                        </div>
                    </DropdownElement>
                </div>
                <div className="form-group py-3">
                    <div className="input-group">
                        <input
                            type="text"
                            placeholder="Filtrar"
                            className="form-control"
                            id="search-filter"
                            value={question}
                            onChange={(evt) => handleFilterChange(evt.target.value)}
                        />
                        <div className="input-group-append">
                            <span
                                className="input-group-text"
                                onClick={() => setQuestion("") | setFilter([])}
                                title="Limpar campo"
                            >
                                <i className="si si-close"></i>
                            </span>
                        </div>
                    </div>
                </div>
            </div>
            {filter.length > 0 ? (
                <div className="col-md-12 py-3">
                    {filter &&
                        filter.map((item) => (
                            <FinancialTimelineItem
                                refresh={() => loadTimeline()}
                                data={item}
                            />
                        ))}
                </div>
            ) : (
                <div className="col-md-12 py-3">
                    {data &&
                        data.map((item) => (
                            <FinancialTimelineItem
                                refresh={() => loadTimeline()}
                                data={item}
                            />
                        ))}
                </div>
            )}
        </div>
    );
}
