import BaseService from "@/app/core/base/BaseService";
import { toast } from "react-toastify";
import { HandleErrors } from "../../util/helpers/ErrorHelper";

export default function UserAccountController() {
  async function Insert(data, response, error) {
    await BaseService(
      {
        method: "POST",
        url: `useraccount/insert`,
        data,
      },
      (res) => {
        response(res);
      },
      (err) =>
        error === undefined ? toast.error(HandleErrors(err)) : error(err),
    );
  }
  async function Update(data, response, error) {
    await BaseService(
      {
        method: "POST",
        url: `useraccount/update`,
        data,
      },
      (res) => {
        response(res);
      },
      (err) =>
        error === undefined ? toast.error(HandleErrors(err)) : error(err),
    );
  }

  async function SendToken(data, response, error) {
    await BaseService(
      {
        method: "POST",
        url: `account/2fa/validate`,
        data,
      },
      (res) => {
        response(res);
      },
      (err) =>
        error === undefined ? toast.error(HandleErrors(err)) : error(err),
    );
  }

  async function List(userId, startDate, endDate, response, error) {
    await BaseService(
      {
        method: "GET",
        url: `useraccount/${userId}?startDate=${startDate}&endDate=${endDate}`,
      },
      (res) => {
        response(res);
      },
      (err) =>
        error === undefined ? toast.error(HandleErrors(err)) : error(err),
    );
  }

  async function Report(userId, response, error) {
    await BaseService(
      {
        method: "GET",
        url: `useraccount/report/${userId}`,
      },
      (res) => {
        response(res);
      },
      (err) =>
        error === undefined ? toast.error(HandleErrors(err)) : error(err),
    );
  }

  async function Delete(docId, response, error) {
    await BaseService(
      {
        method: "DELETE",
        url: `useraccount/delete/${docId}`,
      },
      (res) => {
        response(res);
      },
      (err) =>
        error === undefined ? toast.error(HandleErrors(err)) : error(err),
    );
  }

  return {
    Insert,
    Update,
    List,
    Delete,
    Report,
    SendToken,
  };
}
