import JobsController from "@/app/core/base/controllers/JobsController";
import OrganListController from "@/app/core/base/controllers/OrganListController";
import { ReturnsByUser } from "@/app/core/base/controllers/ProcessReturnController";
import Modal from "@/app/core/layouts/main/components/CustomModal";
import { DateTranslateSimple } from "@/app/core/util/DateTranslater";
import { MoneyMask } from "@/app/core/util/helpers/MoneyHelper";
import OrganListHelper from "@/app/core/util/helpers/OrganListHelper";
import { ContractType } from "@/app/core/util/helpers/UserHelper";
import React, { useEffect, useState } from "react";
// import ReactToExcel from "react-html-table-to-excel";
import { Tabs, Tab, Panel } from "@bumaga/tabs";
import UserCashFlow from "./UserCashFlow";
import UserAccountController from "@/app/core/base/controllers/UserAccountController";
import ReportController from "@/app/core/base/controllers/ReportController";
import ComercialProdutivityReport from "./ComercialProdutivityReport";

export default function UserReportModal({ show, onHide, userId, level }) {
  const [data, setData] = useState([]);
  const [reavaliations, setReavaliations] = useState([]);
  const [returns, setReturns] = useState([]);
  // const [level, setLevel] = useState(1);
  const [total, setTotal] = useState(0);
  const [user, setUser] = useState({});
  const date = new Date();
  const [startDate, setStartDate] = useState(
    getFirstDayOfMonth(date.getFullYear(), date.getMonth())
      .toISOString()
      .substring(0, 10),
  );
  const [endDate, setEndDate] = useState(
    getFirstDayOfMonth(date.getFullYear(), date.getMonth() + 1)
      .toISOString()
      .substring(0, 10),
  );
  const jobsDone = JobsController();
  const organCtrl = OrganListController();
  const organListHelper = OrganListHelper();
  const userCashFlow = UserAccountController();

  function getFirstDayOfMonth(year, month) {
    return new Date(year, month, 1);
  }

  useEffect(() => {
    if (userId !== undefined && userId !== null && show == true) {
      loadReport();
    }
  }, [show, userId, startDate, endDate]);

  async function loadReport() {
    if (level != 1) {
      await jobsDone.Report(userId, startDate, endDate, (res) => {
        setUser(res.user);
        // setLevel(res.user.level);
        if (res.user.contractType === ContractType.PJ) {
          let value = 0;
          for (let s in res.jobs) {
            const item = res.jobs[s];
            if (item.remuneration !== undefined) {
              if (item.right === true) {
                value +=
                  item.remuneration.basePrice + item.remuneration.priceRight;
              } else {
                value += item.remuneration.basePrice;
              }
            } else {
              value += 0;
            }
          }

          // setTotal(value);
        }
        setData(res.jobs);
      });

      await userCashFlow.Report(userId, (res) => setTotal(res.value));

      await ReturnsByUser(userId, startDate, endDate, (res) => {
        setReturns(res);
      });

      await organCtrl.Reavaliations(userId, startDate, endDate, (res) => {
        setReavaliations(res);
      });
    } else {
      ReportController().BudgetOperatorReport(
        userId,
        startDate,
        endDate,
        (res) => {
          setData(res);
        },
      );
    }
  }

  function processLine(item) {
    if (item.remuneration === undefined) {
      return "Remuneração não configurada";
    } else if (item.right === false) {
      return "Feito";
    } else if (item.right === true) {
      return "Def. de 1ª";
    } else {
      return "Feito";
    }
  }

  return (
    <Modal title="Relatório de Usuário" show={show} onHide={onHide}>
      <Tabs>
        {user && user.contractType === ContractType.PJ ? (
          <div>
            <Tab>
              <button className="btn btn-square btn-dual">Relatório</button>
            </Tab>
            <Tab>
              <button className="btn btn-square btn-dual">Extrato</button>
            </Tab>
          </div>
        ) : (
          ""
        )}
        <Panel>
          <div className="row">
            <div className="form-group col-md-6">
              <span>Data de início</span>
              <input
                type="date"
                className="form-control form-control-alt form-control-sm"
                value={startDate}
                onChange={(evt) => setStartDate(evt.target.value)}
              />
            </div>
            <div className="form-group col-md-6">
              <span>Data fim</span>
              <input
                type="date"
                className="form-control form-control-alt form-control-sm"
                value={endDate}
                onChange={(evt) => setEndDate(evt.target.value)}
              />
            </div>
            {level != 1 ? (
              <div className="col-12 row">
                {/* * Produtividade * */}
                <div className="col-md-6">
                  <div className="title">
                    <h2 className="content-heading">
                      <i className="fa fa-angle-right text-muted mr-1"></i>{" "}
                      {data && data.length} Produtividade
                    </h2>
                  </div>
                </div>
                <div className="col-md-6 text-right">
                  {/* <ReactToExcel
                className="btn btn-info mt-3"
                table="table-element"
                filename={`Relatorio - ${user.firstName} periodo: ${startDate} - ${endDate}`}
                sheet="sheet 1"
                buttonText="Exportar"
              /> */}
                </div>
                <div className="col-12">
                  <table
                    className="table table-bordered table-hover"
                    id="table-element"
                    key={"table-element"}
                  >
                    <thead>
                      {level === 1 ? (
                        <tr>
                          <th>Id</th>
                          <th>Processo</th>
                          <th>Fim do orçamento</th>
                          <th>Data de criação</th>
                        </tr>
                      ) : (
                        <tr>
                          <th>Id</th>
                          <th>Processo</th>
                          <th>Serviço</th>
                          <th>Órgão</th>
                          {user && user.contractType === ContractType.PJ ? (
                            <th>Valor</th>
                          ) : (
                            ""
                          )}
                          <th>Data de finalização</th>
                          <th>Feedback</th>
                        </tr>
                      )}
                    </thead>
                    <tbody>
                      {data &&
                        data.map((item) => {
                          if (user && user.level === 1) {
                            return (
                              <tr>
                                <td>{item.id}</td>
                                <td>{item.processName}</td>
                                <td>
                                  {DateTranslateSimple(item.endBudgetDate)}
                                </td>
                                <td>{DateTranslateSimple(item.createdDate)}</td>
                              </tr>
                            );
                          } else {
                            return (
                              <tr>
                                <td>{item.id}</td>
                                <td>
                                  {item.process && item.process.processName}
                                </td>
                                <td>
                                  {item.organList &&
                                    item.organList.selectedService &&
                                    item.organList.selectedService.serviceName}
                                </td>
                                <td>
                                  {item.organList &&
                                    item.organList.organ &&
                                    item.organList.organ.name}
                                </td>
                                {user &&
                                user.contractType === ContractType.PJ ? (
                                  <td>
                                    {item.right === true
                                      ? `${MoneyMask(
                                          item.remuneration !== undefined
                                            ? item.remuneration.basePrice +
                                                item.remuneration.priceRight
                                            : 0,
                                          2,
                                        )}`
                                      : `${MoneyMask(
                                          item.remuneration !== undefined
                                            ? item.remuneration.basePrice
                                            : 0,
                                          2,
                                        )}`}
                                  </td>
                                ) : (
                                  ""
                                )}
                                <td>{DateTranslateSimple(item.createdDate)}</td>
                                <td>{item && processLine(item)}</td>
                              </tr>
                            );
                          }
                        })}
                      <tr>
                        <td>Total</td>
                        <td colSpan="6" className="text-right">
                          R${MoneyMask(total, 2)}
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>

                {/* * Reavaliações * */}
                <div className="col-md-6">
                  <div className="title">
                    <h2 className="content-heading">
                      <i className="fa fa-angle-right text-muted mr-1"></i>
                      {reavaliations && reavaliations.length} Reavaliações
                    </h2>
                  </div>
                </div>
                <div className="col-md-6 text-right">
                  {/* <ReactToExcel
                className="btn btn-info mt-3"
                table="table-reavaliations"
                filename={`Reavaliações - ${user.firstName} periodo: ${startDate} - ${endDate}`}
                sheet="sheet 1"
                buttonText="Exportar"
              /> */}
                </div>
                <div className="col-12">
                  <table
                    className="table table-bordered table-hover"
                    id="table-reavaliations"
                    key={"table-reavaliations"}
                  >
                    <thead>
                      {level === 1 ? (
                        <tr>
                          <th>Id</th>
                          <th>Processo</th>
                          <th>Fim do orçamento</th>
                          <th>Data de reavaliação</th>
                        </tr>
                      ) : (
                        <tr>
                          <th>Id</th>
                          <th>Processo</th>
                          <th>Serviço</th>
                          <th>Órgão</th>
                          <th>Data de reavaliação</th>
                          <th>Feedback</th>
                          <th>Tipo</th>
                        </tr>
                      )}
                    </thead>
                    <tbody>
                      {reavaliations &&
                        reavaliations.map((item) => {
                          if (user && user.level === 1) {
                            return (
                              <tr>
                                <td>{item.id}</td>
                                <td>{item.processName}</td>
                                <td>
                                  {DateTranslateSimple(item.endBudgetDate)}
                                </td>
                                <td>{DateTranslateSimple(item.createdDate)}</td>
                              </tr>
                            );
                          } else {
                            return (
                              <tr>
                                <td>{item.id}</td>
                                <td>
                                  {item.process && item.process.processName}
                                </td>
                                <td>
                                  {item.selectedService &&
                                    item.selectedService.serviceName}
                                </td>
                                <td>
                                  {item.organList &&
                                    item.organList.organ &&
                                    item.organList.organ.name}
                                </td>
                                <td>{DateTranslateSimple(item.createdDate)}</td>
                                <td>{item && item.description}</td>
                                <td>
                                  {item &&
                                    organListHelper.ReavaliationTypeTranslate(
                                      item.type,
                                    )}
                                </td>
                              </tr>
                            );
                          }
                        })}
                    </tbody>
                  </table>
                </div>

                {/* * Retornos * */}
                <div className="col-md-6">
                  <div className="title">
                    <h2 className="content-heading">
                      <i className="fa fa-angle-right text-muted mr-1"></i>{" "}
                      Retornados com exigência
                    </h2>
                  </div>
                </div>
                <div className="col-md-6 text-right">
                  {/* <ReactToExcel
                className="btn btn-info mt-3"
                table="table-returns"
                filename={`Retornados com exigência - ${user.firstName} periodo: ${startDate} - ${endDate}`}
                sheet="sheet 1"
                buttonText="Exportar"
              /> */}
                </div>
                <div className="col-12">
                  <table
                    className="table table-bordered table-hover"
                    id="table-returns"
                    key={"table-returns"}
                  >
                    <thead>
                      {level === 1 ? (
                        <tr>
                          <th>Id</th>
                          <th>Processo</th>
                          <th>Fim do orçamento</th>
                          <th>Retornado do órgão em</th>
                        </tr>
                      ) : (
                        <tr>
                          <th>Id</th>
                          <th>Processo</th>
                          <th>Serviço</th>
                          <th>Órgão</th>
                          <th>Retornado do órgão em</th>
                          <th>Feedback</th>
                        </tr>
                      )}
                    </thead>
                    <tbody>
                      {returns &&
                        returns.map((item) => {
                          if (user && user.level === 1) {
                            return (
                              <tr>
                                <td>{item.id}</td>
                                <td>{item.processName}</td>
                                <td>
                                  {DateTranslateSimple(item.endBudgetDate)}
                                </td>
                                <td>{DateTranslateSimple(item.createdDate)}</td>
                              </tr>
                            );
                          } else {
                            return (
                              <tr>
                                <td>{item.id}</td>
                                <td>{item.process.processName}</td>
                                <td>{item.selectedService.serviceName}</td>
                                <td>
                                  {item.organList &&
                                    item.organList.organ &&
                                    item.organList.organ.name}
                                </td>
                                <td>{DateTranslateSimple(item.createdDate)}</td>
                                <td>{item && item.observations}</td>
                              </tr>
                            );
                          }
                        })}
                    </tbody>
                  </table>
                </div>
              </div>
            ) : (
              ""
            )}
            {level == 1 ? (
              <div className="col-12">
                <ComercialProdutivityReport data={data} />
              </div>
            ) : (
              ""
            )}
          </div>
        </Panel>
        <Panel>
          <UserCashFlow user={user} />
        </Panel>
      </Tabs>
    </Modal>
  );
}
