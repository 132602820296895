import { toast } from "react-toastify";
import BaseService from "@/app/core/base/BaseService";
import { HandleErrors } from "../../util/helpers/ErrorHelper";

export default function WhatsAppController() {
    async function SendCharge(chargeId, response, error) {
        await BaseService(
            {
                method: "POST",
                url: `comunication/financial-whatsapp?chargeId=${chargeId}`,
            },
            (res) => response(res),
            (err) =>
                error === undefined ? toast.error(HandleErrors(err)) : error(err)
        );
    }
    async function SendEmailCharge(chargeId, response, error) {
        await BaseService(
            {
                method: "POST",
                url: `comunication/financial-email?chargeId=${chargeId}`,
            },
            (res) => response(res),
            (err) =>
                error === undefined ? toast.error(HandleErrors(err)) : error(err)
        );
    }

    return {
        SendCharge,
        SendEmailCharge,
    };
}
