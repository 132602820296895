import React, { useState, useEffect } from "react";
import Modal from "@/app/core/layouts/main/components/CustomModal";
import Storage from "@/app/core/util/Storage";
import { CompanyController } from "@/app/core/base/controllers/CompanyController";
import { toast } from "react-toastify";
import { Message } from "@/app/core/util/messages/GeralMessages";
import { OnDataChange } from "@/app/core/util/services/DataService";
import InputMask from "react-input-mask";
import { ConsultController } from "@/app/core/base/controllers/ConsultController";
import { CnpjMask, CpfMask } from "@/app/core/util/helpers/ContactHelper";
import CustomerHelper from "@/app/core/util/helpers/CustomerHelper";

export default function ReportCompaniesModal({ show, onHide, infos, newService,
    setSelected,
    setShowModal,
    openCompany,
    deleteCompany }) {
    const userData = Storage.getUserData();
    const [data, setData] = useState([]);
    const [loading, setLoading] = useState(false);

    useEffect(() => {
        setData(infos);
    }, [show, infos]);

    function save() {
    }

    function end() {
        toast.success(Message.Saved);
        setLoading(false);
        onHide();
    }

    function handleChange(evt, type) {
        setData(OnDataChange(data, evt, type));
    }

    return (
        <Modal
            show={show}
            onHide={onHide}
            title="Relatório"
            onSave={() => save()}
            onSubmit={() => save()}
            isLoading={loading}
        >

            <div className="block-content">
                <table className="table table-hover table-borderless">
                    <thead>
                        <tr>
                            <th>Nome</th>
                            <th>CNPJ</th>
                            <th>Ult. consulta</th>
                            <th>Situacao</th>
                            <th></th>
                            <th></th>
                        </tr>
                    </thead>
                    <tbody>
                        {data &&
                            data.map((item, index) => (
                                <tr>
                                    <td title={item.id}>
                                        {item.name}
                                    </td>
                                    <td>
                                        {item.doc.length == 11
                                            ? CpfMask(item.doc)
                                            : CnpjMask(item.doc)}
                                    </td>
                                    <td>{item.lastChecked}</td>
                                    <td>
                                        {item.status ==
                                            CustomerHelper().CompanyStatus.NotConsulted ? (
                                            <span className="badger badge-seccondary">
                                                Não consultado
                                            </span>
                                        ) : item.status ==
                                            CustomerHelper().CompanyStatus.Consulting ? (
                                            <span className="badge badge-warning">
                                                Consultando
                                            </span>
                                        ) : item.isRegular == true ? (
                                            <span className="badge badge-success">Regular</span>
                                        ) : (
                                            <span className="badge badge-danger">
                                                Irregular
                                            </span>
                                        )}
                                    </td>
                                    <td>
                                        <button
                                            type="button"
                                            class="btn btn-hero-sm btn-hero-success"
                                            onClick={() => newService(item)}
                                        >
                                            Novo Pedido
                                        </button>
                                    </td>
                                    <td>
                                        <span
                                            className="text-primary-lighter shaved-btn cursor-pointer  ml-2 mr-2"
                                            title="Editar"
                                            onClick={() =>
                                                setSelected(item) | setShowModal(true)
                                            }
                                        >
                                            <i className="fa fa-fw fa-pencil-alt" />
                                        </span>
                                        {item && item.organs && item.organs.length > 0 ? (
                                            <span
                                                className="text-primary-lighter shaved-btn cursor-pointer ml-2 mr-2"
                                                title="Visualizar"
                                                onClick={() => openCompany(item)}
                                            >
                                                <i class="fas fa-eye" />
                                            </span>
                                        ) : (
                                            ""
                                        )}
                                        <span
                                            className="text-primary-lighter shaved-btn cursor-pointer  ml-2 mr-2"
                                            title="Excluir"
                                            onClick={() => deleteCompany(item)}
                                        >
                                            <i class="fas fa-times" />
                                        </span>
                                    </td>
                                </tr>
                            ))}
                    </tbody>
                </table>
            </div>

        </Modal>
    );
}
