import React, { useState, useEffect, useRef } from "react";
import Modal from "@/app/core/layouts/main/components/Modal";
import { toast, ToastType } from "react-toastify";
import Storage from "@/app/core/util/Storage";
import { OnDataChange } from "@/app/core/util/services/DataService";
import DropdownElement from "../../elements/DropdownElement";
import { SelectedServicePendingByCustomer } from "@/app/core/base/controllers/ServicesController";
import { MoneyMask } from "@/app/core/util/helpers/MoneyHelper";
import { DateTranslate, DateTranslateSimple } from "@/app/core/util/DateTranslater";
import { ProcessPrice } from "@/app/core/util/helpers/BudgetHelper";
import ServiceChargeController from "@/app/core/base/controllers/ServiceChargeController";
import { Message } from "@/app/core/util/messages/GeralMessages";
import CashFlowController from "@/app/core/base/controllers/CashFlowController";
import FinancialHelper from "@/app/core/util/helpers/FinancialHelper";

export default function ChargeModal({ show, onHide, selected, customerId }) {
  const date = new Date();
  const userData = Storage.getUserData();
  const [data, setData] = useState({});
  const [pendingServices, setPendingServices] = useState([]);
  const [cashflow, setCashflow] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [change, setChange] = useState(date.getTime());

  const serviceChargeController = ServiceChargeController();
  const cashFlowController = CashFlowController();
  const financialHelper = FinancialHelper();

  useEffect(() => {
    if (selected && selected.id !== undefined) {
      let baseDate = selected.dueDate.split("T");
      setData({ ...selected, dueDate: baseDate[0] });
      console.log("Cobrança", selected);
    } else {
      setData({})
    }

    if (customerId !== undefined) {
      loadPendingServices()
      loadCashflow()
    }
  }, [show, selected]);


  function notifyChange() {
    setChange(date.getTime());
  }

  async function loadPendingServices() {
    await SelectedServicePendingByCustomer(customerId, res => {
      console.log("pending", res);
      for (let s in res) {
        res[s].checked = false;
      }
      setPendingServices(res);
    })
  }

  async function loadCashflow() {
    await cashFlowController.CustomerPendingEntries(customerId, res => {
      console.log("pending", res);
      for (let s in res) {
        res[s].checked = false;
      }
      setCashflow(res);
    })
  }

  function onChange(evt, type) {
    setData(OnDataChange(data, evt, type));
  }

  function saveSelecteds() {
    let adds = pendingServices.filter(x => x.checked === true);
    const old = pendingServices.filter(x => x.checked !== true);

    if (data && data.selectedServices !== undefined && data.selectedServices.length > 0) {
      adds = [...data.selectedServices, adds];
    }

    setPendingServices(old);
    setData({ ...data, selectedServices: adds });
    notifyChange();
  }

  function saveSelectedEntries() {
    let adds = cashflow.filter(x => x.checked === true);
    const old = cashflow.filter(x => x.checked !== true);

    if (data && data.entries !== undefined && data.entries.length > 0) {
      adds = [...data.entries, adds];
    }

    setCashflow(old);
    setData({ ...data, entries: adds });
    notifyChange();
  }

  function handleChange(evt, index) {
    let old = pendingServices;
    old[index].checked = evt.target.checked;
    setPendingServices(old);

    console.log(old[index]);
    notifyChange()
  }

  function handleEntryChange(evt, index) {
    let old = cashflow;
    old[index].checked = evt.target.checked;
    setCashflow(old);

    console.log(old[index]);
    notifyChange()
  }

  async function deleteServiceCharge(item) {
    if (window.confirm(Message.DeleteConfirm)) {
      let old = data.selectedServices;
      let adds = old.filter(x => x.id !== item.id);
      setData({ ...data, selectedServices: adds });
      if (item.checked === undefined) {
        await serviceChargeController.ChargeDeleteService(item.id, res => {
          toast.success(Message.DeleteSuccess)
        })
      } else {
        setPendingServices([...pendingServices, item])
      }
      notifyChange();
    }
  }

  async function deleteEntrieCharge(item) {
    if (window.confirm(Message.DeleteConfirm)) {
      let old = data.entries;
      let adds = old.filter(x => x.id !== item.id);
      setData({ ...data, entries: adds });
      if (item.checked === undefined) {
        await cashFlowController.UpdateEntry({ ...item, chargeId: null }, res => {
          toast.success(Message.DeleteSuccess)
        })
      } else {
        setCashflow([...cashflow, item]);
      }
      notifyChange();
    }
  }

  function selectAll() {
    let old = pendingServices;
    for (let s in old) {
      old[s].checked = true;
    }
    setPendingServices(old);
    notifyChange();
  }

  function desselectAll() {
    let old = pendingServices;
    for (let s in old) {
      old[s].checked = false;
    }
    setPendingServices(old);
    notifyChange();
  }

  function selectAllEntries() {
    let old = cashflow;
    for (let s in old) {
      old[s].checked = true;
    }
    setCashflow(old);
    notifyChange();
  }

  function desselectAllEntries() {
    let old = cashflow;
    for (let s in old) {
      old[s].checked = false;
    }
    setCashflow(old);
    notifyChange();
  }

  async function save() {
    console.log("Salvando?", data)
    if (data.name === undefined || data.name.length === 0) {
      toast.error("Preencha o nome da cobrança");
    } else if (data.dueDate === undefined || data.dueDate.length === 0) {
      toast.error("Preencha a data de vencimento");
    } else if (data.selectedServices === undefined || data.selectedServices.length === 0) {
      toast.error("Você não selecionou nenhum serviço");
    } else {
      if (data.id === undefined) {
        await serviceChargeController.Insert({ ...data, customerId: parseInt(customerId) }, async res => {
          for (let s in data.selectedServices) {
            if (data.selectedServices[s].checked !== undefined) {
              await serviceChargeController.ChargeInsertService(res.id, data.selectedServices[s].id, result => { console.log("resultado", result) })
            }
          }
          if (data.entries !== undefined && data.entries.length > 0) {
            for (let s in data.entries) {
              if (data.entries[s].checked !== undefined) {
                await cashFlowController.UpdateEntry({ ...data.entries[s], chargeId: res.id }, result => { console.log("resultado", result) })
              }
            }
          }
          toast.success(Message.Saved);
          onHide();
        })
      } else {
        await serviceChargeController.Update({ ...data, customerId: parseInt(customerId) }, async res => {
          for (let s in data.selectedServices) {
            if (data.selectedServices[s].checked !== undefined) {
              await serviceChargeController.ChargeInsertService(data.id, data.selectedServices[s].id, result => { console.log("resultado", result) })
            }
          }
          if (data.entries !== undefined && data.entries.length > 0) {
            for (let s in data.entries) {
              if (data.entries[s].checked !== undefined) {
                await cashFlowController.UpdateEntry({ ...data.entries[s], chargeId: data.id }, result => { console.log("resultado", result) })
              }
            }
          }
          toast.success(Message.Saved);
          onHide();
        })
      }
    }
  }

  return (
    <Modal title="Cobrança" show={show} isLoading={isLoading} onHide={onHide}
      onSave={() => save()}
      onSubmit={() => save()}>
      <div className="row">
        <div className="col-md-6">
          <div className="form-group">
            <span>Nome</span>
            <input
              type="text"
              className="form-control form-control-alt"
              name="name"
              value={data && data.name}
              onChange={(evt) => onChange(evt)}
            />
          </div>
        </div>
        <div className="col-md-6">
          <div className="form-group">
            <span>Data de vencimento</span>
            <input
              type="date"
              className="form-control form-control-alt"
              name="dueDate"
              value={data && data.dueDate}
              onChange={(evt) => onChange(evt)}
            />
          </div>
        </div>
        {/* ----------------- Serviços ----------------- */}
        <div className="col-md-12">
          <div className="block">
            <div className="block-header">
              <div className="block-title">
                Serviços
              </div>
              <div className="block-tools">
                <DropdownElement
                  icon="si si-plus"
                  title="Selecionar serviços"
                  orientation="left"
                  submit={() => saveSelecteds()}
                  button="Adicionar"
                  style={{ width: 500 }}
                >
                  <div className="row">
                    <div className="col-md-4">Serviços pendentes</div>
                    <div className="col-md-8">
                      <button className="btn btn-sm btn-dual" onClick={() => selectAll()}>
                        <i className="si si-list mr-1" /> Selecionar tudo
                      </button>
                      <button className="btn btn-sm btn-dual" onClick={() => desselectAll()}>
                        <i className="si si-close mr-1" /> Desselecionar
                      </button>
                      <button className="btn btn-sm btn-success" onClick={() => saveSelecteds()}>
                        <i className="si si-plus mr-1" />
                      </button>
                    </div>
                    {change && pendingServices && pendingServices.map((item, index) => (
                      <div className="col-md-12">
                        <div
                          className="js-task block block-rounded block-fx-pop block-fx-pop mb-2 animated fadeIn"
                          key={`selectedService-${item.id}-${index}`}
                        >
                          <table className="table table-borderless table-vcenter mb-0">
                            <tbody>
                              <tr>
                                <td className="js-task-content font-w600" style={{ width: 200 }} title="Serviço selecionado">
                                  <small>
                                    <i className="fa fa-tasks mr-1"></i>
                                    {item && item.serviceName} <br />
                                    <i className="si si-notebook mr-1"></i>
                                    {item && item.process && item.process.processName}
                                  </small>
                                </td>
                                <td className="js-task-content font-w600" style={{ width: 100 }} title="Valor pelo serviço">
                                  <small>
                                    <i className="far fa-money-bill-alt mr-1"></i>
                                    {item && `${MoneyMask(item.price, 2)}`}
                                  </small>
                                </td>
                                <td className="js-task-content font-w600" style={{ width: 100 }} title="Orçamento aceito em:">
                                  <small>
                                    <i className="far fa-calendar-check mr-1"></i>
                                    {item && item.process && DateTranslateSimple(item.process.endBudgetDate)}
                                  </small>
                                </td>
                                <td className="text-right" style={{ width: 30 }}>
                                  <div className="form-check">
                                    <input
                                      className="form-check-input"
                                      type="checkbox"
                                      name={item.id}
                                      id={`checkbox-${item.id}`}
                                      checked={item.checked}
                                      onChange={evt => handleChange(evt, index)}
                                    />
                                  </div>
                                </td>
                              </tr>
                            </tbody>
                          </table>
                        </div>
                      </div>
                    ))}
                  </div>
                </DropdownElement>
              </div>
            </div>
            <div className="block-content">
              {change && data && data.selectedServices && data.selectedServices.map((item, index) => (
                <div
                  className="block block-rounded block-bordered"
                  key={`selectedService-${item.id}-${index}`}>
                  <table className="table table-borderless table-vcenter mb-0">
                    <tbody>
                      <tr>
                        <td className="js-task-content font-w600" style={{ width: 200 }} title="Serviço selecionado">
                          <small>
                            <i className="fa fa-tasks mr-1"></i>
                            {item && item.serviceName} <br />
                            <i className="si si-notebook mr-1"></i>
                            {item && item.process && item.process.processName}
                          </small>
                        </td>
                        <td className="js-task-content font-w600" style={{ width: 150 }} title="Valor pelo serviço">
                          <small>
                            <i className="far fa-money-bill-alt mr-1"></i>
                            {item && ProcessPrice(item)}
                          </small>
                        </td>
                        <td className="js-task-content font-w600" style={{ width: 150 }} title="Orçamento aceito em">
                          <small>
                            <i className="si si-calendar mr-1"></i>
                            {item && item.process && DateTranslate(item.process.endBudgetDate)}
                          </small>
                        </td>
                        <td className="text-right" style={{ width: 100 }}>
                          <button
                            onClick={() => deleteServiceCharge(item, index)}
                            className="js-task-remove btn btn-sm btn-link text-danger"
                          >
                            <i className="fa fa-times fa-fw"></i>
                          </button>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              ))}
            </div>
          </div>
        </div>
        {/* ----------------- Operações ----------------- */}
        <div className="col-md-12">
          <div className="block">
            <div className="block-header">
              <div className="block-title">
                Taxas e descontos
              </div>
              <div className="block-tools">
                <DropdownElement
                  icon="si si-plus"
                  title="Selecionar lançamentos"
                  orientation="left"
                  submit={() => saveSelectedEntries()}
                  button="Adicionar"
                  style={{ width: 500 }}
                >
                  <div className="row">
                    <div className="col-md-4">Lançamentos pendentes</div>
                    <div className="col-md-8">
                      <button className="btn btn-sm btn-dual" onClick={() => selectAllEntries()}>
                        <i className="si si-list mr-1" /> Selecionar tudo
                      </button>
                      <button className="btn btn-sm btn-dual" onClick={() => desselectAllEntries()}>
                        <i className="si si-close mr-1" /> Desselecionar
                      </button>
                      <button className="btn btn-sm btn-success" onClick={() => saveSelectedEntries()}>
                        <i className="si si-plus mr-1" />
                      </button>
                    </div>
                    {change && cashflow && cashflow.map((item, index) => (
                      <div className="col-md-12">
                        <div
                          className="js-task block block-rounded block-fx-pop block-fx-pop mb-2 animated fadeIn"
                          key={`selectedService-${item.id}-${index}`}
                        >
                          <table className="table table-borderless table-vcenter mb-0">
                            <tbody>
                              <tr>
                                <td className="js-task-content font-w600" style={{ width: 200 }} title="Descrição">
                                  <small>
                                    <i className="fa fa-tasks mr-1"></i>
                                    {item && financialHelper.OperationTypeTranslateBadge(item.type)}<br />
                                    {item && item.description}
                                  </small>
                                </td>
                                <td className="js-task-content font-w600" style={{ width: 100 }} title="Valor">
                                  <small>
                                    <i className="far fa-money-bill-alt mr-1"></i> {item && `${MoneyMask(item.value, 2)}`}
                                  </small>
                                </td>
                                <td className="js-task-content font-w600" style={{ width: 100 }} title="Data de correspondência">
                                  <small>
                                    <i className="far fa-calendar-check mr-1"></i>
                                    {item && item.dueDate && DateTranslateSimple(item.dueDate)}
                                  </small>
                                </td>
                                <td className="text-right" style={{ width: 30 }}>
                                  <div className="form-check">
                                    <input
                                      className="form-check-input"
                                      type="checkbox"
                                      name={item.id}
                                      id={`checkbox-${item.id}`}
                                      checked={item.checked}
                                      onChange={evt => handleEntryChange(evt, index)}
                                    />
                                  </div>
                                </td>
                              </tr>
                            </tbody>
                          </table>
                        </div>
                      </div>
                    ))}
                  </div>
                </DropdownElement>
              </div>
            </div>
            <div className="block-content">
              {change && data && data.entries && data.entries.map((item, index) => (
                <div
                  className="block block-rounded block-bordered"
                  key={`selectedService-${item.id}-${index}`}>
                  <table className="table table-borderless table-vcenter mb-0">
                    <tbody>
                      <tr>
                        <td className="js-task-content font-w600" style={{ width: 200 }} title="Serviço selecionado">
                          <small>
                            <i className="fa fa-tasks mr-1"></i>
                            {item && financialHelper.OperationTypeTranslateBadge(item.type)}<br />
                            {item && item.description}
                          </small>
                        </td>
                        <td className="js-task-content font-w600" style={{ width: 150 }} title="Valor pelo serviço">
                          <small>
                            <i className="far fa-money-bill-alt mr-1"></i>
                            {item && MoneyMask(item.value, 2)}
                          </small>
                        </td>
                        <td className="text-right" style={{ width: 100 }}>
                          <button
                            onClick={() => deleteEntrieCharge(item, index)}
                            className="js-task-remove btn btn-sm btn-link text-danger"
                          >
                            <i className="fa fa-times fa-fw"></i>
                          </button>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              ))}
            </div>
          </div>
        </div>
        <div className="col-md-12 text-center">

        </div>
      </div>
    </Modal>
  );
}
