import React, { useEffect, useState } from "react";
import { ToastContainer, toast } from "react-toastify";
import Logo from "@/assets/img/logo.jpg";
import "react-toastify/dist/ReactToastify.css";
import { SelectedServiceUpdate } from "@/app/core/base/controllers/ServicesController";
import { NotifyUpdate } from "@/app/core/base/controllers/TimelineController";
import Storage from "@/app/core/util/Storage";
import TranslateDeliveryType, {
  DeliveryType,
} from "@/app/core/util/constants/DeliveryType";
import OrganListController from "@/app/core/base/controllers/OrganListController";
import NotificationController from "@/app/core/base/controllers/NotificationController";
import { NotificationTypeGeneral } from "@/app/core/util/helpers/NotificationHelper";
import OrganListHelper, {
  GetCurrentOperator,
  OrganStatus,
} from "@/app/core/util/helpers/OrganListHelper";
import { DateTimeNow } from "@/app/core/util/DateTranslater";
import { TimelineChannelSistema } from "@/app/core/util/helpers/TimelineHelper";
import DocMasterItem from "@/app/core/layouts/main-components/documents/DocMasterItem";
import DropdownElement from "@/app/core/layouts/main-components/elements/DropdownElement";
import { Message } from "@/app/core/util/messages/GeralMessages";
import GetBaseUrl from "@/app/core/base/BaseAddress";
import EvaluationModal from "@/app/core/layouts/main-components/evaluation/EvaluationModal";

function DeferredService(props, presentation) {
  const [selectedService, setSelectedService] = useState({});
  const [data, setData] = useState({});
  const [organList, setOrganList] = useState({});
  const [disabled, setDisabled] = useState(false);
  const [show, setShow] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [address, setAddress] = useState("");
  const [url, setUrl] = useState("");
  const [theLogo, setLogo] = useState(Logo);
  const enviroment = process.env.REACT_APP_ENV;

  const userData = Storage.getUserData();
  const notificationCtrl = NotificationController();
  const organCtrl = OrganListController();
  const organHelper = OrganListHelper();

  useEffect(() => {
    loadData();
  }, []);

  async function loadData() {
    await organCtrl.SelectById(props.match.params.key, (res) => {
      setSelectedService(res.selectedService);
      setData(res.process);
      checkCustomer(res.process);
      setOrganList(res);
      if (res && res.deliveryType !== 0 && res.deliveryType !== 1) {
        setDisabled(true);
      }

      if (res.reviews == undefined || res.reviews.length == 0) {
        setShowModal(true);
      }
      if (
        res.process &&
        res.process.customer &&
        res.process.customer.avatarImages &&
        res.process.customer.avatarImages.length > 0
      ) {
        setLogo(res.process.customer.avatarImages[0].url);
      }
      setUrl(
        ProcessLink(`${GetBaseUrl()}#/deferred/${props.match.params.key}`),
      );
    });
  }

  function ProcessLink(url) {
    url = url.replace(":", "%3A");
    url = url.replaceAll("/", "%2F");
    url = url.replace("#", "%23");
    return url;
  }

  function checkCustomer(process) {
    if (presentation == true) {
      return;
    }
    if (show != true) {
      if (userData !== null || enviroment == "local") {
        setShow(true);
        return;
      }
      let customerCode = window.prompt(
        "Para acessar essa tela, confirme seu código de cliente",
      );
      if (customerCode === process.customer.customerCode) {
        setShow(true);
      } else {
        let customerCode2 = window.prompt(
          "Código de cliente inválido, tente novamente : ",
        );
        if (customerCode2 === process.customer.customerCode) {
          setShow(true);
        } else {
          if (customerCode === process.customer.customerCode) {
            setShow(true);
          } else {
            let customerCode2 = window.prompt(
              "Código de cliente inválido, tente novamente : ",
            );
            if (customerCode2 === process.customer.customerCode) {
              setShow(true);
            } else {
              if (customerCode === process.customer.customerCode) {
                setShow(true);
              } else {
                let customerCode2 = window.prompt(
                  "Código de cliente inválido, tente novamente : ",
                );
                if (customerCode2 === process.customer.customerCode) {
                  setShow(true);
                } else {
                  let customerCode2 = window.prompt(
                    "Código de cliente inválido, tente novamente : ",
                  );
                  if (customerCode2 === process.customer.customerCode) {
                    setShow(true);
                  } else {
                    toast.info(
                      "Você errou o código muitas vezes e será redirecionado ao site",
                    );
                    setTimeout(() => {
                      window.location = "https://hasa.com.br";
                    }, 3900);
                  }
                }
              }
            }
          }
        }
      }
    }
  }

  async function returnProcess(i) {
    if (window.confirm("Tem certeza de que deseja selecionar essa opção?")) {
      if (i === 2 && address.length === 0) {
        toast.error(Message.PendingData);
        return;
      }
      await organCtrl.Update({ ...organList, deliveryType: i }, async (res) => {
        let history = ``;
        let message = `O cliente escolheu a forma de entrega : ${TranslateDeliveryType(
          i,
        )}.`;
        if (i == 2 || i == 6) {
          message += ` E escreveu o seguinte endereço para entrega: ${address}`;
          history = `Preparando a Devolução`;
        }

        if (DeliveryType.Retirada) {
          history = `Aguardando Retirada em nossa Sede `;
        }

        if (DeliveryType.Digital) {
          history = `Baixe o arquivo onde e quando quiser`;
        }
        await NotifyUpdate(selectedService.processId, message, false);
        await notificationCtrl.Insert(
          {
            message: message,
            processId: selectedService.processId,
            type: NotificationTypeGeneral,
            organListId: organList.id,
            userId: GetCurrentOperator(organList),
            view: false,
          },
          (res) => {},
        );

        loadData();
        toast.success("Salvo com sucesso");
      });
    }
  }

  async function confirmReceive() {
    let message = `Processo foi deferido no órgão ${organList.organ.name}. `;

    if (userData !== null) {
      message += `${
        userData.firstName !== undefined
          ? `Documentos baixados por ${userData.firstName}`
          : ""
      }`;
    }

    await NotifyUpdate(
      selectedService.processId,
      message,
      true,
      TimelineChannelSistema,
      organList.id,
    );

    await organCtrl.Update(
      {
        ...organList,
        status: OrganStatus.Done,
        stage: 10,
        statusDevolution: 6,
        endDevolutionDate: DateTimeNow(),
      },
      (res) => {
        loadData();
        toast.success("Salvo com sucesso");
      },
    );
  }

  return (
    <div style={{}} className="">
      <div className="container mb-0 " style={{}}>
        {props.presentation != true ? (
          <div className="row">
            <div
              className="col-lg-12"
              align="center"
              style={{ marginBottom: "1em" }}
            >
              <img src={theLogo} width="150px" />
              <br />
              <br />
            </div>
          </div>
        ) : (
          ""
        )}
        {show === true ? (
          <div>
            <div className="block block-fx-shadow">
              {props.presentation != true ? (
                <div className="text-center py-3">
                  <h3></h3>
                  <p>
                    O processo {data.processName} foi deferido no órgão. <br />
                    {organList && organList.deliveryType === 0 ? (
                      <span>
                        {organList &&
                        organList.deliveryType !== DeliveryType.Digital
                          ? "Como quer que sejam devolvidos os documentos originais?"
                          : ""}
                      </span>
                    ) : (
                      ""
                    )}
                  </p>
                  <div className="row">
                    <div className="col-3"></div>
                    <div className="col-6">
                      {organList &&
                        organList.docMasters &&
                        organList.docMasters.map((item) => (
                          <DocMasterItem
                            data={item}
                            organList={organList}
                            reload={() => loadData()}
                          />
                        ))}
                    </div>
                    <div className="col-3"></div>
                    <div className="col-3"></div>
                    <div className="col-6">
                      {organList && organList.deliveryType === 4 ? (
                        <p>
                          Você será muito bem vindo em nossa sede – O endereço
                          é: <br />
                          Rua Coelho Lisboa, 442 - CJ. 84, Tatuapé, São Paulo -
                          SP CEP 03323-040
                          <br />
                          <a
                            className="btn btn-info mt-3"
                            href="https://www.google.com/maps/place/Edif%C3%ADcio+Los+Angeles+-+R.+Coelho+Lisboa,+442+-+Sala+84+-+Cidade+M%C3%A3e+do+C%C3%A9u,+S%C3%A3o+Paulo+-+SP,+03323-040/@-23.548291,-46.5749347,17z/data=!3m1!4b1!4m6!3m5!1s0x94ce5e95a64fcb33:0x9ea9a729e9464a4!8m2!3d-23.5482959!4d-46.5723598!16s%2Fg%2F11qpkndyxw?entry=ttu"
                            target={"_blank"}
                            rel="noreferrer"
                          >
                            <i className="si si-map mr-1" /> Abrir no Maps
                          </a>
                        </p>
                      ) : (
                        ""
                      )}
                      {organList && organList.deliveryType === 4 ? (
                        <p className="block-rounded block-bordered block-header-default">
                          <br />
                          <i className="fa fa-exclamation-circle mr-3 text-danger"></i>
                          Seu processo encontra-se em nossa recepção para
                          retirada, caso não seja retirado no prazo de 10 dias
                          corridos, os físicos serão enviados automaticamente por carta simples.
                          <br/>
                          <br/>
                          <span className="font-w700">
                          ATENÇÃO: Se não for possível a retirada neste prazo, solicite a nossa equipe a devolução por Sedex antes de terminar o prazo. A modalidade carta simples não oferece rastreamento e a Hasa Documentos não se responsabiliza em caso de extravio por parte dos Correios 
                          </span>
                        </p>
                      ) : (
                        ""
                      )}
                    </div>
                    <div className="col-3"></div>
                  </div>
                </div>
              ) : (
                ""
              )}
            </div>
            {organList && organList.deliveryType !== DeliveryType.Digital ? (
              <div
                className="row"
                // style={{ marginTop: "2em" }}
              >
                <div className="col-md-4 text-center">
                  <DropdownElement
                    title="Sedex"
                    button="Salvar"
                    disabled={disabled}
                    drop="right"
                    submit={() => returnProcess(2)}
                    className="btn btn-hero-primary btn-block mt-3"
                    icon={
                      organList && organList.deliveryType === 2
                        ? "si si-check text-white"
                        : ""
                    }
                  >
                    <div className="form-group">
                      <span>
                        Preencha aqui o endereço completo para recebimento{" "}
                        <br />
                      </span>
                      <span className="text-muted">
                        <small>
                          Obs: O valor do Sedex será lançado na sua conta para
                          pagamento na próxima fatura.
                        </small>
                      </span>
                      <textarea
                        className="form-control"
                        placeholder="Endereço"
                        onChange={(evt) => setAddress(evt.target.value)}
                      />
                    </div>
                  </DropdownElement>
                </div>
                <div className="col-md-4 text-center">
                  <DropdownElement
                    title="Carta Simples"
                    button="Salvar"
                    drop="left"
                    disabled={disabled}
                    submit={() => returnProcess(6)}
                    className="btn btn-hero-warning btn-block mt-3"
                    icon={
                      organList && organList.deliveryType === 6
                        ? "si si-check text-white"
                        : ""
                    }
                  >
                    <div className="form-group">
                      <span>
                        Preencha aqui o endereço completo para recebimento
                      </span>
                      <br/>
                      <br/>
                      <span className="font-w700">

                      ATENÇÃO: Nossa sugestão é sempre optar pelo SEDEX, pois a carta simples não oferece rastreamento. A Hasa Documentos não se responsabiliza em caso de extravio por parte dos Correios.

                      </span>
                      <textarea
                        className="form-control"
                        placeholder="Endereço"
                        onChange={(evt) => setAddress(evt.target.value)}
                      />
                    </div>
                  </DropdownElement>
                </div>
                <div className="col-md-4 text-center">
                  <button
                    onClick={() => returnProcess(4)}
                    disabled={disabled}
                    className="btn btn-hero-success btn-block mt-3"
                  >
                    Retirada na Recepção{" "}
                    {organList.deliveryType === 4 ? (
                      <i className="si si-check text-white" />
                    ) : (
                      ""
                    )}
                  </button>
                </div>
              </div>
            ) : (
              ""
            )}

            {organList.deliveryType === DeliveryType.Digital &&
            organList.status !== OrganStatus.Done ? (
              <div className="text-center">
                <button
                  onClick={() => confirmReceive()}
                  disabled={organList && organList.status === OrganStatus.Done}
                  className="btn btn-hero-success"
                >
                  Confirmo o recebimento{" "}
                  <i className="si si-check text-white" />
                </button>
              </div>
            ) : (
              ""
            )}
          </div>
        ) : (
          ""
        )}
      </div>
      <ToastContainer className="toastr" />
      <EvaluationModal
        show={showModal}
        onHide={() => setShowModal(false)}
        processId={organList && organList.processId}
        organListId={organList && organList.id}
      />
    </div>
  );
}

export default DeferredService;
