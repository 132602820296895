import React, { useEffect, useState } from "react";
import ToDoController from "@/app/core/base/controllers/ToDoController";
import TaskModal from "./TaskModal";
import { GeneralHelper } from "@/app/core/util/helpers/GeneralHelper";

export default function ResumeTaskCard({reload, customerId }) {
  const [showModal, setShowModal] = useState(false);
  const [data, setData] = useState([]);

  useEffect(()=>{
    loadTasks()
},[customerId])

function loadTasks(){
    ToDoController().List(customerId, res => {
        console.log("tarefas", res)
        setData(res.filter(x => x.done == false))
    })
}

  function openItem() {
    window.location = "#/todo"
    // setShowModal(true);
  }

  return (
    <div>
      <div
        className="block block-fx-pop bg-white block-roundedMax mb-3 pb-3 ml-1 cursor"
        style={{ height: 210 }}
        onClick={() => openItem()}
      >
        <div className="block-header">
          <div className="block-title">
            <i className="fa fa-tasks fa-2x"></i>
          </div>
          <div className="block-tools">
            <i
              className="si si-question "
              title="Adicione, edite e exclua tarefas facilmente."
            />
          </div>
        </div>
        <div className="block-content pt-0">
          {data && data.length == 0 ? (
            <div>
              <h4 className=" mt-0">
                Você não tem nenhuma tarefa em aberto
              </h4>
              <button className="btn btn-info">Adicionar</button>
            </div>
          ) : (
            <div>
              <h3 className=" mt-0">
                {data && data.length}{" "}
                {data && data.length == 1 ? "Tarefa" : "Tarefas"}  em aberto
              </h3>

              <ul>
                {data && data.map((item, index) => {
                    if(index < 2 && item.description && item.description.length > 0){
                        console.log("Tarefa", item)
                        return (
                            <li className="text-dark"> {GeneralHelper.LegibleName(item.description, 35)}</li>
                        )
                    }
                })}
                {/* {data && data.length > 2 ? 
                <li>E outras...</li>:""
                } */}
              </ul>
            </div>
          )}
        </div>
      </div>
      <TaskModal
        show={showModal}
        customerId={customerId}
        reload={() => reload() }
        onHide={() => setShowModal(false) | loadTasks()}
      />

    </div>
  );
}
