import BaseService from "@/app/core/base/BaseService";
import { toast } from "react-toastify";
import { HandleErrors } from "../../util/helpers/ErrorHelper";
import Storage from "@/app/core/util/Storage";

export function ServiceInsert(data, response) {
  BaseService(
    {
      method: "POST",
      url: "service/insert",
      data: data,
    },
    (res) => {
      response(res);
    },
    (err) => {
      toast.error("Erro ao cadastrar Serviço");
    },
  );
}

export async function SelectedServicePendingByCustomer(customerId, response) {
  await BaseService(
    {
      method: "GET",
      url: `selectedservices/customer/pending/${customerId}`,
    },
    (res) => {
      response(res);
    },
    (err) => {
      console.log("SelectedService do processo", err, err.response);
      toast.error(HandleErrors(err));
    }
  );
}

export function ServiceUpdate(data, response) {
  BaseService(
    {
      method: "POST",
      url: "service/update",
      data: data,
    },
    (res) => {
      response(res);
    },
    (err) => {
      toast.error("Erro ao cadastrar Serviço");
    },
  );
}

export function ServiceDelete(id, response) {
  BaseService(
    {
      method: "DELETE",
      url: `service/delete/${id}`,
    },
    (res) => {
      response(res);
    },
    (err) => {
      toast.error("Erro ao excluir o Serviço");
    },
  );
}

export async function ServiceList(response) {
  await BaseService(
    {
      method: "GET",
      url: "service",
    },
    (res) => {
      response(res);
    },
    (err) => {
      toast.error(HandleErrors(err));
    },
  );
}

export function ServiceListInsert(data, response) {
  BaseService(
    {
      method: "POST",
      url: "selectedservices/insert",
      data: data,
    },
    (res) => {
      response(res);
    },
    (err) => {
      toast.error(HandleErrors(err));
    },
  );
}

export async function SelectedServiceUpdate(data, response) {
  await BaseService(
    {
      method: "POST",
      url: "selectedservices/update",
      data: data,
    },
    (res) => {
      response(res);
    },
    (err) => {
      toast.error(HandleErrors(err));
    },
  );
}

export function ChangeSelectedService(selectedserviceId, newService, response) {
  BaseService(
    {
      method: "POST",
      url: `selectedservices/change/${selectedserviceId}/?newService=${newService}`,
    },
    (res) => {
      response(res);
    },
    (err) => {
      toast.error(HandleErrors(err));
    },
  );
}

export function PlayOrPauseService(selectedServiceId, serviceStatus, response) {
  BaseService(
    {
      method: "GET",
      url: `selectedservices/ongoing/${selectedServiceId}?serviceStatus=${serviceStatus}`,
    },
    (res) => {
      response(res);
    },
    (err) => {
      toast.error(HandleErrors(err));
    },
  );
}

export function SelectedServices(id, response) {
  BaseService(
    {
      method: "GET",
      url: `selectedservices/process/${id}`,
    },
    (res) => {
      response(res);
    },
    (err) => {
      toast.error(HandleErrors(err));
    },
  );
}

export function CompleteSelectedService(id, response) {
  BaseService(
    {
      method: "GET",
      url: `selectedservices/complete/${id}`,
    },
    (res) => {
      response(res);
    },
    (err) => {
      toast.error(HandleErrors(err));
    },
  );
}

export function StageSelectedServices(stage, response) {
  BaseService(
    {
      method: "GET",
      url: `selectedservices/department/${stage}`,
    },
    (res) => {
      response(res);
    },
    (err) => {
      toast.error(HandleErrors(err));
    },
  );
}

export function SelectedServiceByDate(startDate, endDate, response) {
  BaseService(
    {
      method: "GET",
      url: `selectedservices/dates/?startDate=${startDate}&endDate=${endDate}`,
    },
    (res) => {
      response(res);
    },
    (err) => {
      toast.error(HandleErrors(err));
    },
  );
}

export function GetSelectedService(id, response) {
  BaseService(
    {
      method: "GET",
      url: `selectedservices/${id}`,
    },
    (res) => {
      response(res);
    },
    (err) => {
      toast.error(HandleErrors(err));
    },
  );
}

export async function GetUserSelectedServices(userId, response) {
  await BaseService(
    {
      method: "GET",
      url: `selectedservices/user/${userId}`,
    },
    (res) => {
      response(res);
    },
    (err) => {
      toast.error(HandleErrors(err));
    },
  );
}

export function RemoveSelectedService(id, response) {
  BaseService(
    {
      method: "DELETE",
      url: `selectedservices/delete/${id}`,
    },
    (res) => {
      response(res);
    },
    (err) => {
      toast.error(HandleErrors(err));
    },
  );
}

export function DeleteSelectedService(
  id,
  motive,
  reimbursement,
  reimbursementType,
  response,
) {
  BaseService(
    {
      method: "DELETE",
      url: `selectedservices/ongoing/delete/${id}?observation=${motive}&reimbursement=${reimbursement}&reimbursementType=${reimbursementType}`,
    },
    (res) => {
      response(res);
    },
    (err) => {
      toast.error(HandleErrors(err));
    },
  );
}

export function UpdateSelectedServiceStatus(selectedService, sts, response) {
  const userData = Storage.getUserData();
  let service = selectedService;
  if (userData.level === 2) {
    service.statusAnalysis = sts;
  }

  if (userData.level === 3) {
    service.statusPreparation = sts;
  }

  if (userData.level === 4) {
    service.statusDevolution = sts;
  }

  SelectedServiceUpdate(service, (res) => {
    response(res);
  });
}

export async function LastBuy(customerId, response, error) {
  await BaseService(
    {
      method: "GET",
      url: `SelectedServices/lastbuy/${customerId}`,
    },
    (res) => response(res),
    (err) =>
      error === undefined ? toast.error(HandleErrors(err)) : error(err),
  );
}
