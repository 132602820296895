import BaseService from "@/app/core/base/BaseService";
import { toast } from "react-toastify";
import { HandleUploadErrors } from "../../util/helpers/DocsHelper";

export const NegotiationTimelineController = {
    Insert: async function (data, response, error) {
        await BaseService(
            {
                method: "POST",
                url: `negotiationtimeline/insert`,
                data
            },
            (res) => {
                response(res);
            },
            (err) =>
                error === undefined ? toast.error(HandleUploadErrors(err)) : error(err)
        );
    },
    Update: async function (data, response, error) {
        await BaseService(
            {
                method: "POST",
                url: `negotiationtimeline/update`,
                data
            },
            (res) => {
                response(res);
            },
            (err) =>
                error === undefined ? toast.error(HandleUploadErrors(err)) : error(err)
        );
    },
    Delete: async function (reminderId, response, error) {
        await BaseService(
            {
                method: "DELETE",
                url: `negotiationtimeline/delete/${reminderId}`,
            },
            (res) => {
                response(res);
            },
            (err) =>
                error === undefined ? toast.error(HandleUploadErrors(err)) : error(err)
        );
    },
    ByCustomer: async function (customerId, response, error) {
        await BaseService(
            {
                method: "GET",
                url: `negotiationtimeline/customer/${customerId}`,
            },
            (res) => {
                response(res);
            },
            (err) =>
                error === undefined ? toast.error(HandleUploadErrors(err)) : error(err)
        );
    },
}