import BaseService from "@/app/core/base/BaseService";
import { toast } from "react-toastify";
import { HandleErrors } from "../../util/helpers/ErrorHelper";

export async function SendInsert(data, response) {
  await BaseService(
    {
      method: "POST",
      url: "send/insert",
      data: data,
    },
    (res) => {
      response(res);
    },
    (err) => {
      toast.error(HandleErrors(err));
    },
  );
}

export function ResendInsert(data, response) {
  BaseService(
    {
      method: "POST",
      url: "send/resend/insert",
      data: data,
    },
    (res) => {
      response(res);
    },
    (err) => {
      toast.error(HandleErrors(err));
    },
  );
}

export function ScheduleSend(Body,
  OrganListId,
  ProcessId,
  Subject,
  TargetEmail,
  TargetName,
  From,
  Type, response) {
  BaseService(
    {
      method: "POST",
      url: "send/schedule/insert",
      data: {
        Body,
        OrganListId,
        ProcessId,
        Subject,
        TargetEmail,
        TargetName,
        From,
        Type,
      },
    },
    (res) => {
      response(res);
    },
    (err) => {
      toast.error(HandleErrors(err));
    },
  );
}

export function ResendDelete(resendId, response) {
  BaseService(
    {
      method: "DELETE",
      url: `send/resend/delete/${resendId}`,
    },
    (res) => {
      response(res);
    },
    (err) => {
      toast.error(HandleErrors(err));
    },
  );
}

export function ResendList(response) {
  BaseService(
    {
      method: "GET",
      url: `send/resend`,
    },
    (res) => {
      response(res);
    },
    (err) => {
      toast.error(HandleErrors(err));
    },
  );
}

export function AutomaticResend(response) {
  BaseService(
    {
      method: "GET",
      url: `send/automatic-resend`,
    },
    (res) => {
      response(res);
    },
    (err) => {
      toast.error(HandleErrors(err));
    },
  );
}

export function SendProcessList(processId, response) {
  BaseService(
    {
      method: "GET",
      url: `send/process/${processId}`,
    },
    (res) => {
      response(res);
    },
    (err) => {
      toast.error(HandleErrors(err));
    },
  );
}

export function SendOrganListList(organListId, response) {
  BaseService(
    {
      method: "GET",
      url: `send/organList/${organListId}`,
    },
    (res) => {
      response(res);
    },
    (err) => {
      toast.error(HandleErrors(err));
    },
  );
}

export async function OldBudgetsList(userId, type, response) {
  await BaseService(
    {
      method: "GET",
      url: `process/oldbudgets/${userId}?type=${type}`,
    },
    (res) => {
      response(res);
    },
    (err) => {
      toast.error(HandleErrors(err));
    },
  );
}

export async function OldExigenciesList(userId, type, response) {
  await BaseService(
    {
      method: "GET",
      url: `exigency/oldexigencies/${userId}?type=${type}`,
    },
    (res) => {
      response(res);
    },
    (err) => {
      toast.error(HandleErrors(err));
    },
  );
}

export async function DeliveryDevolution(response) {
  await BaseService(
    {
      method: "GET",
      url: `organlist/delivery/devolution`,
    },
    (res) => {
      response(res);
    },
    (err) => {
      toast.error(HandleErrors(err));
    },
  );
}

export function SendDelete(sendId, response) {
  BaseService(
    {
      method: "Delete",
      url: `send/delete/${sendId}`,
    },
    (res) => {
      response(res);
    },
    (err) => {
      toast.error(HandleErrors(err));
    },
  );
}
