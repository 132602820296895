import {
  ServiceListInsert,
  ServiceList,
} from "@/app/core/base/controllers/ServicesController";
import { FieldTypeObj } from "@/app/core/util/helpers/FieldTypeHelper";
import { MoneyMask } from "@/app/core/util/helpers/MoneyHelper";
import { Message } from "@/app/core/util/messages/GeralMessages";
import { OnDataChange } from "@/app/core/util/services/DataService";
import React, { useEffect, useState } from "react";
import { toast } from "react-toastify";
import DropdownElement from "../elements/DropdownElement";
import { Typeahead } from "react-bootstrap-typeahead";
import Storage from "@/app/core/util/Storage";

export default function ServiceDropdown({
  processId,
  reload,
  selectedServices,
}) {
  const [data, setData] = useState({});
  const [services, setServices] = useState(
    Storage.getServices() != null ? Storage.getServices() : [],
  );
  const [query, setQuery] = useState("");

  useEffect(() => {
    loadPreServices();
  }, [processId]);

  async function loadPreServices() {
    if (!services || services.length == 0) {
      ServiceList((res) => {
        Storage.setServices(res);
        setServices(res);
      });
    }
  }

  function handleChange(evt, type) {
    setData(OnDataChange(data, evt, type));
  }

  function addNewService() {
    ServiceListInsert(
      {
        ...data,
        processId: processId,
        deliveryType: 0,
        position: selectedServices.length + 1,
      },
      (res) => {
        toast.info(Message.Saved);
        setData({ description: "" });
        setQuery("");
        reload();
      },
    );
  }

  return (
    <DropdownElement
      submit={() => addNewService()}
      style={{ width: 450 }}
      icon={"si si-plus"}
      title="Adicionar serviço"
    >
      <div className="form-group">
        <span>Serviço</span>
        <Typeahead
          options={services}
          onSearch={(q) => setQuery(q)}
          defaultInputValue={query}
          clearButton={true}
          id="advanced-filter-state"
          name="name"
          placeholder="Selecionar serviço"
          labelKey={(option) =>
            `${option.name} - ${MoneyMask(option.value, 2)}`
          }
          onChange={(selected) => {
            if (selected[0] !== undefined) {
              setData({ ...data, serviceId: selected[0].id });
            }
          }}
        />
        {/* <select
          className="form-control"
          name="serviceId"
          value={data.serviceId}
          onChange={(evt) => handleChange(evt, FieldTypeObj.Number)}
        >
          <option value={null}>Selecione</option>
          {services.map((item) => (
            <option value={item.id}>
              {item.name} - R${MoneyMask(item.value, 2)}
            </option>
          ))}
        </select> */}
      </div>
      <div className="form-group">
        <span>Descrição</span>
        <textarea
          className="form-control"
          name="description"
          value={data && data.description}
          onChange={(evt) => handleChange(evt)}
        />
      </div>
    </DropdownElement>
  );
}
