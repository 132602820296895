import { toast } from "react-toastify";
import BaseService from "@/app/core/base/BaseService";
import { HandleErrors } from "../../util/helpers/ErrorHelper";
import Storage from "../../util/Storage";

export default function CollectorHistoryController() {
    function InsertHistory(message, customerId) {
        const user = Storage.getUserData();
        const body = {
            userId: user.id,
            description: message,
            // type: ,
            customerId: customerId,
        };

        Insert(body, res => null);
    }
    async function MyHistory(response, error) {
        await BaseService(
            {
                method: "GET",
                url: `collectorhistory/my-history`,
            },
            (res) => response(res),
            (err) =>
                error === undefined ? toast.error(HandleErrors(err)) : error(err)
        );
    }
    async function Today(response, error) {
        await BaseService(
            {
                method: "GET",
                url: `collectorhistory/today`,
            },
            (res) => response(res),
            (err) =>
                error === undefined ? toast.error(HandleErrors(err)) : error(err)
        );
    }
    async function Insert(data, response, error) {
        await BaseService(
            {
                method: "POST",
                url: `collectorhistory/insert`,
                data
            },
            (res) => response(res),
            (err) =>
                error === undefined ? toast.error(HandleErrors(err)) : error(err)
        );
    }
    async function Update(data, response, error) {
        await BaseService(
            {
                method: "POST",
                url: `collectorhistory/update`,
                data
            },
            (res) => response(res),
            (err) =>
                error === undefined ? toast.error(HandleErrors(err)) : error(err)
        );
    }

    return {
        MyHistory,
        Today,
        Insert,
        Update,
        InsertHistory,
    };
}
