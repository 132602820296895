import React, { useEffect, useState } from "react";
import Storage from "@/app/core/util/Storage";
import { toast } from "react-toastify";
import ExigencyFulfillmentComponent from "./ExigencyFulfillmentModal";
import ExigencyItem from "./exigencies/ExigencyItem";
import ProcessController from "../../base/controllers/ProcessController";
import {
  ExigencyChangeOrgan,
  ExigencyDelete,
  ExigencyList,
  ExigencyUpdate,
  InsertExigency,
} from "../../base/controllers/ExigenciesController";
import { Autocomplete } from "../../util/helpers/AutocompleteHelper";
import DropdownElement from "./elements/DropdownElement";
import { ExigencyTemplateList } from "../../base/controllers/ExigencyTemplateController";
import DocumentsController from "../../base/controllers/DocumentsController";
import { SendExigencies } from "../../base/controllers/ContactController";
import { ExigencyStatus } from "../../util/constants/ExigenciesConstants";
import DocInput from "./documents/DocInput";
import { CopyToClipboard } from "react-copy-to-clipboard";
import GetBaseUrl from "../../base/BaseAddress";
import { NotifyUpdate } from "../../base/controllers/TimelineController";
import {
  TimelineChannelEmail,
  TimelineChannelWhatsapp,
} from "../../util/helpers/TimelineHelper";
import { SelectedServiceAnalysisStatus } from "../../util/helpers/SelectedServiceHelper";
import { ExigencyType } from "../../util/helpers/ExigencyActionHelper";
import OrganListController from "../../base/controllers/OrganListController";
import { SendInsert } from "../../base/controllers/SendController";
import OrganListHelper, {
  OrganStatus,
} from "../../util/helpers/OrganListHelper";
import CustomContact from "./contact/CustomContact";
import { PreparationStep } from "../../util/helpers/UserHelper";
import JobsController from "../../base/controllers/JobsController";
import { Message } from "../../util/messages/GeralMessages";
import { ExigencyTimelineInsert } from "../../base/controllers/ExigencyTimelineController";
import ProcessHelper from "../../util/helpers/ProcessHelpers";
import { ComunicationController } from "../../base/controllers/ComunicationController";
import { Dropdown } from "react-bootstrap";

export default function ExigenciesComponent({
  processId,
  preLoad,
  refresh,
  disabled,
  organListId,
}) {
  const userData = Storage.getUserData();

  const [showModal, setShowModal] = useState(false);

  const [data, setData] = useState([]);
  const [content, setContent] = useState(null);
  const [action, setAction] = useState(2);
  const [file, setFile] = useState(null);
  const [exigencies, setExigencies] = useState(Storage.getTemplates());
  const [exigencyData, setExigencyData] = useState(null);
  const [responsable, setResponsables] = useState([]);
  const [relatedExigence, setRelatedExigence] = useState(null);
  const [results, setResults] = useState([]);
  const [filter, setFilter] = useState([]);
  const [question, setQuestion] = useState("");
  const [disable, setDisable] = useState(false);
  const [disabledWhats, setDisabledWhats] = useState(false);
  const [loading, setLoading] = useState(false);
  const [organList, setOrganList] = useState({});
  const [process, setProcess] = useState({});

  const processCtrl = ProcessController();
  const organCtrl = OrganListController();
  const organHelper = OrganListHelper();
  const processHelper = ProcessHelper();
  const docsCtrl = DocumentsController();
  const jobsDone = JobsController();
  const date = new Date();
  const [change, setChange] = useState(date.getTime());

  useEffect(() => {
    getModels();
    if (preLoad != undefined && preLoad.id != undefined) {
      processData();
    } else {
      loadResponsables();
      loadExigencies();
    }
    if (disabled === undefined || disabled === null) {
      setDisable(false);
    } else {
      setDisable(disabled);
    }
    if (userData.level > 1 && userData.level < 4 && userData.role < 2) {
      setDisabledWhats(true);
    } else {
      setDisabledWhats(false);
    }
  }, [refresh, processId, disabled]);

  useEffect(() => {}, [filter]);

  function notifyChange() {
    setChange(date.getTime());
  }

  function processData() {
    setProcess(preLoad);
    processResponsibles(preLoad);
    processExigencies(preLoad.exigencies);
    setOrganList(processHelper.GetCurrentOrgan(organListId, preLoad));
  }

  async function loadResponsables() {
    if (processId == undefined || processId == null) {
      return;
    }
    await processCtrl.Select(processId, (res) => {
      processResponsibles(res);
    });
  }

  function processResponsibles(res) {
    if (res.responsible) {
      if (res.responsible.phone === undefined) {
        res.responsible.phone = res.customer.whatsapp;
      }
      setResponsables(res.responsible);
    }
  }

  function showRelated(item) {
    return (
      <div className="alert alert-danger alert-dismissable" role="alert">
        <table className="table">
          <tr>
            <td>
              <h4 className="alert-heading font-size-h4 my-2">
                Exigência complementar
              </h4>
              <p className="mb-0">
                {item && item.code} - {item && item.description}
              </p>
            </td>
            <td style={{ width: 50 }}>
              <button
                className="btn btn-sm btn-link text-info"
                onClick={() => saveComplement(item)}
              >
                <i className="fa fa-fw fa-check"></i> Adicionar
              </button>
            </td>
          </tr>
        </table>
      </div>
    );
  }

  function saveComplement(item) {
    let exigency = {
      processId: processId,
      description: item.description,
      action: item.action,
      fulfill: false,
      status: 1,
      fileUrl: "",
      organListId,
    };

    register(exigency);
  }

  function showEditItem(item) {
    setExigencyData(item);
    setShowModal(true);
  }

  function getModels() {
    if (!exigencies || exigencies.length == 0) {
      ExigencyTemplateList((res) => {
        for (let s in res) {
          res[s].name = `${res[s].code} - ${res[s].description}`;
        }
        setExigencies(res);
      });
    }
  }

  function loadExigencies() {
    if (refresh) {
      refresh();
    } else {
      reprocessExigencies();
    }
  }

  function reprocessExigencies() {
    if (processId !== undefined && processId !== null) {
      ExigencyList(processId, (res) => {
        processExigencies(res);
      });
      if (
        organListId !== undefined &&
        organListId != null &&
        organList.id == undefined
      ) {
        organCtrl.SelectById(organListId, (res) => setOrganList(res));
      }
    }
  }

  function processExigencies(res) {
    setData([]);
    if (res !== null && res !== undefined) {
      let itens = [];
      let waiting = res.filter(
        (x) =>
          x.status === ExigencyStatus.waiting ||
          x.status === ExigencyStatus.WaitingFulfillment,
      );
      let waitingConfirmation = res.filter(
        (x) => x.status === ExigencyStatus.waitingConfirmation,
      );
      let done = res.filter((x) => x.status === ExigencyStatus.done);
      itens = [...waitingConfirmation, ...waiting, ...done];
      setData(itens);
    } else {
      setData(res);
    }
    if (res.length > 0 && organListId !== undefined) {
      processSelectedStatus(res);
    }
    notifyChange();
  }

  async function processSelectedStatus(exigenciesRes) {
    let results = exigenciesRes.filter(
      (x) =>
        x.status === ExigencyStatus.waitingConfirmation ||
        x.status === ExigencyStatus.waiting,
    );
    if (results.length === 0) {
      if (organList.id == undefined) {
        await organCtrl.SelectById(organListId, (res) => {
          setOrganList(res);
          handleStatus(res);
        });
      } else {
        handleStatus(organList);
      }
    }
  }

  function handleStatus(res) {
    console.log("Loop em algum lugar", res);
    if(res.statusAnalysis !==
      SelectedServiceAnalysisStatus.Ongoing){
        if (
          res.statusAnalysis ===
            SelectedServiceAnalysisStatus.WaitingOperatorConfirmation ||
          res.statusAnalysis ===
            SelectedServiceAnalysisStatus.WaitingExigencyFulfillment
        ) {
          notifyStatus(SelectedServiceAnalysisStatus.Ongoing);
        } else if (
          res.statusPreparation ===
            SelectedServiceAnalysisStatus.WaitingOperatorConfirmation ||
          res.statusPreparation ===
            SelectedServiceAnalysisStatus.WaitingExigencyFulfillment
        ) {
          notifyStatus(SelectedServiceAnalysisStatus.Ongoing);
        } else if (
          res.statusDevolution ===
          organHelper.DevolutionStatus.WaitingOperatorConfirmation
        ) {
          notifyStatus(SelectedServiceAnalysisStatus.Ongoing);
        }
      }
  }

  async function response(bool, id, exigency) {
    let list = data;
    setData([]);
    setTimeout(() => {
      for (let s in list) {
        if (list[s].id == exigency.id) {
          list[s].status =
            bool === "true" || bool === true
              ? ExigencyStatus.done
              : ExigencyStatus.waiting;
        }
      }

      processExigencies(list);
    }, 300);

    await ExigencyUpdate(
      {
        ...exigency,
        status:
          bool === "true" || bool === true
            ? ExigencyStatus.done
            : ExigencyStatus.waiting,
      },
      async (res) => {
        if (bool == false || bool == "false") {
          await reportNotDone();
        } else {
          await reportDone(exigency);
        }
        loadExigencies();
      },
    );
  }

  async function reportNotDone() {
    if (organList.id !== undefined) {
      let organ = organList;
      if (organ.stage === 2) {
        organ.statusAnalysis =
          SelectedServiceAnalysisStatus.WaitingExigencyFulfillment;
      } else if (organ.stage === 3) {
        organ.statusPreparation =
          SelectedServiceAnalysisStatus.WaitingExigencyFulfillment;
      } else if (organ.stage === 4) {
        organ.statusDevolution =
          organHelper.DevolutionStatus.WaitingExigencyFulfillment;
      }
      await organCtrl.Update(organ, (res) => {
        if (refresh !== undefined) {
          refresh();
        }
      });
    } else {
      if (organList.id == undefined) {
        await organCtrl.SelectById(organListId, async (res) => {
          setOrganList(res);
          reportNotDoneStatus();
        });
      } else {
        reportNotDoneStatus(organList);
      }
    }
  }

  async function reportNotDoneStatus(res) {
    let organ = res;
    if (organ.stage === 2) {
      organ.statusAnalysis =
        SelectedServiceAnalysisStatus.WaitingExigencyFulfillment;
    } else if (organ.stage === 3) {
      organ.statusPreparation =
        SelectedServiceAnalysisStatus.WaitingExigencyFulfillment;
    } else if (organ.stage === 4) {
      organ.statusDevolution =
        organHelper.DevolutionStatus.WaitingExigencyFulfillment;
    }
    await organCtrl.Update(organ, (response) => {
      if (refresh !== undefined) {
        refresh();
      }
    });
  }

  async function reportDone(exigency) {
    ExigencyTimelineInsert(
      {
        userId: userData.id,
        exigencyId: exigency.id,
        description: "Exigência Cumprida com sucesso",
      },
      (res) => {},
    );
  }

  async function reportStatus(sts) {
    if (organList.id !== undefined) {
      let organ = organList;
      if (organ.stage === 2) {
        organ.statusAnalysis = sts;
      } else if (organ.stage === 3) {
        organ.statusPreparation = sts;
      } else if (organ.stage === 4) {
        if (sts === SelectedServiceAnalysisStatus.WaitingExigencyFulfillment) {
          organ.statusDevolution =
            organHelper.DevolutionStatus.WaitingExigencyFulfillment;
        } else if (
          sts === SelectedServiceAnalysisStatus.WaitingOperatorConfirmation
        ) {
          organ.statusDevolution =
            organHelper.DevolutionStatus.WaitingOperatorConfirmation;
        } else {
          organ.statusDevolution = organHelper.DevolutionStatus.Exigency;
        }
      }
      if (organ.status == OrganStatus.ToBeReturned) {
        organ.status = OrganStatus.Ongoing;
      }
      await organCtrl.Update(organ, (res) => {
        if (refresh !== undefined) {
          refresh();
        }
      });
    } else {
      await organCtrl.SelectById(organListId, async (res) => {
        setOrganList(res);
        let organ = res;
        if (organ.stage === 2) {
          organ.statusAnalysis = sts;
        } else if (organ.stage === 3) {
          organ.statusPreparation = sts;
        } else if (organ.stage === 4) {
          if (
            sts === SelectedServiceAnalysisStatus.WaitingExigencyFulfillment
          ) {
            organ.statusDevolution =
              organHelper.DevolutionStatus.WaitingExigencyFulfillment;
          } else if (
            sts === SelectedServiceAnalysisStatus.WaitingOperatorConfirmation
          ) {
            organ.statusDevolution =
              organHelper.DevolutionStatus.WaitingOperatorConfirmation;
          } else {
            organ.statusDevolution = organHelper.DevolutionStatus.Exigency;
          }
        }
        await organCtrl.Update(organ, (response) => {
          if (refresh !== undefined) {
            refresh();
          }
        });
      });
    }
  }

  function clearFields() {
    setTimeout(() => {
      setContent("");
      setContent(null);
      setFile(null);
    }, 550);
  }

  async function saveUpdate() {
    if (action === 3 || action === "3") {
      if (file === null || file === undefined) {
        toast.error(
          `Você precisa adicionar o arquivo da taxa para poder salvar`,
        );
        return;
      }
    }

    let exigency = {
      processId: parseInt(processId),
      userId: userData.id,
      description: content,
      action: parseInt(action),
      status: ExigencyStatus.waiting,
      organListId,
    };

    if (file !== null && file !== undefined) {
      var formData = new FormData();
      formData.append("file", file);
      setLoading(true);
      await docsCtrl.UploadDocs(
        exigency.processId,
        formData,
        userData.id,
        organListId,
        (res) => {
          exigency.sendedId = res[0].id;
          setLoading(false);
          register(exigency);
        },
      );
    } else {
      register(exigency);
    }
  }

  async function register(exigency) {
    await InsertExigency(exigency, async (res) => {
      toast.info("Salvo com sucesso");
      clearFields();
      loadExigencies();
    });
  }

  function deleteExigency(item) {
    if (item.userId === userData.id || userData.role > 0) {
      if (
        window.confirm("Você tem certeza de que deseja excluir essa Exigência?")
      ) {
        ExigencyDelete(item.id, (res) => {
          toast.info("Excluído...", false);
          loadExigencies();
        });
      }
    } else {
      toast.info(
        "Essa exigência não pode ser removida, peça ao supervisor para excluí-la",
      );
    }
  }

  function notifyStatus(sts) {
    SendInsert(
      {
        userId: userData.id,
        processId: processId,
        organListId: organListId,
      },
      (res) => {},
    );
    reportStatus(sts);
  }

  async function sendExigencies() {
    if (
      window.confirm("Enviar as exigências para o cumprimento pelo cliente?")
    ) {
      await processCtrl.Select(processId, (res) => {
        if (res.responsible === undefined || res.responsible === null) {
          toast.error(
            "Esse processo esta sem um responsável, adicione um usuário na aba 'Processo'",
          );
        } else {
          SendExigencies(
            res,
            organListId,
            res.responsible.firstName,
            res.responsible.email,
          );
          exigencySendNotice(TimelineChannelEmail);
          notifyStatus(
            SelectedServiceAnalysisStatus.WaitingExigencyFulfillment,
          );
          if (refresh !== undefined) {
            refresh();
          }
        }
      });
    }
  }

  async function sendCustomEmail(email) {
    if (window.confirm("Enviar as exigências para o email customizado?")) {
      await processCtrl.Select(processId, (res) => {
        SendExigencies(res, organListId, "", email);
        exigencySendNotice(TimelineChannelEmail);
        notifyStatus(SelectedServiceAnalysisStatus.WaitingExigencyFulfillment);
        if (refresh !== undefined) {
          refresh();
        }
      });
    }
  }

  function sendByWhatsExigencies() {
    if (
      window.confirm("Enviar as exigências para o cumprimento pelo cliente?")
    ) {
      notifyStatus(SelectedServiceAnalysisStatus.WaitingExigencyFulfillment);
      let name =
          responsable.firstName !== undefined ? responsable.firstName : "",
        phone = responsable.phone;

      if (phone === undefined) {
        toast.error(
          "O Responsável pelo processo não possui um telefone cadastrado",
        );
        return;
      }

      // if (phone.length === 9) {
      //   phone = "5511" + phone;
      // } else if (phone.length === 11) {
      //   phone = "55" + phone;
      // } else {
      //   phone = phone;
      // }

      ComunicationController.ExigenciesWhats(organListId, (res) => {
        exigencySendNotice(TimelineChannelWhatsapp);
        if (refresh !== undefined) {
          refresh();
        }
      });

      // var url = encodeURIComponent(
      //   `${GetBaseUrl()}#/exigencies/${organListId}`
      // );
      // let message = `Olá ${name}! Segue o link para o cumprimento das exigências : \n ${url}`;

      // window.open(
      //   "https://api.whatsapp.com/send?phone=" + phone + "&text=" + message,
      //   "_blank"
      // );
    }
  }

  async function exigencySendNotice(channel) {
    let statusTab = true;
    if (userData && userData.level == 4) {
      statusTab = false;
    }
    await NotifyUpdate(
      processId,
      "Exigências enviadas ao cliente",
      statusTab,
      channel,
      organListId,
    );

    await changeExigencies();
  }

  async function changeExigencies() {
    if (organListId !== undefined && organListId !== 0) {
      await ExigencyChangeOrgan(organListId, (res) => {});
    }
    return null;
  }

  function handleExigencieChange(value) {
    setContent(value);
    let people_to_show = [];

    if (value.length > 0) {
      setResults([]);
      people_to_show = Autocomplete(value, exigencies, "name");
      setResults(people_to_show);
    } else {
      people_to_show = [];
      setResults([]);
    }
  }

  function handleFilterChange(value) {
    setQuestion(value);
    let exigencies_to_show = [];

    if (value.length > 0) {
      setFilter([]);
      exigencies_to_show = Autocomplete(value, data, "description");
      setFilter(exigencies_to_show);
    } else {
      exigencies_to_show = [];
      setFilter([]);
    }
  }

  async function sendToReview() {
    if (organList.id == undefined) {
      await organCtrl.SelectById(organListId, async (res) => {
        sendReviewStatus(res);
      });
    } else {
      sendReviewStatus(organList);
    }
  }

  async function sendReviewStatus(res) {
    if (window.confirm("Realmente deseja encerrar a preparação?")) {
      // if (res && res.statusPreparation === organHelper.AnalysisStatus.WaitingExigencyFulfillment) {
      setOrganList(res);
      if (res.consulting === true) {
        toast.error(Message.Preparation.Consulting);
        return;
      } else if (res.internalFeedback == true) {
        toast.error(Message.Preparation.InternalFeedback);
        return;
      } else if (res.customerReturn == true) {
        toast.error(Message.Preparation.CustomerReturn);
        return;
      } else {
        await jobsDone.Insert(
          organList.processId,
          organList.id,
          async (res) => {
            await organCtrl.SendPreparationReview(organList.id, (res) => {
              toast.success(Message.Saved);
              window.location = "#/app";
            });
          },
        );
      }
      // } else {
      // alert("As exigências devem ser enviadas ao cliente para continuar");
      // }
    }
  }

  function sendPreference() {
    if (process.contact == ProcessHelper().ContactPreference.WhatsApp) {
      sendByWhatsExigencies();
    } else if (process.contact == ProcessHelper().ContactPreference.Email) {
      sendExigencies();
    } else if (process.contact == ProcessHelper().ContactPreference.Both) {
      sendExigencies();
      sendByWhatsExigencies();
    }
  }

  return (
    <div className="row">
      <div className="col-md-12">
        <div className="block-header">
          <h2 className="content-heading">
            <i className="fa fa-angle-right text-muted mr-1"></i> Exigências
          </h2>
          <div className="block-tools d-flex">
            {organList &&
            organList.stage === 3 &&
            organList.preparationStage == PreparationStep.Track ? (
              <button
                type="button"
                className="btn btn-sm btn-outline-info"
                onClick={() => sendToReview()}
              >
                <i className="far fa-check-square" /> Preparação Concluída
              </button>
            ) : (
              ""
            )}
            <button
              className="btn btn-info btn-sm"
              onClick={() => sendPreference()}
            >
              <i className="si si-paper-plane" /> Enviar
            </button>
            <Dropdown>
              <Dropdown.Toggle variant="light">
                <i className="si si-envelope-letter" /> Enviar por
              </Dropdown.Toggle>
              <Dropdown.Menu>
                <div className="text-center">
                  <button
                    type="button"
                    // disabled={disabledWhats}
                    className="btn btn-sm btn-dual btn-block"
                    onClick={() => sendByWhatsExigencies()}
                  >
                    <i className="fab fa-whatsapp" />
                    <span className="ml-1 d-none d-sm-inline-block"></span>{" "}
                    WhatsApp
                  </button>
                  <button
                    type="button"
                    className="btn btn-sm btn-dual btn-block"
                    onClick={() => sendExigencies()}
                  >
                    <i className="fa fa-fw fa-envelope" />
                    <span className="ml-1 d-none d-sm-inline-block"></span>{" "}
                    Email
                  </button>
                  <CopyToClipboard
                    text={`${GetBaseUrl()}#/exigencies/${organListId}`}
                    onCopy={() =>
                      toast.info("Copiado para a área de transferência")
                    }
                  >
                    <button
                      type="button"
                      className="btn btn-sm btn-dual btn-block"
                      title="copiar"
                    >
                      <i className="far fa-copy" /> Copiar link
                    </button>
                  </CopyToClipboard>
                  <CustomContact send={(email) => sendCustomEmail(email)} />
                </div>
              </Dropdown.Menu>
            </Dropdown>
          </div>
        </div>
        <div className="text-center">
          <DropdownElement
            submit={() => saveUpdate()}
            disabled={disable}
            icon=""
            button={"Salvar"}
            style={{ width: "100%", height: 330 }}
            title={"Adicionar exigência"}
          >
            <div className="form-group">
              <span>Exigência</span>
              <div className="input-group">
                <textarea
                  type="text"
                  className="form-control"
                  id="example-group1-input2"
                  name="example-group1-input2"
                  value={content}
                  onChange={(evt) =>
                    setContent(evt.target.value) |
                    handleExigencieChange(evt.target.value)
                  }
                />
                <div className="input-group-append">
                  <button
                    className="btn btn-link text-danger"
                    onClick={() => setContent("")}
                  >
                    <i className="si si-close"></i>
                  </button>
                </div>
              </div>
            </div>
            <ul
              style={{
                borderRadius: "3px",
                boxShadow: "0 2px 12px rgba(0, 0, 0, 0.1)",
                background: "rgba(255, 255, 255, 0.9)",
                padding: "2px 0",
                fontSize: "90%",
                position: "fixed",
                overflow: "auto",
                marginTop: 20,
                width: "90%",
                maxHeight: "300px", // TODO: don't cheat, let it flow to the bottom
              }}
            >
              {results &&
                results.map((item) => {
                  return (
                    <li
                      className="m-3"
                      onClick={() =>
                        setContent(item.description) | setResults([])
                      }
                    >
                      {item.name}
                    </li>
                  );
                })}
            </ul>
            <div className="form-group">
              <span>Ação esperada?</span>
              <select
                type="text"
                className="form-control"
                onChange={(evt) => setAction(evt.target.value)}
              >
                {ExigencyType.map((item) => (
                  <option value={item.value}>{item.name}</option>
                ))}
              </select>
            </div>
            <div className="form-group">
              <span>Anexar um arquivo (Opcional)</span>
              <br />
              <DocInput
                multiple={false}
                disable={disable}
                loading={loading}
                uploadFile={(itens) => setFile(itens[0])}
              />
            </div>
            <div>
              {relatedExigence !== null ? showRelated(relatedExigence) : ""}
            </div>
          </DropdownElement>
        </div>

        <div className="form-group py-3">
          <div className="input-group">
            <input
              type="text"
              placeholder="Filtrar"
              className="form-control"
              id="search-filter"
              value={question}
              onChange={(evt) => handleFilterChange(evt.target.value)}
            />
            <div className="input-group-append">
              <span
                className="input-group-text"
                onClick={() => setQuestion("") | setFilter([])}
                title="Limpar campo"
              >
                <i className="si si-close"></i>
              </span>
            </div>
          </div>
        </div>
      </div>
      {change && filter.length > 0 ? (
        <div className="col-md-12 py-2">
          {change &&
            filter &&
            filter.map((item) => (
              <ExigencyItem
                refresh={() => loadExigencies()}
                disable={disable}
                data={item}
                showItem={(info) => showEditItem(info)}
                deleteExigency={(info) => deleteExigency(info)}
                response={(info, id) => response(info, id, item)}
              />
            ))}
        </div>
      ) : (
        <div className="col-md-12 py-2">
          {change &&
            data &&
            data.map((item) => (
              <ExigencyItem
                refresh={() => loadExigencies()}
                disable={disable}
                data={item}
                showItem={(info) => showEditItem(info)}
                deleteExigency={(info) => deleteExigency(info)}
                response={(info, id) => response(info, id, item)}
              />
            ))}
        </div>
      )}

      <ExigencyFulfillmentComponent
        data={exigencyData}
        show={showModal}
        disable={disable}
        organListId={organListId}
        reportNotDone={() => reportNotDone()}
        onHide={() => setShowModal(!showModal) | loadExigencies()}
        submit={(service) => loadExigencies() | setShowModal(!showModal)}
      />
    </div>
  );
}
