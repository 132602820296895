import React from "react";
import { HashRouter as Router } from "react-router-dom";
import { renderRoutes } from "react-router-config";
import { DashboardRouter } from "@/app/modules/dashboard";
import AuthenticationRouter from "@/app/modules/auth";
import RegisterReouter from "@/app/modules/register";
import RecoverRouter from "@/app/modules/recoverPassword";
import ResetRouter from "@/app/modules/resetPassword";
import Error404Page from "@/app/core/layouts/errors/Error404Page";
import Storage from "@/app/core/util/Storage";
import CustomerRouter from "@/app/modules/customer";
import CustomerProfilePage from "@/app/modules/customerProfile/main";
import SettingsRouter from "@/app/modules/settings";
import ConfirmPage from "@/app/modules/confirm-service";
import DeferredProcess from "@/app/modules/deferred";
import Milestone from "@/app/modules/milestone";
import ProspectPage from "@/app/modules/prospect";
import ExigenciesPage from "@/app/modules/exigencies";
import ClosurePage from "@/app/modules/closure";
import BudgetRouter from "./modules/budget";
import ProcessRouter from "./modules/process";
import AnalysisRouter from "./modules/analysis";
import PreparationRouter from "./modules/preparation";
import ProfileRouter from "./modules/profile";
import AvailableCitiesRouter from "./modules/available-cities";
import SupervisorRouter from "./modules/supervisor";
import CompanyRouter from "./modules/company";
import TermsRouter from "./modules/terms";
import ResendRouter from "./modules/resend";
import VerificationRouter from "./modules/verification";
import Feedback from "./modules/feedback";
import CashFlowRouter from "./modules/cashFlow";
import ConsultRouter from "./modules/consult";
import SurveyRouter from "./modules/survey";
import NarniaRouter from "./modules/narnia";
import ServicesRouter from "./modules/services";
import ResendDevolutionRouter from "./modules/resendDevolution";
import ReportRouter from "./modules/report";
import DoneRouter from "./modules/done";
import CompaniesRouter from "./modules/companies";
import ReminderRouter from "./modules/reminder";
import ChargeComponent from "./modules/charge/main";
import DrafterComponent from "./modules/drafter";
import ContractRouter from "./modules/contract";
import SharedRouter from "./modules/shared";
import RefusedsRouter from "./modules/refuseds";
import OportunitiesRouter from "./modules/oportunities";
import NpsRouter from "./modules/nps";
import ExigencyPanelRouter from "./modules/exigency-panel";
import ToDoRouter from "./modules/todo";
import ElaboratorRouter from "./modules/elaborator";
import BillingRouter from "./modules/billing";
import PaymentPage from "./modules/bill";

export default function App() {
  if (!localStorage.getItem("theme")) {
    localStorage.setItem("theme", "light");
  }

  document.body.classList.add("bg-" + Storage.getTheme());

  const routes = [
    AuthenticationRouter,
    AnalysisRouter,
    PreparationRouter,
    DashboardRouter,
    CustomerRouter,
    ResetRouter,
    RecoverRouter,
    SupervisorRouter,
    RegisterReouter,
    ProfileRouter,
    SettingsRouter,
    CompanyRouter,
    BudgetRouter,
    TermsRouter,
    ProcessRouter,
    VerificationRouter,
    ResendRouter,
    ConsultRouter,
    AvailableCitiesRouter,
    SurveyRouter,
    BillingRouter,
    CashFlowRouter,
    ServicesRouter,
    CompaniesRouter,
    ReminderRouter,
    ResendDevolutionRouter,
    NpsRouter,
    ContractRouter,
    ReportRouter,
    ToDoRouter,
    ElaboratorRouter,
    OportunitiesRouter,
    RefusedsRouter,
    DoneRouter,
    SharedRouter,
    ExigencyPanelRouter,
    NarniaRouter,
    {
      path: "/confirm/:key",
      component: ConfirmPage,
      onShell: false,
    },
    {
      path: "/charge/:key",
      component: ChargeComponent,
      onShell: false,
    },
    {
      path: "/feedback/:key",
      component: Feedback,
      onShell: false,
    },
    {
      path: "/bill/:key",
      component: PaymentPage,
      onShell: false,
    },
    {
      path: "/deferred/:key",
      component: DeferredProcess,
      onShell: false,
    },
    {
      path: "/milestone/:key",
      component: Milestone,
      onShell: false,
    },
    {
      path: "/prospect/:key",
      component: ProspectPage,
      onShell: false,
    },
    {
      path: "/exigencies/:key",
      component: ExigenciesPage,
      onShell: false,
    },
    {
      path: "/closure/:key",
      component: ClosurePage,
      onShell: false,
    },
    {
      path: "/drafter/:key",
      component: DrafterComponent,
      onShell: false,
    },
    {
      path: "/customer-profile/:key",
      component: CustomerProfilePage,
      onShell: false,
    },
    {
      path: "/analysis/:key",
      component: AnalysisRouter,
      onShell: false,
    },
    {
      path: "/preparation/:key",
      component: PreparationRouter,
      onShell: false,
    },
    {
      path: "*",
      component: Error404Page,
      onShell: false,
    },
  ];

  return <Router>{renderRoutes(routes)}</Router>;
}
