import ToDoController from "@/app/core/base/controllers/ToDoController";
import { DateTranslate } from "@/app/core/util/DateTranslater";
import { OnDataChange } from "@/app/core/util/services/DataService";
import React, { useState } from "react";

export default function ToDoItem({handleTaskChange, deleteTask, data}) {
    const [item, setItem] = useState(data);
    const [edit, setEdit] = useState(false);

    function handleChange(evt, type){
        setItem(OnDataChange(item, evt,type))
    }

    function saveChange(){
        ToDoController().Update(item, res => {
            setEdit(false);
        })
    }

    return (
        <div className="js-task block block-rounded block-fx-pop block-fx-pop mb-2 animated fadeIn" data-task-id="9" data-task-completed="false" data-task-starred="false">
            <table className="table table-borderless table-vcenter mb-0">
                <tbody>
                    <tr>
                        <td className="text-center pr-0" style={{ width: 38 }}>
                            <div className="js-task-status custom-control custom-checkbox custom-checkbox-rounded-circle custom-control-primary custom-control-lg">
                                <input type="checkbox" className="custom-control-input" id={`task-${item.id}`} name={`task-${item.id}`} onChange={(evt) => handleTaskChange(evt, item)} checked={item.done} />
                                <label className="custom-control-label" for={`task-${item.id}`}></label>
                            </div>
                        </td>
                        <td className="js-task-content font-w600 pl-0" style={{ wordBreak: "break-all" }}>
                            {edit === false ? 
                            item.description : <div className="form-group"><span>Descrição da tarefa</span><textarea className="form-control" value={item.description}
                                onChange={evt => handleChange(evt)} name="description"/></div>}
                        </td>
                        <td className="text-right" style={{ width: 300 }}>
                            <small className="text-sm">
                                Criado em: {DateTranslate(item.createdDate)}
                            </small>
                            {edit === false ? 
                                <button type="button" className="js-task-remove btn btn-sm btn-link text-info" onClick={() => setEdit(true)}>
                                    <i className="far fa-edit fa-fw"></i>
                                </button>
                                :
                                <button type="button" className="js-task-remove btn btn-sm btn-link text-success" onClick={() => saveChange()}>
                                    <i className="si si-check"></i>
                                </button>
                            }
                            <button type="button" className="js-task-remove btn btn-sm btn-link text-danger" onClick={() => deleteTask(item)}>
                                <i className="fa fa-times fa-fw"></i>
                            </button>
                        </td>
                    </tr>
                </tbody>
            </table>
        </div>
    )
}