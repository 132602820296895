import React, { useEffect, useState } from "react";
import { ToastContainer, toast } from "react-toastify";
import Storage from "@/app/core/util/Storage";
import CardItem from "@/app/core/layouts/main-components/CounterCardItem";
import FinancialEntries from "@/app/core/layouts/main-components/financial/FinancialEntriesCard";
import FinancialHelper from "@/app/core/util/helpers/FinancialHelper";
import CashFlowController from "@/app/core/base/controllers/CashFlowController";
import { MoneyMask } from "@/app/core/util/helpers/MoneyHelper";

export default function MainCustomer(props) {
  const [loading] = useState(false);
  const [data, setData] = useState({});
  const userData = Storage.getUserData();

  const financialHelper = FinancialHelper();
  const cashFlow = CashFlowController();

  useEffect(() => {
    getCustomerData();
  }, []);

  async function getCustomerData() {
    await cashFlow.CustomerEntries(1, (res) => {
      let income = 0,
        outcome = 0,
        discount = 0,
        reimbursement = 0;

      for (let s in res) {
        const item = res[s];
        if (item.type === financialHelper.OperationType.Income) {
          income += item.value;
        }
        if (item.type === financialHelper.OperationType.Outcome) {
          outcome += item.value;
        }
        if (item.type === financialHelper.OperationType.Discount) {
          discount += item.value;
        }
        if (item.type === financialHelper.OperationType.Reimbursement) {
          reimbursement += item.value;
        }
      }
      setData({
        income,
        outcome,
        discount,
        reimbursement,
      });
    });
  }

  return (
    <>
      <div className={Storage.getTheme() + " content"}>
        <div className="title">
          <h2 className="content-heading">
            <i className="fa fa-angle-right text-muted mr-1"></i> Fluxo de caixa
          </h2>
        </div>
        <div className="row">
          <div class="col-md-3">
            <CardItem
              name={"Receitas"}
              value={data && `${MoneyMask(data.income, 2)}`}
              text={"text-white-75"}
              icon={"far fa-2x fa-chart-bar "}
              aparence={"block block-rounded block-link-shadow bg-success"}
            />
          </div>
          <div class="col-md-3">
            <CardItem
              name={"Despesas"}
              value={data && `${MoneyMask(data.outcome, 2)}`}
              text={"text-white-75"}
              icon={"fa fa-2x fa-outdent "}
              aparence={"block block-rounded block-link-shadow bg-danger"}
            />
          </div>
          <div class="col-md-3">
            <CardItem
              name={"Descontos"}
              value={data && `${MoneyMask(data.discount, 2)}`}
              text={"text-white-75"}
              icon={"fa fa-2x fa-percentage "}
              aparence={"block block-rounded block-link-shadow bg-warning"}
            />
          </div>
          <div class="col-md-3">
            <CardItem
              name={"Re-embolsos"}
              value={data && `${MoneyMask(data.reimbursement, 2)}`}
              text={"text-white-75"}
              icon={"si fa-2x si-refresh "}
              aparence={"block block-rounded block-link-shadow bg-secondary"}
            />
          </div>
          <div class="col-md-12">
            <FinancialEntries customerId={1} />
          </div>
        </div>
      </div>
    </>
  );
}
