import React, { useEffect, useState } from "react";
import Storage from "@/app/core/util/Storage";
import TimelineItem from "@/app/core/layouts/main-components/timeline/TimelineItem";
import { Autocomplete } from "../../util/helpers/AutocompleteHelper";
import { toast } from "react-toastify";
import TimelineController from "../../base/controllers/TimelineController";
import DropdownElement from "./elements/DropdownElement";
import { NoteInsert } from "../../base/controllers/ProcessNotesController";
import FilterOrgans from "./FilterOrgans";
import OrganListController from "../../base/controllers/OrganListController";
import ProcessController from "../../base/controllers/ProcessController";
import DocInput from "./documents/DocInput";
import { OnDataChange } from "../../util/services/DataService";
import { FieldTypeObj } from "../../util/helpers/FieldTypeHelper";
import AttachmentController from "../../base/controllers/AttachmentController";
import { Message } from "../../util/messages/GeralMessages";
import { DateTimeNow } from "../../util/DateTranslater";

export default function TimelineComponent({
  processId,
  organListId,
  search,
  view,
  preLoad,
  refresh,
}) {
  const userData = Storage.getUserData();
  const [data, setData] = useState([]);
  const [newData, setNewData] = useState({ prints: [] });
  const [filter, setFilter] = useState([]);
  const [question, setQuestion] = useState("");
  const [rawData, setRawData] = useState([]);
  const [loading, setLoading] = useState(false);

  const timelineCtrl = TimelineController();
  const organCtrl = OrganListController();
  const processCtrl = ProcessController();
  const attachmentCtrl = AttachmentController();

  useEffect(() => {
    loadTimeline();
  }, [processId, organListId]);

  async function reload() {
    await timelineCtrl.Range(processId, 0, data.length, (res) => {
      setData(res);
      setRawData(res);
    });
  }

  document.onpaste = function (event) {
    var items = (event.clipboardData || event.originalEvent.clipboardData)
      .items;
    for (let index in items) {
      var item = items[index];
      if (item.kind === "file") {
        var blob = item.getAsFile();
        var reader = new FileReader();
        reader.onload = function (event) {
          setNewData({
            ...newData,
            prints: [
              ...newData.prints,
              {
                url: event.target.result,
                type: 2,
                name: `printscreen-${DateTimeNow()}`,
              },
            ],
          });
        }; // data url!
        reader.readAsDataURL(blob);
      }
    }
  };

  async function loadTimeline() {
    if (processId === 0 || processId === undefined || processId === null) {
      return;
    }
    setLoading(true);
    await timelineCtrl.Range(
      processId,
      data.length,
      data.length + 50,
      (res) => {
        setLoading(false);
        if (res.length == 0) {
          toast.info("Não há mais dados a serem carregados");
        } else {
          for (let s in res) {
            res[s].time = new Date(res[s].createdDate).getTime();
          }

          res.sort(function (a, b) {
            return b.time - a.time;
          });

          setData([...data, ...res]);
          setRawData([...rawData, ...res]);
        }
      },
    );
  }

  async function loadFromStart() {
    if (processId === 0 || processId === undefined || processId === null) {
      return;
    }
    setLoading(true);
    await timelineCtrl.Range(processId, 0, 100, (res) => {
      setLoading(false);
      if (res.length == 0) {
        toast.info("Não há mais dados a serem carregados");
      } else {
        for (let s in res) {
          res[s].time = new Date(res[s].createdDate).getTime();
        }

        res.sort(function (a, b) {
          return b.time - a.time;
        });

        setData(res);
        setRawData(res);
      }
    });
  }

  async function remove(item) {
    if (window.confirm(Message.DeleteConfirm)) {
      attachmentCtrl.Delete(item.id, (res) => {
        toast.success(Message.DeleteSuccess);
        reload();
      });
    }
  }

  function clearFields() {
    setNewData({
      observation: "",
      files: [],
      prints: [],
    });
  }

  async function saveUpdate() {
    let timeline = {
      processId: processId,
      organListId,
      level: userData.level,
      userId: userData.id,
      status: 1,
      ...newData,
    };

    await timelineCtrl.Insert(timeline, async (res) => {
      toast.info("Salvo com sucesso");
      if (
        (timeline.files !== undefined && timeline.files.length > 0) ||
        (newData.prints != undefined && newData.prints.length > 0)
      ) {
        var formData = new FormData();
        for (let s in timeline.files) {
          formData.append("file", timeline.files[s]);
        }
        for (let s in newData.prints) {
          const file = DataURIToBlob(newData.prints[s].url);
          formData.append("upload", file, `${newData.prints[s].name}.jpg`);
          formData.append("path", "temp/"); //other param
        }
        setData([]);
        await attachmentCtrl.Upload(formData, res.id, processId, (res) => {
          loadFromStart();
          if (refresh != undefined) {
            refresh();
          } else {
            reload();
          }
        });
      } else {
        loadFromStart();
        clearFields();
        if (refresh != undefined) {
          refresh();
        } else {
          reload();
        }
      }
    });

  }
  
  function DataURIToBlob(dataURI) {
    const splitDataURI = dataURI.split(",");
    const byteString =
      splitDataURI[0].indexOf("base64") >= 0
        ? atob(splitDataURI[1])
        : decodeURI(splitDataURI[1]);
    const mimeString = splitDataURI[0].split(":")[1].split(";")[0];

    const ia = new Uint8Array(byteString.length);
    for (let i = 0; i < byteString.length; i++)
      ia[i] = byteString.charCodeAt(i);

    return new Blob([ia], { type: mimeString });
  }

  function addObservation(item) {
    let note = {
      observation: item.observation,
      processId: processId,
      userId: item.userId !== undefined ? item.userId : null,
    };
    NoteInsert(note, (res) => {});
  }

  function handleChange(evt, type) {
    setNewData(OnDataChange(newData, evt, type));
  }

  function handleFilterChange(value) {
    setQuestion(value);
    let timeline_to_show = [];

    if (value.length > 0) {
      setFilter([]);
      timeline_to_show = Autocomplete(value, data, "observation");
      setFilter(timeline_to_show);
    } else {
      loadFromStart();
    }
  }

  function search(e) {
    e.preventDefault();
    if (question.length > 0) {
      setLoading(true);
      timelineCtrl.Search(processId, question, (res) => {
        setLoading(false);
        setData(res);
      });
    }
  }

  return (
    <div className="row">
      <div className="col-6">
        <h2 className="content-heading">
          <i className="fa fa-angle-right text-muted mr-1"></i> Timeline
        </h2>
      </div>
      <div className="col-6 d-flex pt-4">
        <DropdownElement
          submit={() => saveUpdate()}
          icon=""
          className="btn btn-dual mr-2"
          style={{ width: "100%", height: 350 }}
          title={"Adicionar informacao"}
        >
          <div className="form-group">
            <span>Qual o canal de contato?</span>
            <select
              name="channel"
              className="form-control"
              onChange={(evt) => handleChange(evt, FieldTypeObj.Number)}
            >
              <option value={1}>Email</option>
              <option value={2}>Whatsapp</option>
              <option value={3}>Chat</option>
              <option value={4}>Telefone</option>
              <option value={5}>Recepção</option>
            </select>
          </div>
          <div className="form-group">
            <span>O que foi tratado?</span>
            <textarea
              type="text"
              className="form-control"
              name="observation"
              value={newData && newData.observation}
              onChange={(evt) => handleChange(evt)}
            />
          </div>
          <div className="form-group mb-0">
            {/* <span>Anexos</span> */}
            {newData &&
              newData.prints &&
              newData.prints.map((item) => (
                <button className="btn btn-sm btn-dual" title={item.name}>
                  <i className="fa fa-paperclip text-success mr-1"></i>{" "}
                  {item.name}
                </button>
              ))}
            <br />
            <DocInput
              // className="form-control"
              disable={false}
              multiple={true}
              uploadFile={(files) => setNewData({ ...newData, files })}
            />
          </div>
        </DropdownElement>
        <FilterOrgans
          filter={filter}
          data={data}
          processId={processId}
          organListId={organListId}
          responseFilter={(itens) => setFilter(itens)}
          responseData={(itens) => setData(itens)}
          rawData={rawData}
        />
      </div>
      <div className="col-md-12">
        <form className="form-group py-3" onSubmit={(e) => search(e)}>
          <div className="input-group">
            <input
              type="text"
              placeholder="Filtrar"
              className="form-control"
              id="search-filter"
              value={question}
              onChange={(evt) => handleFilterChange(evt.target.value)}
            />
            <div className="input-group-append">
              <span
                className="input-group-text"
                onClick={() => setQuestion("") | loadFromStart()}
                title="Limpar campo"
              >
                <i className="si si-close"></i>
              </span>
            </div>
          </div>
        </form>
      </div>
      {/* {filter.length > 0 ? (
        <div className="col-md-12 py-3">
          {filter &&
            filter.map((item) => (
              <TimelineItem
                refresh={() => reload()}
                data={item}
                view={(i) => view(i)}
                remove={(i) => remove(i)}
                organListId={organListId}
                addObservation={(info) => addObservation(info)}
              />
            ))}
        </div>
      ) : (
      )} */}
      <div className="col-md-12 py-3">
        {loading === true ? (
          <div className="block-content text-center">
            <i
              className={"fa fa-circle-notch fa-spin text-info fa-2x py-3"}
            ></i>
          </div>
        ) : (
          ""
        )}
        {data &&
          data.map((item) => (
            <TimelineItem
              refresh={() => reload()}
              data={item}
              view={(i) => view(i)}
              remove={(i) => remove(i)}
              organListId={organListId}
              addObservation={(info) => addObservation(info)}
            />
          ))}
      </div>

      {data && data.length == 0 ? (
        <div className="col-12 text-center">
          <h4 className="h3 font-w400 text-muted mb-5 js-appear-enabled animated fadeInUp">
            Nenhum resultado foi encontrado
          </h4>
          <button
            className="btn btn-info btn-square mt-5"
            onClick={() => loadTimeline()}
          >
            <i className="si si-cloud-download mr-1" />
            Recarregar
          </button>
        </div>
      ) : (
        <div className="col-12 text-center">
          <button
            className="btn btn-info btn-square"
            onClick={() => loadTimeline()}
          >
            <i className="si si-cloud-download mr-1" />
            Carregar mais
          </button>
        </div>
      )}
    </div>
  );
}
