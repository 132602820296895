import React, { useEffect, useState } from "react";
import Storage from "@/app/core/util/Storage";
import OperationProcessModal from "@/app/core/layouts/main-components/OperationProcessModal";
import Table from "@/app/core/components/table/";
import Functions from "@/app/core/util/Functions";
import ProcessController from "@/app/core/base/controllers/ProcessController";
import { GetLastDayOfMonth, ProcessDates } from "@/app/core/util/DateTranslater";

export default function RemovedProcessCard({ customerId }) {
  const userData = Storage.getUserData();
  const [query, setQuery] = useState("");
  const [finished, setFinished] = useState([]);
  const [showOperationModal, setShowOperationModal] = useState(false);
  const [operationData, setOperationData] = useState({});
  const date = new Date();
  const [startDate, setStartDate] = useState(
    getFirstDayOfMonth(date.getFullYear(), 0).toISOString().substring(0, 10),
  );
  const [endDate, setEndDate] = useState(
    GetLastDayOfMonth(date.getFullYear(), 11).toISOString().substring(0, 10),
  );

  const processCtrl = ProcessController();

  function getFirstDayOfMonth(year, month) {
    return new Date(year, month, 1);
  }

  const colsProcess = [
    { header: "#ID", isSortable: true, name: "id" },
    { header: "Processo", isSortable: true, name: "processName" },
    { header: "Criado em", isSortable: true, name: "createdDate" },
    { header: "data de exclusão", isSortable: true, name: "updatedDate" },
  ];

  useEffect(() => {
    loadRemoveds();
  }, [startDate, endDate]);

  async function loadRemoveds() {
    await processCtrl.CustomerDeleted(customerId, startDate, `${endDate}T23:59:59`, (res) =>
      setFinished(ProcessDates(res)),
    );
  }

  function handleChange(value) {
    setQuery(value);
    let refusedbgts = [];

    if (value.length > 0) {
      refusedbgts = autocompleteProcess(value, finished);
      setFinished(refusedbgts);
    } else {
      setFinished(finished);
    }
  }

  function autocompleteProcess(value, data) {
    const inputValue = value.trim().toLowerCase();
    let itens = [];
    for (let s in data) {
      itens.push(data[s]);
    }
    let newResults = [];

    for (let s in itens) {
      const item = itens[s];
      const name = item.processName.toLowerCase();
      if (name.includes(inputValue)) {
        newResults.push(item);
      }
    }

    return newResults;
  }

  return (
    <div className="block block-rounded">
      <div className="block-content">
        <div className="row">
          <div className="col-md-6">
            <div className="form-group">
              <span>Filtrar</span>
              <div className="input-group">
                <input
                  type="text"
                  placeholder="Filtrar"
                  className="form-control"
                  id="search-filter"
                  value={query}
                  onChange={(evt) => handleChange(evt.target.value)}
                />
                <div className="input-group-append">
                  <span
                    className="input-group-text"
                    onClick={() => setQuery("") | loadRemoveds()}
                    title="Limpar campo"
                  >
                    <i className="si si-close"></i>
                  </span>
                </div>
              </div>
            </div>
          </div>
          <div className="col-md-6 row">
            <div className="form-group col-6">
              <span>Data de início</span>
              <input
                type="date"
                className="form-control"
                value={startDate}
                onChange={(evt) => setStartDate(evt.target.value)}
              />
            </div>
            <div className="form-group col-6">
              <span>Data fim</span>
              <input
                type="date"
                className="form-control"
                value={endDate}
                onChange={(evt) => setEndDate(evt.target.value)}
              />
            </div>
          </div>
        </div>

        <div className="block block-rounded" style={{ overflowX: "scroll" }}>
          <div className="block-content">
            <Table
              title="Processos removidos"
              data={finished}
              columns={colsProcess}
              setFilter={(filter) => Functions.ProcessFilter(filter, finished)}
              refreshData={() => loadRemoveds()}
              viewAction={(data) =>
                setOperationData(data.data) | setShowOperationModal(true)
              }
            />
          </div>
        </div>
      </div>
      <OperationProcessModal
        data={operationData}
        show={showOperationModal}
        submit={(updateBudget) => null}
        onHide={() =>
          setOperationData(null) | loadRemoveds() | setShowOperationModal(false)
        }
      />
    </div>
  );
}
