import React from "react";
import { ChannelTranslate } from "@/app/core/util/StatusStates";
import { DepartmentTranslate } from "@/app/core/util/helpers/DepartmentHelper";
import TimelineController from "@/app/core/base/controllers/TimelineController";
import { DateTranslate } from "@/app/core/util/DateTranslater";
import { NegotiationTimelineController } from "@/app/core/base/controllers/NegotiationTimelineController";

export default function FinancialTimelineItem({ refresh, data }, props) {
    const timelineCtrl = TimelineController();

    async function showOff(item) {
        let infos = {
            ...data,
            important: !item.important,
            status: 1,
        };

        await NegotiationTimelineController.Update(infos, (res) => {
            refresh();
        });
    }

    function verifyState(item) {
        if (item.important === true) {
            return "border-success";
        } else {
            if (item.channel === 0) {
                return "border-info";
            }

            return "border-secondary";
        }
    }

    return (
        <a
            className={
                "block block-rounded block-link-shadow block-bordered " +
                verifyState(data) +
                " border-3x js-appear-enabled animated fadeIn"
            }
            data-toggle="appear"
            href="javascript:void(0)"
        >
            <div className="block-header">
                <div className="block-title">
                    {" "}
                    {data.user !== null && data.user !== undefined
                        ? `${data.user.firstName} | ${DepartmentTranslate(
                            data.user.level
                        )} - `
                        : ""}
                    {data.user === null || data.user === undefined ? "Cliente - " : ""}
                    {ChannelTranslate(data.channel)}
                </div>
                <div className="block-tools">
                    <button
                        className={
                            data.important === true
                                ? "btn btn-sm btn-square btn-success"
                                : "btn btn-sm btn-square btn-outline-success"
                        }
                        onClick={() => showOff(data)}
                    >
                        {data.important === true ? "Destacado" : "Destacar"}
                    </button>
                </div>
            </div>
            <div className="block-content block-content-full d-flex align-items-center justify-content-between">
                <div>
                    <p className="text-muted mb-0">{data.observation}</p>
                </div>
            </div>
            <div className="block-content block-content-full block-content-sm bg-body-light">
                <span className="font-size-sm text-muted">
                    <strong>{DateTranslate(data.createdDate)}</strong>
                </span>
            </div>
        </a>
    );
}
