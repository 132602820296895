import React, { useEffect, useState, useRef } from "react";
import { Dropdown } from "react-bootstrap";
import { toast } from "react-toastify";
import DocItemComponent from "./DocItemComponent";
import Storage from "@/app/core/util/Storage";
import DocumentsController from "../../base/controllers/DocumentsController";
import DocInput from "./documents/DocInput";
import OrganListController from "../../base/controllers/OrganListController";
import ProcessController from "../../base/controllers/ProcessController";
import { ExigencyTemplateList } from "../../base/controllers/ExigencyTemplateController";
import { DocHelper } from "../../util/helpers/DocsHelper";
import { OnDataChange } from "../../util/services/DataService";
import { Message } from "../../util/messages/GeralMessages";
import DeferredEmailModal from "./documents/DeferredEmailModal";
import AutomaticConsult from "./documents/consults/AutomaticConsult";

export default function DocsComponent({
  processId,
  organListId,
  setView,
  setRightView,
  disabled,
  hideButtons,
  mandatory,
  search,
  showAll,
  preLoad,
  refresh,
}) {
  const userData = Storage.getUserData();
  const [email, setEmail] = useState({
    from: userData != null ? userData.email : "",
  });
  const [data, setData] = useState([]);
  const [rawData, setRawData] = useState([]);
  const [eraseds, setEraseds] = useState([]);
  const [exigencies, setExigencies] = useState(Storage.getTemplates());
  const [filter, setFilter] = useState([]);
  const [query, setQuery] = useState("");
  const [disable, setDisable] = useState(false);
  const [loading, setLoading] = useState(false);
  const [available, setAvailable] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const docsCtrl = DocumentsController();
  const organCtrl = OrganListController();
  const processCtrl = ProcessController();

  const dragItem = useRef();
  const dragOverItem = useRef();

  const date = new Date();
  const [change, setChange] = useState(date.getTime());

  useEffect(() => {
    if (preLoad != undefined && preLoad.id != undefined) {
      processDocs(preLoad.docs);
    } else if (processId !== undefined) {
      listDocuments();
    }
    if (disabled === undefined || disabled === null) {
      setDisable(false);
    } else {
      setDisable(disabled);
    }
    loadExigencies();
  }, [processId, organListId, preLoad]);

  function notifyChange() {
    setChange(date.getTime());
  }

  function dragStart(e, index) {
    dragItem.current = index;
  }
  function dragEnter(e, index) {
    dragOverItem.current = index;
  }

  const drop = async (e) => {
    if (disabled == true || (userData && userData.level == 5)) {
      return;
    }
    let copyListItems = data;
    const dragItemContent = copyListItems[dragItem.current];
    copyListItems.splice(dragItem.current, 1);
    copyListItems.splice(dragOverItem.current, 0, dragItemContent);
    dragItem.current = null;
    dragOverItem.current = null;

    for (let s in copyListItems) {
      copyListItems[s].position = parseInt(s) + 1;
      docsCtrl.Update(
        copyListItems[s],
        (res) => {},
        (error) => {},
      );
    }

    setData(orderByPosition(copyListItems));
    notifyChange();
  };

  function orderByPosition(itens) {
    const list = itens.sort(function (a, b) {
      return a.position - b.position;
    });
    console.log(itens, list);
    return list;
  }

  async function loadExigencies() {
    if (userData) {
      if (exigencies == null || exigencies.length == 0) {
        await ExigencyTemplateList((res) => {
          for (let s in res) {
            res[s].name = `${res[s].code} - ${res[s].description}`;
          }
          setExigencies(res);
        });
      } else {
        let res = exigencies;
        for (let s in res) {
          res[s].name = `${res[s].code} - ${res[s].description}`;
        }
        setExigencies(res);
      }
    }
  }

  async function listDocuments() {
    if (refresh != undefined) {
      refresh();
    } else {
      await docsCtrl.ProcessList(processId, (res) => {
        processDocs(res);
      });
    }
  }

  function processDocs(res) {
    let results = [];
    let list = [];

    for (let s in res) {
      res[s].checked = false;
      res[s].time = new Date(res[s].created).getTime();
      if (userData === null || userData.level === 5) {
        if (showAll != undefined) {
          list.push(res[s]);
        } else if (mandatory === true) {
          if (
            res[s].visible === true &&
            res[s].organListId === parseInt(organListId)
          ) {
            list.push(res[s]);
          }
        } else {
          if (res[s].visible === true) {
            list.push(res[s]);
          }
        }
      } else {
        list.push(res[s]);
      }
    }

    let master = list.filter((x) => x.status === 1);
    let complement = list.filter((x) => x.status === 2);
    let consults = list.filter((x) => x.status === 3);
    let availables = list.filter((x) => x.visible === true);

    if (availables.length > 0) {
      setAvailable(true);
    } else {
      setAvailable(false);
    }

    for (let s in master) {
      results.push(master[s]);
    }
    for (let s in complement) {
      results.push(complement[s]);
    }
    for (let s in consults) {
      results.push(consults[s]);
    }
    const eraseds = res.filter((x) => x.status === 5);
    setData(orderByPosition(results));
    setRawData(orderByPosition(results));
    setEraseds(orderByPosition(eraseds));
  }

  function viewAction(recv) {
    setView(recv.url);
  }

  function viewRightAction(recv) {
    setRightView(recv.url);
  }

  async function uploadFile(fl) {
    setLoading(true);
    var formData = new FormData();
    for (let s in fl) {
      formData.append("file", fl[s]);
    }
    let id = userData === null ? "" : userData.id;

    await docsCtrl.UploadDocs(processId, formData, id, organListId, (res) => {
      toast.info("Upload concluído");
      setLoading(false);
      listDocuments();
    });

    // if (userData !== null) {
    //   if (userData.level === 6 || (search === true && userData.role === 0)) {
    //     if (organListId !== undefined) {
    //       await organCtrl.CustomerReturn(organListId, true, (res) => {});
    //     } else {
    //       await processCtrl.CustomerReturn(processId, (res) => {});
    //     }
    //   } 
    //   else if (
    //     userData.level !== 6 &&
    //     userData.level !== 5 &&
    //     userData.role > 0
    //   ) {
    //     if (organListId !== undefined) {
    //       await organCtrl.InternalFeedback(organListId, true, (res) => {});
    //     } else {
    //       await processCtrl.InternalFeedback(processId, (res) => {});
    //     }
    //   }
    // } else {
    //   if (organListId !== undefined) {
    //     await organCtrl.CustomerReturn(organListId, true, (res) => {});
    //   } else {
    //     await processCtrl.CustomerReturn(processId, (res) => {});
    //   }
    // }
  }

  function handleExigencieChange(value) {
    setQuery(value);
    let docs_to_show = [];

    if (value.length > 0) {
      setFilter([]);
      docs_to_show = autocomplete(value);
      setFilter(docs_to_show);
    } else {
      docs_to_show = [];
      setFilter([]);
    }
  }

  function autocomplete(value) {
    const inputValue = value.trim().toLowerCase();
    let itens = [];
    for (let s in data) {
      itens.push(data[s]);
    }
    for (let s in eraseds) {
      itens.push(eraseds[s]);
    }

    let newResults = [];

    for (let s in itens) {
      const item = itens[s];
      const name = item.name.toLowerCase();
      if (name.includes(inputValue)) {
        newResults.push(item);
      }
    }

    return newResults;
  }

  async function hideAllDocs() {
    await docsCtrl.HideAll(processId, (res) => {
      toast.success("Salvo com sucesso");
      listDocuments();
    });
  }

  async function hideSelecteds() {
    let selecteds = data.filter((x) => x.checked == true);
    let filtereds = filter.filter((x) => x.checked == true);
    const newData = data;
    let ids = [];
    for (let s in selecteds) {
      selecteds[s].visible = false;
      await docsCtrl.Update(selecteds[s], (res) => {});
    }
    for (let s in filtereds) {
      ids.push(filtereds[s].id);
    }
    for (let s in newData) {
      if (newData[s].checked == true) {
        newData[s].visible = false;
      }
    }

    setData(newData);
    toast.success("Salvo com sucesso");
    // listDocuments();
  }

  async function showSelecteds() {
    let selecteds = data.filter((x) => x.checked == true);
    let filtereds = filter.filter((x) => x.checked == true);
    let ids = [];
    const newData = data;
    for (let s in selecteds) {
      selecteds[s].visible = true;
      await docsCtrl.Update(selecteds[s], (res) => {});
    }
    for (let s in filtereds) {
      ids.push(filtereds[s].id);
    }

    for (let s in newData) {
      if (newData[s].checked == true) {
        newData[s].visible = true;
      }
    }

    setData(newData);

    toast.success("Salvo com sucesso");
    // listDocuments();
  }

  async function deleteAllDocs() {
    let selecteds = data.filter((x) => x.checked == true);
    let filtereds = filter.filter((x) => x.checked == true);
    let ids = [];
    for (let s in selecteds) {
      selecteds[s].status = DocHelper.Status.Trash;
      await docsCtrl.Update(selecteds[s], (res) => {});
    }
    for (let s in filtereds) {
      ids.push(filtereds[s].id);
    }

    toast.success("Salvo com sucesso");
    listDocuments();
  }

  async function updateDocStatus(sts) {
    let selecteds = data.filter((x) => x.checked == true);
    if (selecteds.length == 0) {
      toast.error("Selecione ao menos 1 documento");
      return;
    }
    let filtereds = filter.filter((x) => x.checked == true);
    let ids = [];
    for (let s in selecteds) {
      selecteds[s].status = sts;
      await docsCtrl.Update(selecteds[s], (res) => {});
    }
    for (let s in filtereds) {
      ids.push(filtereds[s].id);
    }

    toast.success("Salvo com sucesso");
    listDocuments();
  }

  function handleChecked(item, index) {
    let old = data;
    old[index].checked = item.checked;
    setData(old);
    notifyChange();
  }

  function removeSelected() {
    let old = data;
    for (let s in old) {
      old[s].checked = false;
    }

    setData(old);
    notifyChange();
  }

  return (
    <div className="row">
      <div className="col-md-12">
        <div className="block-header">
          <div className="title">
            <h2 className="content-heading">
              <i className="fa fa-angle-right text-muted mr-1"></i>{" "}
              {data && data.length} Documentos{" "}
              {/* {userData === null || userData.level === 5 ? "registrados" : ""} */}
            </h2>
          </div>

          <div className="tools">
            {hideButtons !== true ? (
              <div className="d-flex">
                {userData && userData.level !== 5 ? (
                  <Dropdown>
                    <Dropdown.Toggle variant="light">
                      <i class="fa fa-ellipsis-v"></i>
                    </Dropdown.Toggle>
                    <Dropdown.Menu>
                      <button
                        title="Tornar documentos invisíveis ao cliente"
                        className={`btn btn-dual btn-block`}
                        key={`deleteAllDocs`}
                        onClick={() => deleteAllDocs()}
                      >
                        <i className={`fa fa-times fa-fw text-danger`} /> (
                        {change &&
                          data &&
                          data.filter((x) => x.checked == true).length}
                        ) Excluir selecionados
                      </button>

                      <button
                        title="Tornar documentos invisíveis ao cliente"
                        className={`btn btn-dual btn-block`}
                        key={`hideSelecteds`}
                        onClick={() => hideSelecteds()}
                      >
                        <i className={`fa fa-eye-slash`} /> (
                        {change &&
                          data &&
                          data.filter((x) => x.checked == true).length}
                        ) Deixar invisível
                      </button>

                      <button
                        title="Tornar documentos invisíveis ao cliente"
                        className={`btn btn-dual btn-block`}
                        key={`showSelecteds`}
                        onClick={() => showSelecteds()}
                      >
                        <i className={`fa fa-eye`} /> (
                        {change &&
                          data &&
                          data.filter((x) => x.checked == true).length}
                        ) Deixar visível
                      </button>

                      <button
                        title="Tornar documentos invisíveis ao cliente"
                        className={`btn btn-dual btn-block`}
                        key={`hideAllDocs`}
                        onClick={() => hideAllDocs()}
                      >
                        <i
                          className={`fa fa-eye${available === true ? "" : "-slash"}`}
                        />{" "}
                        Deixar todos invisíveis
                      </button>

                      <button
                        title="Tornar documentos invisíveis ao cliente"
                        key={`SendOrgan`}
                        className={`btn btn-dual btn-block`}
                        onClick={() => setShowModal(true)}
                      >
                        <i className={`si si-paper-plane`} /> Enviar ao órgão
                      </button>

                      <Dropdown>
                        <Dropdown.Toggle
                          className="btn btn-sm btn-block btn-dual"
                          variant={Storage.getTheme()}
                          disabled={disable}
                        >
                          Alterar Status
                        </Dropdown.Toggle>
                        <Dropdown.Menu style={{ zIndex: 99999999999 }}>
                          <button
                            className="btn btn-square btn-outline-info mb-1 mt-1"
                            style={{ width: "100%", zIndex: 99999999999 }}
                            onClick={() => updateDocStatus(1)}
                          >
                            Mestre
                          </button>
                          <br />
                          <button
                            className="btn btn-square btn-outline-secondary mb-1 mt-1"
                            style={{ width: "100%", zIndex: 99999999999 }}
                            onClick={() => updateDocStatus(2)}
                          >
                            Complementar
                          </button>
                          <br />
                          <button
                            className="btn btn-square btn-outline-warning mb-1 mt-1"
                            style={{ width: "100%", zIndex: 99999999999 }}
                            onClick={() => updateDocStatus(3)}
                          >
                            Consultas e fichas
                          </button>
                        </Dropdown.Menu>
                      </Dropdown>
                    </Dropdown.Menu>
                  </Dropdown>
                ) : (
                  ""
                )}

                <DocInput
                  multiple={true}
                  disable={disable}
                  loading={loading}
                  uploadFile={(itens) => uploadFile(itens)}
                />
               {userData && userData.level === 1 ? 
                  <AutomaticConsult preLoad={preLoad} reload={() => listDocuments()}/>
                : ""}
              </div>
            ) : (
              ""
            )}
          </div>
        </div>
      </div>
      <div className="col-md-12 py-1">
        <div className="form-group">
          <div className="input-group">
            <input
              type="text"
              placeholder="Filtrar"
              className="form-control"
              id="search-filter"
              value={query}
              onChange={(evt) => handleExigencieChange(evt.target.value)}
            />
            <div className="input-group-append">
              <span
                className="input-group-text"
                onClick={() => setQuery("") | setFilter([])}
                title="Limpar campo"
              >
                <i className="si si-close"></i>
              </span>
            </div>
          </div>
        </div>
        {query && query.length > 0 ? (
          <div>
            {change &&
              filter &&
              filter.map((item, index) => (
                <DocItemComponent
                  exigencies={exigencies}
                  setView={(dta) => viewAction(dta)}
                  setRightView={(dta) => viewRightAction(dta)}
                  viewers={setRightView === undefined ? 1 : 2}
                  disabled={disable}
                  organListId={organListId}
                  reportChecked={(item) => handleChecked(item, index)}
                  data={item}
                  updateList={() => listDocuments()}
                />
              ))}
          </div>
        ) : (
          <div>
            {userData && userData.level === 5 ? (
              <div>
                {data && data.length === 0 ? (
                  <div className="text-center mt-5">
                    <h5 className="text-muted">
                      Nesta pagina não apresentamos todos os documentos deste
                      processo
                      <br />
                      <br />
                      Aqui serão apresentados apenas os arquivos que nossa
                      equipe estiver tratando algum assunto com você
                      <br />
                      <br />
                      Fique tranquilo(a) todos os seus documentos estão seguros
                      com a gente ;)
                    </h5>
                  </div>
                ) : (
                  ""
                )}
              </div>
            ) : (
              ""
            )}
            {change &&
              data &&
              data.map((item, index) => (
                <div
                  key={`docs`}
                  onDragStart={(e) => dragStart(e, index)}
                  onDragEnter={(e) => dragEnter(e, index)}
                  onDragEnd={drop}
                  draggable
                >
                  <DocItemComponent
                    exigencies={exigencies}
                    setView={(dta) => viewAction(dta)}
                    setRightView={(dta) => viewRightAction(dta)}
                    viewers={setRightView === undefined ? 1 : 2}
                    data={item}
                    organListId={organListId}
                    reportChecked={(item) => handleChecked(item, index)}
                    disabled={disable}
                    updateList={() => listDocuments()}
                  />
                </div>
              ))}
          </div>
        )}
      </div>

      {userData !== null && userData.level !== 5 ? (
        <div className="col-md-12 py-1 text-center">
          <Dropdown>
            <Dropdown.Toggle variant={"light"}>
              <button className="btn btn-link text-danger">
                <i className="si si-trash"></i> Excluídos
              </button>
            </Dropdown.Toggle>
            <Dropdown.Menu
              style={{
                marginRight: 60,
                height: 340,
                width: 460,
                overflowY: "scroll",
              }}
            >
              <div className="block-header">
                <div className="block-title">
                  <h2 className="content-heading" style={{ width: 300 }}>
                    <i className="fa fa-angle-right text-muted mr-1"></i>{" "}
                    {eraseds && eraseds.length} Excuídos
                  </h2>
                </div>
              </div>
              {eraseds &&
                eraseds.map((item) => (
                  <DocItemComponent
                    exigencies={exigencies}
                    setView={(dta) => viewAction(dta)}
                    setRightView={(dta) => viewRightAction(dta)}
                    viewers={setRightView === undefined ? 1 : 2}
                    data={item}
                    disabled={disable}
                    organListId={organListId}
                    updateList={() => listDocuments()}
                  />
                ))}
            </Dropdown.Menu>
          </Dropdown>
        </div>
      ) : (
        ""
      )}
      <DeferredEmailModal
        show={showModal}
        docs={change && data}
        onHide={() => setShowModal(false) | removeSelected()}
      />
    </div>
  );
}
