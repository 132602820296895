import React, { useState, useEffect } from "react";
import Modal from "@/app/core/layouts/main/components/Modal";
import Storage from "@/app/core/util/Storage";
import { toast } from "react-toastify";
import { Message } from "@/app/core/util/messages/GeralMessages";
import { OnDataChange } from "@/app/core/util/services/DataService";
import { OnlyDateTimeNow, OnlyDateTranslate, OnlyDateTranslateToInput } from "@/app/core/util/DateTranslater";
import { NegotiationController } from "@/app/core/base/controllers/NegotiationController";
import { MoneyMask } from "@/app/core/util/helpers/MoneyHelper";
import CollectorHistoryController from "@/app/core/base/controllers/CollectorHistoryController";
import ServiceChargeController from "@/app/core/base/controllers/ServiceChargeController";
import FinancialHelpModal from "./FinancialHelpModal";
import FinancialHelper from "@/app/core/util/helpers/FinancialHelper";
import { Autocomplete } from "@/app/core/util/helpers/AutocompleteHelper";
import { Typeahead } from "react-bootstrap-typeahead";

export default function NegotiationModal({ show, onHide, infos, customerId }) {
    const userData = Storage.getUserData();
    const [data, setData] = useState({});
    const [loading, setLoading] = useState(false);
    const [disabled, setDisabled] = useState(false);
    const [bills, setBills] = useState([]);
    const [query, setQuery] = useState("");
    const [results, setResults] = useState([]);
    const date = new Date();

    useEffect(() => {
        if (show) {
            if (infos) {
                let old = infos;
                if (old.dueDate) {
                    old.dueDate = OnlyDateTranslateToInput(old.dueDate);
                }
                if (old.charge) {
                    old.chargeName = `Venc: ${OnlyDateTranslate(old.charge.dueDate)} - valor: R$${old.charge.price.toLocaleString()}`;
                }
                console.log("Dados", infos);
                setData(infos);
            }
            loadBills(customerId)
        } else {
            setData({})
            setLoading(false);
        }
        console.log("Data", data);
    }, [show, infos]);

    function loadBills(customer) {
        ServiceChargeController().ListByCustomer(customer, res => {
            for (let s in res) {
                res[s].chargeName = `Venc: ${OnlyDateTranslate(res[s].dueDate)} - valor: R$${res[s].price.toLocaleString()}`;
                console.log("Ooooh", res[s])
            }
            setBills(res.filter(x => x.status != FinancialHelper().PaymentStatus.Paid));
        })
    }

    function save() {
        console.log("Dados", data);
        if (!data.description || !customerId || !data.value) {
            toast.error(Message.PendingData);
        } else {
            let baseData = data;
            baseData.customerId = customerId;
            setLoading(true);
            if (data.id) {
                NegotiationController.Update(baseData, res => {
                    let message = `Editou uma negociação no valor de ${MoneyMask(res.value, 2)}`;

                    reportHistory(message, res.customerId);
                    end();
                });
            } else {
                baseData.userId = userData.id;
                NegotiationController.Insert(baseData, res => {
                    let message = `Adicionou uma negociação no valor de ${MoneyMask(res.value, 2)}`;
                    reportHistory(message, res.customerId);
                    end();
                });
            }

        }
    }

    function reportHistory(message, id) {
        CollectorHistoryController().InsertHistory(message, id);
    }

    function end() {
        setTimeout(() => {
            toast.success(Message.Saved);
            setLoading(false);
            onHide()
        }, 400)
    }

    function handleChange(evt, type) {
        setData(OnDataChange(data, evt, type));
        if (evt.target.name == "chargeName") {
            checkValue(evt.target.value)
        }
    }

    function checkValue(value) {
        setResults(Autocomplete(value, bills, "chargeName"));
    }

    function selectCharge(item) {
        setData({ ...data, chargeName: item.chargeName, chargeId: item.id, value: item.price, dueDate: OnlyDateTranslateToInput(item.dueDate) });
    }

    function clearField() {
        if (data && data.id == undefined) {
            setData({ ...data, chargeName: "", chargeId: 0 })
        }
    }

    return (
        <Modal
            show={show}
            onHide={onHide}
            title="Negociação"
            onSave={() => save()}
            onSubmit={() => save()}
            isLoading={loading}>
            <div className="form-group">
                <span>Faturas</span>
                <Typeahead
                    options={bills}
                    labelKey="name"
                    onSearch={(q) => setQuery(q)}
                    id="advanced-filter-state"
                    name="chargeName"
                    placeholder="Selecionar fatura"
                    value={data && data.chargeName}
                    onChange={(selected) => {
                        if (selected[0]) {
                            setData({
                                ...data,
                                chargeId: selected[0].id,
                                chargeName: selected[0].name,
                            });
                        }
                    }}
                />
            </div>
            <div className="form-group">
                <span>Valor</span>
                <input className="form-control" type="number" name="value" value={data && data.value} onChange={evt => handleChange(evt)} />
            </div>

            <div className="form-group">
                <span>Descrição</span>
                <textarea className="form-control" name="description" value={data && data.description} onChange={evt => handleChange(evt)} />
            </div>


            <div className="form-group">
                <span>Data</span>
                <input className="form-control" type="date" min={OnlyDateTimeNow()} disabled={disabled} name="dueDate" value={data && data.dueDate} onChange={evt => handleChange(evt)} />
            </div>
        </Modal>
    );
}
