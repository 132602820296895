import { ConsultController } from "@/app/core/base/controllers/ConsultController";
import OrganListController from "@/app/core/base/controllers/OrganListController";
import { ConsultHelper } from "@/app/core/util/helpers/ConsultHelper";
import { Message } from "@/app/core/util/messages/GeralMessages";
import Storage from "@/app/core/util/Storage";
import React, { useEffect, useState } from "react";
import { Dropdown } from "react-bootstrap";
import { toast } from "react-toastify";

export default function AutomaticConsult({ preLoad, organListId, reload }) {
    const [options, setOptions] = useState(ConsultHelper.ConsultTypeList);

    const date = new Date();
    const [change, setChange] = useState(date.getTime());
    const [loading, setLoading] = useState(false);

    useEffect(() => {
        loadOrgans()
    }, [])

    function loadOrgans() {
    }

    function handleChange(evt, index) {
        let newOptions = options;
        newOptions[index].selected = evt.target.checked;

        setOptions(newOptions);
        notifyChange();
    }

    function notifyChange() {
        setChange(date.getTime());
    }

    async function generateDocs() {
        const selecteds = options.filter(x => x.selected == true);
        if (!preLoad.cnpj) {
            toast.error("Esse processo não tem o CNPJ registrado")
        } else if (selecteds.length === 0) {
            toast.error(Message.PendingData);
        } else {
            setLoading(true);
            for (let s of selecteds) {
                if (s.value == ConsultHelper.ConsultType.Junta) {
                    ConsultController.GetJunta(preLoad.cnpj, preLoad.id, res => {
                        if (res?.data && res?.data.length === 0) {
                            toast.error("Não foi possível gerar a consulta da Junta")
                        }
                        else {
                            toast.success("Consulta da Junta gerada com sucesso")
                            reload();
                        }
                    }, error => toast.error("Não foi possível gerar a consulta da Junta"))
                }
                if (s.value == ConsultHelper.ConsultType.ReceitaFederal) {
                    ConsultController.GetReceita(preLoad.cnpj, preLoad.id, res => {
                        if (res?.data && res?.data.length === 0) {
                            toast.error("Não foi possível gerar a consulta da Receita")
                        }
                        else {
                            toast.success("Consulta da Receita gerada com sucesso")
                            reload();
                        }
                    }, error => toast.error("Não foi possível gerar a consulta da Receita"))
                }
                if (s.value == ConsultHelper.ConsultType.PrefeituraCCM) {
                    ConsultController.GetPrefeitura(preLoad.cnpj, preLoad.id, res => {
                        if (res?.data && res?.data.length === 0) {
                            toast.error("Não foi possível gerar a consulta da Prefeitura")
                        }
                        else {
                            toast.success("Consulta da Prefeitura gerada com sucesso")
                            reload();
                        }
                    }, error => toast.error("Não foi possível gerar a consulta da Prefeitura"))
                }
                if (s.value == ConsultHelper.ConsultType.Sintegra) {
                    ConsultController.GetSintegra(preLoad.cnpj, preLoad.id, res => {
                        if (res?.data && res?.data.length === 0) {
                            toast.error("Não foi possível gerar a consulta da Sintegra")
                        }
                        else {
                            toast.success("Consulta da Sintegra gerada com sucesso")
                            reload();
                        }
                    }, error => toast.error("Não foi possível gerar a consulta da Sintegra"))
                }
                if (s.value == ConsultHelper.ConsultType.CaixaRegularidade) {
                    ConsultController.GetCaixaRegularidade(preLoad.cnpj, preLoad.id, res => {
                        if (res?.data && res?.data.length === 0) {
                            toast.error("Não foi possível gerar a consulta da Caixa")
                        }
                        else {
                            toast.success("Consulta Caixa gerada com sucesso")
                            reload();
                        }
                    }, error => toast.error("Não foi possível gerar a consulta da Caixa"))
                }
                if (s.value == ConsultHelper.ConsultType.ProcuradoriaGeral) {
                    ConsultController.GetProcuradoriaGeral(preLoad.cnpj, preLoad.id, res => {
                        if (res?.data && res?.data.length === 0) {
                            toast.error("Não foi possível gerar a consulta da Procuradoria Geral")
                        }
                        else {
                            toast.success("Consulta da Procuradoria Geral gerada com sucesso")
                            reload();
                        }
                    }, error => toast.error("Não foi possível gerar a consulta da Procuradoria Geral"))
                }
                if (s.value == ConsultHelper.ConsultType.Sefaz) {
                    ConsultController.GetSefaz(preLoad.cnpj, preLoad.id, res => {
                        if (res?.data && res?.data.length === 0) {
                            toast.error("Não foi possível gerar a consulta da Sefaz")
                        }
                        else {
                            toast.success("Consulta do Sefaz gerada com sucesso")
                            reload();
                        }
                    }, error => toast.error("Não foi possível gerar a consulta da Sefaz"))
                }
                if (s.value == ConsultHelper.ConsultType.PrefeituraCertidaoTributaria) {
                    ConsultController.GetPrefeituraCertidaoTributaria(preLoad.cnpj, preLoad.id, res => {
                        if (res?.data && res?.data.length === 0) {
                            toast.error("Não foi possível gerar a consulta da Certidão Tributária da prefeitura")
                        }
                        else {
                            toast.success("Consulta da Certidão Tributária da prefeitura gerada com sucesso")
                            reload();
                        }
                    }, error => toast.error("Não foi possível gerar a consulta da Certidão Tributária da prefeitura"))
                }
            }

            setTimeout(() => {
                setLoading(false);
            }, 50000)
        }
    }

    return (
        <div className="d-flex">
            <Dropdown>
                <Dropdown.Toggle
                    className="btn btn-hero btn-hero-warning"
                    variant={Storage.getTheme()}
                >
                    <i className="si si-notebook" /> Gerar Consultas
                </Dropdown.Toggle>
                <Dropdown.Menu style={{ zIndex: 99999999999, width: 540 }} className="border border-2x">
                    <div className="form-group">
                        <span>Selecione os órgãos para consulta:</span>
                        <div className="row">
                            {change && options && options.map((item, index) => (
                                <div className="col-md-6">
                                    <div class="custom-control custom-switch custom-control-lg mb-2">
                                        <input type="checkbox" class="custom-control-input" id={`organ-${item.value}`} name={`organ-${item.value}`} onChange={evt => handleChange(evt, index)} checked={item.selected} />
                                        <label class="custom-control-label" for={`organ-${item.value}`}>{item.name}</label>
                                    </div>
                                </div>
                            ))}
                        </div>
                    </div>
                    <div className="text-center">
                        <button className="btn btn-block btn-hero-sm btn-hero-warning" onClick={() => generateDocs()}>
                            {loading === true ? <i className={"fa fa-circle-notch fa-spin mr-2"}></i> : ""}
                            Gerar
                        </button>
                    </div>
                </Dropdown.Menu>
            </Dropdown>
        </div >
    )
}