export const ConsultHelper = {
    ConsultType : {
        Junta: 0,
        ReceitaFederal: 1,
        PrefeituraCCM: 2,
        Sintegra: 3,
        CaixaRegularidade: 4,
        ProcuradoriaGeral: 5,
        Sefaz: 6,
        PrefeituraCertidaoTributaria: 7,
    },
    
    ConsultTypeList : [
        {name: "Ficha Cadastral Simplificada", value: 0, selected: true},
        {name: "Cartão CNPJ", value: 1, selected: true},
        {name: "FDC - Ficha de dados cadastrais", value: 2, selected: true},
        {name: "CADESP", value: 3, selected: true},
        {name: "Certidão de Regularidade do FGTS", value: 4, selected: false},
        {name: "Certidão Negativa de Débitos Inscritos na dívida ativa", value: 5, selected: false},
        {name: "Certidão de Débitos Tributários Não Inscritos na Dívida Ativa", value: 6, selected: false},
        {name: "Certidão de débitos Municipais", value: 7, selected: false},
    ],

} 