import Calendar from "react-calendar";
import "react-calendar/dist/Calendar.css";
import { Dropdown } from "react-bootstrap";
import Storage from "@/app/core/util/Storage";
import React, { useEffect, useState } from "react";
import {
    DateTranslate,
    DateTranslateToInput,
    OnlyDateTranslate,
} from "@/app/core/util/DateTranslater";
import { Message } from "@/app/core/util/messages/GeralMessages";
import { ReminderHelper } from "@/app/core/util/helpers/ReminderHelper";
import { ReminderController } from "@/app/core/base/controllers/ReminderController";
import { Panel, Tab, Tabs } from "@bumaga/tabs";
import ToDoController from "@/app/core/base/controllers/ToDoController";
import { toast } from "react-toastify";
import TaskTutorialModal from "./components/TaskTutorialModal";
import ResumeTaskCard from "../../customerProfile/main/components/ResumeTaskCard";
import ElaboratorCard from "../../customerProfile/main/components/ElaboratorCard";
import BudgetCard from "../../customerProfile/main/components/BudgetCard";
import CompaniesCard from "../../customerProfile/main/components/CompaniesCard";
import ReminderCard from "../../customerProfile/main/components/ReminderCard";
import ToDoItem from "./components/ToDoItem";

export default function ToDoComponent({ customerId }) {
    const userData = Storage.getUserData();
    const [customer, setCustomer] = useState(null);
    const [data, setData] = useState([]);
    const [done, setDone] = useState([]);
    const [selected, setSelected] = useState({});
    const [showModal, setShowModal] = useState(false);
    const [showStatsModal, setShowStatsModal] = useState(false);
    const date = new Date();
    const [change, setChange] = useState(date.getTime());
    const [value, onChange] = useState(new Date());
    const [description, setDescription] = useState("");

    useEffect(() => {
        setCustomer(userData.customerId);
        getTasks(userData.customerId);
    }, []);

    function notifyChange() {
        setChange(date.getTime());
    }

    function saveTask(e) {
        e.preventDefault();
        if (description.length == 0) {
            toast.error(Message.PendingData)
        } else {
            ToDoController().Insert({ customerId: parseInt(userData.customerId), description }, res => {
                setDescription("")
                getTasks();
            })
        }
    }

    function getTasks() {
        ToDoController().List(userData.customerId, (res) => {

            setData(res.filter((x) => x.done != true));
            setDone(res.filter((x) => x.done == true));
        });
    }

    function deleteList() {
        if (window.confirm(Message.DeleteConfirm)) {
            for (let s in data) {
                if (data[s].checked) {
                    ToDoController().Delete(data[s].id, (res) => {
                        getTasks(customer);
                    });
                }
            }
        }
    }



    function handleChange(evt, item) {
        let baseData = item;
        baseData.done = evt.target.checked;

        setData(data.filter(x => x.done == false && x.id != item.id));

        ToDoController().Update(baseData, res => {
            getTasks();
        })
    }

    function handleDoneChange(evt, item) {
        let baseData = item;
        baseData.done = evt.target.checked;
        ToDoController().Update(baseData, res => {
            getTasks();
        })
    }

    function deleteTask(item) {
        if (window.confirm(Message.DeleteConfirm)) {
            ToDoController().Delete(item.id, res => {
                getTasks();
            })
        }
    }

    return (
        <div className="content mr-0 pl-0 pr-0">
            <div className="row mr-0 pr-0">
                
                <div className="col-md-9">
                    <div className="row">
                        <div className="col-8 mb-3">
                            <h2 className="mb-0 ml-3 mt-2">
                                <strong className="font-w700">Tarefas</strong>
                            </h2>
                            <div className="text-sm-left ml-3">
                                Adicione, edite e exclua tarefas facilmente.
                            </div>
                        </div>
                        <div className="col-4 text-right">
                            <button
                                className="btn btn-outline-info"
                                onClick={() => setShowModal(true)}
                            >
                                <i className="si si-question" /> Tutorial completo
                            </button>
                        </div>
                    </div>
                    <Tabs>
                        <div className="bg-gray mb-3">
                            <Tab>
                                <button className="btn btn-dual">
                                    ({data && data.length}) Em andamento
                                </button>
                            </Tab>
                            <Tab>
                                <button className="btn btn-dual">
                                    ({done && done.length}) Finalizadas
                                </button>
                            </Tab>
                        </div>
                        <Panel>
                            <form onSubmit={e => saveTask(e)}>
                                <div className="form-group">
                                    <div className="input-group">
                                        <input type="text" className="form-control" id="example-group3-input2" name="example-group3-input2" placeholder="Descreva a tarefa" value={description} onChange={evt => setDescription(evt.target.value)} />
                                        <div className="input-group-append">
                                            <button type="submit" className="btn btn-dark">Adicionar</button>
                                        </div>
                                    </div>
                                </div>
                            </form>
                            {change &&
                                data &&
                                data.map((item, index) => (
                                    <ToDoItem data={item} deleteTask={()=> deleteTask(item)} handleTaskChange={evt => handleChange(evt, item)} />
                                ))}
                            {data && data.length == 0 ? (
                                <div className="text-center mt-5">
                                    <h4 className="font-w700 text-dark">
                                        Você não tem nenhuma tarefa em aberto
                                    </h4>
                                    <p className="font-w700 text-dark">
                                        Adicione a sua primeira tarefa para começar
                                    </p>
                                    <hr style={{ width: 120 }} />
                                </div>
                            ) : (
                                ""
                            )}
                        </Panel>
                        <Panel>

                            {change &&
                                done &&
                                done.map((item, index) => (
                                    <div className="js-task block block-rounded block-fx-pop block-fx-pop mb-2 animated fadeIn" data-task-id="9" data-task-completed="false" data-task-starred="false">
                                        <table className="table table-borderless table-vcenter mb-0">
                                            <tbody>
                                                <tr>
                                                    <td className="text-center pr-0" style={{ width: 38 }}>
                                                        <div className="js-task-status custom-control custom-checkbox custom-checkbox-rounded-circle custom-control-primary custom-control-lg">
                                                            <input type="checkbox" className="custom-control-input" id={`done-${item.id}`} name={`done-${item.id}`} onChange={(evt) => handleDoneChange(evt, item)} checked={item.done} defaultChecked={item.done} />
                                                            <label className="custom-control-label" for={`done-${item.id}`}></label>
                                                        </div>
                                                    </td>
                                                    <td className="js-task-content font-w600 pl-0">
                                                        {item.description}
                                                    </td>
                                                    <td className="text-right" style={{ width: 390 }}>
                                                        <small className="text-sm mr-1">
                                                            Criado em: {DateTranslate(item.createdDate)}
                                                        </small>
                                                        |
                                                        <small className="text-sm ml-1">
                                                            Finalizado em: {DateTranslate(item.updatedDate)}
                                                        </small>
                                                        <button type="button" className="js-task-remove btn btn-sm btn-link text-danger" onClick={() => deleteTask(item)}>
                                                            <i className="fa fa-times fa-fw"></i>
                                                        </button>
                                                    </td>
                                                </tr>
                                            </tbody>
                                        </table>
                                    </div>
                                ))}
                            {done && done.length == 0 ? (
                                <div className="text-center mt-5">
                                    <h4 className="font-w700 text-dark">
                                        Você não tem nenhuma tarefa concluída
                                    </h4>
                                    <hr style={{ width: 120 }} />
                                </div>
                            ) : (
                                ""
                            )}
                        </Panel>
                    </Tabs>
                </div>
                <div className="col-3">
                    <div className="mt-3">
                        <ElaboratorCard customerId={customerId != undefined ? customerId : userData.customerId} />
                        <BudgetCard customerId={customerId != undefined ? customerId : userData.customerId} />
                        <CompaniesCard customerId={customerId != undefined ? customerId : userData.customerId} />
                        <ReminderCard customerId={customerId != undefined ? customerId : userData.customerId} />
                    </div>
                </div>
            </div>
            <TaskTutorialModal
                show={showModal}
                onHide={() => setShowModal(false)}
            />
        </div>
    );
}
