import { toast } from "react-toastify";
import BaseService from "@/app/core/base/BaseService";
import { HandleErrors } from "../../util/helpers/ErrorHelper";

export const SupportController = {
    Send: async function (data, response, error) {
        await BaseService(
            {
                method: "POST",
                url: "email/FinancialSupport",
                data: data,
            },
            (res) => {
                response(res);
            },
            (err) =>
                error === undefined ? toast.error(HandleErrors(err)) : error(err)
        );
    }
}
