import React, { useState, useEffect } from "react";
import Modal from "@/app/core/layouts/main/components/Modal";
import { toast } from "react-toastify";
import { OnDataChange } from "@/app/core/util/services/DataService";
import { Message } from "@/app/core/util/messages/GeralMessages";
import {
  DeleteSelectedService,
  ServiceDelete,
} from "@/app/core/base/controllers/ServicesController";
import ProcessHelper from "@/app/core/util/helpers/ProcessHelpers";

export default function DeleteServiceModal({ showModal, onHide, data }) {
  const [motive, setMotive] = useState({});
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    if (data !== undefined && data !== null) {
      console.log("Quase", showModal);
    }
  }, [showModal]);

  function saveRemoval() {
    if (!motive.motive || motive.reimbursement == undefined) {
      toast.error(Message.PendingData);
    } else {
      setLoading(true);
      DeleteSelectedService(
        data.id,
        motive.motive,
        motive.reimbursement,
        motive.reimbursementType,
        (res) => {
          setLoading(false);
          toast.success(Message.DeleteSuccess);
          onHide();
        },
      );
    }
  }

  function handleChange(evt, type) {
    setMotive(OnDataChange(motive, evt, type));
  }

  function handleDeleteCheckChange(evt) {
    let base = {};
    if (evt.target.id == "erro") {
      base = {
        ...motive,
        reimbursementType: ProcessHelper().ReimbursementType.Erro,
      };
      setMotive(base);
    } else if (evt.target.id == "desistencia") {
      base = {
        ...motive,
        reimbursementType: ProcessHelper().ReimbursementType.Desistencia,
      };
      setMotive(base);
    } else if (evt.target.id == "alteracao") {
      base = {
        ...motive,
        reimbursementType: ProcessHelper().ReimbursementType.Alteracao,
      };
      setMotive(base);
    }
  }

  return (
    <Modal
      title="Excluir serviço"
      show={showModal}
      size="md"
      isLoading={loading}
      onHide={() => onHide()}
      onSubmit={() => saveRemoval()}
    >
      <div className="form-group">
        <span>Qual o motivo da exclusão?</span>
        <textarea
          className="form-control"
          name="motive"
          value={motive.motive}
          onChange={(evt) => handleChange(evt)}
        />
      </div>
      <br />
      <div className="form-group">
        <label className="d-block">Tipo de exclusão:</label>
        <div className="custom-control custom-radio custom-control-inline custom-control-lg">
          <input
            type="radio"
            className="custom-control-input"
            id="erro"
            name="deleteType"
            onChange={(evt) => handleDeleteCheckChange(evt)}
            checked={
              motive &&
              motive.reimbursementType == ProcessHelper().ReimbursementType.Erro
            }
          />
          <label className="custom-control-label" for="erro">
            Erro
          </label>
        </div>
        <div className="custom-control custom-radio custom-control-inline custom-control-lg">
          <input
            type="radio"
            className="custom-control-input"
            id="desistencia"
            name="deleteType"
            onChange={(evt) => handleDeleteCheckChange(evt)}
            checked={
              motive &&
              motive.reimbursementType ==
                ProcessHelper().ReimbursementType.Desistencia
            }
          />
          <label className="custom-control-label" for="desistencia">
            Desistência
          </label>
        </div>
        <div className="custom-control custom-radio custom-control-inline custom-control-lg">
          <input
            type="radio"
            className="custom-control-input"
            id="alteracao"
            name="deleteType"
            onChange={(evt) => handleDeleteCheckChange(evt)}
            checked={
              motive &&
              motive.reimbursementType ==
                ProcessHelper().ReimbursementType.Alteracao
            }
          />
          <label className="custom-control-label" for="alteracao">
            Alteração de serviço
          </label>
        </div>
      </div>
      <br />
      {/* <div className="form-group">
            <label className="d-block">O serviço foi cancelado dentro de 24h?</label>
            <div className="custom-control custom-radio custom-control-inline custom-control-lg">
            <input type="radio" className="custom-control-input" id="erro" name="twenty" onChange={evt => handleDeleteCheckChange(evt)} checked={motive && motive.reimbursementType == ProcessHelper().ReimbursementType.Erro} />
            <label className="custom-control-label" for="erro">Sim</label>
            </div>
            <div className="custom-control custom-radio custom-control-inline custom-control-lg">
            <input type="radio" className="custom-control-input" id="desistencia" name="twenty" onChange={evt => handleDeleteCheckChange(evt)} checked={motive && motive.reimbursementType == ProcessHelper().ReimbursementType.Desistencia} />
            <label className="custom-control-label" for="desistencia">Não</label>
            </div>
        </div>
        <br/>
        <div className="form-group">
            <label className="d-block">O serviço foi analisado e/ou preparado?</label>
            <div className="custom-control custom-radio custom-control-inline custom-control-lg">
            <input type="radio" className="custom-control-input" id="erro" name="almostDone" onChange={evt => handleDeleteCheckChange(evt)} checked={motive && motive.reimbursementType == ProcessHelper().ReimbursementType.Erro} />
            <label className="custom-control-label" for="erro">Sim</label>
            </div>
            <div className="custom-control custom-radio custom-control-inline custom-control-lg">
            <input type="radio" className="custom-control-input" id="desistencia" name="almostDone" onChange={evt => handleDeleteCheckChange(evt)} checked={motive && motive.reimbursementType == ProcessHelper().ReimbursementType.Desistencia} />
            <label className="custom-control-label" for="desistencia">Não</label>
            </div>
        </div>
        <br/> */}
      <div className="form-group">
        <span>O que fazer após a exclusão?</span>
        <br />
        <div class="custom-control custom-radio custom-control-inline custom-control-lg">
          <input
            type="radio"
            class="custom-control-input"
            id="dontSend"
            name="revision"
            onChange={(evt) => setMotive({ ...motive, reimbursement: false })}
          />
          <label class="custom-control-label" for="dontSend">
            Não enviar reembolso
          </label>
        </div>
        <div class="custom-control custom-radio custom-control-inline custom-control-lg">
          <input
            type="radio"
            class="custom-control-input"
            id="revisao"
            name="revision"
            onChange={(evt) => setMotive({ ...motive, reimbursement: true })}
          />
          <label class="custom-control-label" for="revisao">
            Enviar reembolso pra revisão
          </label>
        </div>
      </div>
    </Modal>
  );
}
